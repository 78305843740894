import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Input,
  Form,
  Row,
  Col,
  Button,
  message
} from 'antd';
import {request} from '../../../../../utils/fetch'
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { flex: '100px' },
    wrapperCol: { flex: 1 },
}

const BillUpDataModal = forwardRef((props,ref) => {
  const [visible, setVisible] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [recordData, setRecordData] = React.useState({});
  const [title1, setTitle1] = React.useState();
  const [form] = Form.useForm()
   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record,title) => {
        form.resetFields()
        setRecordData(record)
        setTitle1(title)
        setVisible(true);
      }
  }));

  const handleOk = () =>{
    setBtnLoading(true)
    form.validateFields().then( async (valus)=>{
      let data = {
        taxEnterpriseCode: recordData.taxEnterpriseCode,
        companyCode: recordData.companyCode,
        companyName: recordData.companyName,
        dealType: title1 === '上账'? 2:3,
        dealAmount: valus.dealAmount,
        remark: valus.remark,
      }
      await request.post('/platform/companyAccount/updateBalance',data)
      message.success('执行成功')
      setVisible(false)
      setBtnLoading(false)
      console.log(props)
      props.refresh()
    }).catch(()=>{
      setBtnLoading(false)
    })
  }
  const handleCancel = () =>{
    setVisible(false)
    setRecordData({})
    setTitle1('')
    form.resetFields()
  }

  return (
    <>
      <Modal maskClosable={false}
        title={`${recordData.companyName}${title1}`}
        width='700px'
        visible={visible}
        footer={[
          <Button key="link1" loading={btnLoading} onClick={()=>{handleCancel()}} >取消</Button>,
          <Button key="link" loading={btnLoading} type="primary" onClick={()=>{handleOk()}} >提交</Button>
        ]}
        onCancel={() => {setVisible(false)}}
      >
        <Form {...formItemLayout} form={form}>
            <Row className="margin-15">
                <Col className="gutter-row" span={24}>
                    <Form.Item name="dealAmount" label={`${title1}金额:`}>
                      <Input />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                    <Form.Item name="remark" label="备注:">
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      
      </Modal>
    </>
  );
});
export default BillUpDataModal



