import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, message } from 'antd';
import axios from 'axios'
import qs from 'qs'
// 发布方反查
const PublisherInvestigate = (props) => {
  const [form] = Form.useForm();
  const [formData,setFormData] = useState({})
  let formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  }
  
  const getData = ()=>{
    let data = qs.parse(props.history.location.search.replace(/\?/g, ''));
    axios({
      method: 'get',
      url: `/hire/platform/reverseData/fbfInfo`,
      // url: `/platform/reverseData/fbfInfo`,
      params: data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log(res)
      if(res.data.success){
        setFormData(res.data.data)
        form.setFieldsValue(res.data.data)
      }else{
        message.error(res.data.retMsg)
      }
    }).catch(err => {
        console.log(err)
    })
   
  }

  useEffect(() => {
    getData()
  },[])
  
  return (
    <>
     <Form
      {...formItemLayout}
      form={form}
      initialValues={formData}
    >
      <Row>
        <Col span={12}>
          <Form.Item name="fbfuuid" label="发布方UUID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nsrmc" label="企业名称">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="gsdz" label="地址">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="xxdz" label="详细地址">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ptzcsj" label="平台注册时间">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    </>
  )
}

export default PublisherInvestigate