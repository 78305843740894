import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Table, message, Space, Popconfirm, Spin, Typography, Modal } from 'antd'
import store from '../../../../../store/index'
import { getTaxEnterpriseList, taxEnterpriseUpdateStatus, } from '../../api'
const { Paragraph, Link } = Typography
const TableList = (props,ref) => {
    let path = 'platform:taxEnterprise'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    const [previewTitle, setpreviewTitle] = useState('认证链接')
    const [textUrl, settextUrl] = useState('')
    const [previewVisible, setpreviewVisible] = useState(false)
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: () => {
            getTableLists()
        },
    }))
    const getTableLists = async () => {
        let result = await getTaxEnterpriseList()
        if (result.data.success) {
            setTableData(result.data.data)
        } else {
            message.error(result.data.retMsg)
        }
    }
    const handlestatus = async (record) => {
        let status = record.status == 1 ? 0 : 1
        let result = await taxEnterpriseUpdateStatus({ code: record.code, status: status })
        if (result.data.success) {
            message.success(result.data.retMsg)
            refresh('query')
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '创建时间', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 170 },
        { title: '公司编码', dataIndex: 'code', key: 'code', align: 'center', width: 140 },
        { title: '结算通道名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center', width: 240 },
        { title: '结算通道简称', dataIndex: 'shortName', key: 'shortName', align: 'center', width: 140 },
        { title: '落地省份', dataIndex: 'provinceName', key: 'provinceName', align: 'center', width: 110 },
        { title: '落地城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 110 },
        { title: '落地区县', dataIndex: 'countyName', key: 'countyName', align: 'center', width: 110 },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 70,
            render: (status, record) => (
                <Space>
                    <span>{status == 1 ? '启用' : '停用'}</span>{' '}
                </Space>
            ),
        },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 210,
            align: 'center',
            render: (record) => {
                let title = ''
                if (record.status == 1) {
                    title = '停用'
                } else {
                    title = '启用'
                }
                return (
                    <Space>
                        {store.getState().userReducer.permissions.includes(`${path}:updateStatus`) && (
                            <Popconfirm
                                title={`确定${title}吗？`}
                                onConfirm={() => {
                                    handlestatus(record)
                                }}
                                okText='确定'
                                cancelText='取消'
                                className='marginLeft'
                            >
                                <Link>{title}</Link>
                            </Popconfirm>
                        )}
                    </Space>
                )
            },
        },
    ]
    useEffect(() => {
        getTableLists()
    }, [])
    return (
        <Spin spinning={false}>
            <Table columns={tableColumns} dataSource={tableData} scroll={{ x: 'max-content' }} rowKey={(record) => record.code} pagination={false} />
            <Modal maskClosable={false}
                width={800}
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => {
                    setpreviewVisible(false)
                }}
            >
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    copyable={{
                        text: textUrl,
                        tooltips: ['点击复制', '复制成功'],
                    }}
                >
                    {textUrl}
                </Paragraph>
            </Modal>
        </Spin>
    )
}
export default forwardRef(TableList)
