import React from 'react'
import { Modal, Image } from 'antd'


const QRCodeModal = (props) => {
    const { visible, close, detail, configVoList } = props
    
    const handleCancel = () => {
        close()
    }
    return (
        <Modal maskClosable={false} title="二维码" visible={visible} onCancel={handleCancel} width={600} footer={[]}>
            <div style={{textAlign: 'center', fontSize: '14px', fontWeight: 'bold'}}>{detail.companyName}</div>
            
                <div style={{display: 'flex', justifyContent: 'center'}}>
                {configVoList.includes('2')&& 
                    <div style={{width: '220px'}}>
                        <Image width={200} src={detail.unitPictureUrl} />
                        <a href={detail.unitPictureUrl} target="_blank" name={detail.companyName} download={detail.unitPictureUrl} style={{display: 'block', textAlign: 'center'}}>下载二维码</a>
                    </div>
                }
                {configVoList.includes('1') &&
                    <div style={{width: '220px'}}>
                        <Image width={200} src={detail.taxPictureUrl} />
                        <a href={detail.taxPictureUrl} target="_blank" name={detail.companyName} download={detail.taxPictureUrl} style={{display: 'block', textAlign: 'center'}}>下载二维码</a>
                    </div> 
                }
                {configVoList.includes('3')&&
                    <div style={{width: '220px'}}>
                        <Image width={200} src={detail.entrustPictureUrl} />
                        <a href={detail.entrustPictureUrl} target="_blank" name={detail.companyName} download={detail.entrustPictureUrl} style={{display: 'block', textAlign: 'center'}}>下载二维码</a>
                    </div> 
                }
                </div>
            
        </Modal>
    )
}

export default QRCodeModal