import React,{useState,useEffect} from 'react'
import {
  Row,
  Table,
  Select,
  Form,
  Input,
  Button,
  DatePicker,
 } from 'antd';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import moment from 'moment'

import './index.css'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
 const itemStyle = {
     width:'330px',
}
 const labelStyleObj = {
    labelCol:{ flex: '90px'},
    wrapperCol:{ flex: '1'}
 }
function Withdrawal(props) {
    // let path = 'platform:Receipt'
    const [form] = Form.useForm();
    const initSearch = {
        date: null,
        companyCode: null,
        taxEnterpriseCode: null,
    }
    const [tableData,setTableData] = useState([{}])
    const [companyList,setCompanyList] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const columns = [
        {
            title: '任务信息',
            dataIndex: 'taskName',
            key: 'taskName',
            width: 330,
            align:'left',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'80px',textAlign: 'right',marginRight: '10px'}} >任务名称:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.taskName}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'80px',textAlign: 'right',marginRight: '10px'}} >任务单号:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.billNo}</div>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '个人信息',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            align:'left',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >姓名:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.name}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >手机号:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.phone}</div>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '提现信息',
            dataIndex: 'finishTime',
            key: 'finishTime',
            width: 280,
            align:'left',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >户名:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.name}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >账号:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.cardNumber}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >银行:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.bankName}</div>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 280,
            align:'left',
        }, 
        {
            title: '金额(元)',
            dataIndex: 'dealAmount',
            key: 'dealAmount',
            width: 120,
            align:'center',
        },
        {
            title: '时间信息',
            dataIndex: 'dealTime',
            key: 'dealTime',
            width: 270,
            align:'left',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'60px',textAlign: 'right',marginRight: '10px'}} >交易时间:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.dealTime}</div>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            align:'left',
            render: (status) => {
                return (
                    <>
                        {status=='CLZ'&&<div >提现中</div>}
                        {status=='SUCCESS'&&<div >提现成功</div>}
                        {status=='FAIL'&&<div >提现失败</div>}
                    </>
                )
            }
        }, 
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 130,
            align:'center',
        }, 
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="企业名称" name="companyCodeList" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem  label="状态" name="status" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部" allowClear >
                            <Option value={'CLZ'}>提现中</Option>
                            <Option value={'SUCCESS'}>已到账</Option>
                            <Option value={'FAIL'}>到账失败</Option>
                        </Select>
                    </FormItem>
                    <Form.Item name="date" style={itemStyle} {...labelStyleObj} label="申请时间">
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name="keyword" style={itemStyle} {...labelStyleObj}label="关键字">
                        <Input placeholder="任务名称|户名|卡号|手机号" />
                    </Form.Item>
                </Row>
                <Row style={{justifyContent: 'flex-end'}} >
                    <FormItem style={{ marginLeft: '30px',marginBottom: '0px' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.exportData}>导出</Button>
                    </FormItem>
                </Row>
            </Form>
            );
        },
    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                companyCodeList:formObj.companyCodeList,
                startDate: formObj.date ? moment(formObj.date[0]).format('YYYY-MM-DD') : '',
                endDate: formObj.date ? moment(formObj.date[1]).format('YYYY-MM-DD') : '',
                keyword:formObj.keyword,
                status:formObj.status,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        exportData(){
            const params = methodsFn.getsearchObj()
            let url = '/platform/accountDeal/export'
            request.downExport(url, params)
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/accountDeal/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
            setCompanyList(result)
        },
    }
    
    useEffect(() => {
        methodsFn.getCompanyList()
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
        {myComponent.renderSearchForm()}
        <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={{
            position: ['bottomCenter'],
            total: total,
            current: pageNum,
            pageSize: pageSize,
            showSizeChanger:false,
            showTotal:(total) =>  `共 ${total} 条数据` ,
            onChange: (pageNum, pageSize) => setPageNum(pageNum)
        }}
        />
        </div>
    )
}
export default Withdrawal