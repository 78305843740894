import React,{ useImperativeHandle, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  Select,
  message,
} from 'antd';
import {request} from '../../../../utils/fetch'
const { Option } = Select;
const FormItem = Form.Item;
const MyModel1 = (props, ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    age:''
  }
  const [id, setId] = React.useState('');

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setVisible(true);
      form.resetFields()
    },
    reviseModal: (obj) => {
      setVisible(true);
      setId(obj.id)
      form.setFieldsValue(obj)
     
    },
  }));
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        setLoading(true);
        if(props.titleModel == '新增'){
          methodsFn.handleAdd(values)
          return
        }
        methodsFn.handleModify(values)
        
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      request.post('/platform/user/add',obj).then(res => {
          setVisible(false);
          setLoading(false);
          message.success(res.msg)
          props.refresh()
      }).catch(err => setLoading(false))
    },
    handleModify(obj){
      request.post('/platform/user/edit',{
        ...obj,
        id:id
      }).then(res => {
        setVisible(false);
        setLoading(false);
        message.success(res.msg)
        props.refresh()
      }).catch(err => setLoading(false))
    },
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.options) {
      let list = option.options.filter(e=>{
        return e.children.indexOf(inputValue) != -1
      })
      if(list.lenght>0){
        return option
      }
    }else{
      if (option.children.indexOf(inputValue) != -1) {
        return option
      }
    }
  }

  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title={props.titleModel}
        width='800px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
          initialValues={initialValues}
          form={form}
        >
          <FormItem name="userName" label="姓名" >
              <Input placeholder="请输入"/>
          </FormItem>
          <FormItem name="phone" label="登录手机号" >
              <Input placeholder="请输入"/>
          </FormItem>
          <FormItem name="roleIds" label="角色" rules={[ { required: true,message: '请选择角色',} ]} >
            <Select>
              {
                props.roleList.map(item => {
                    return <Option value={item.id} key={item.id}>{item.roleName}</Option>
                })
              }
            </Select>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



