import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Statistic } from 'antd';
import ChartPlots from './ChartPlots.js'
import ChartPie from './ChartPie.js'
import ChartLine from './ChartLine.js'
import {request} from '../../../utils/fetch'

import qiye from '../../../images/qiye.png'
import geren from '../../../images/geren.png'
import tongdao from '../../../images/tongdao.png'

function Home() {
    const [dataObj, setDataObj] = React.useState({});
    
    const getIndexData = () => {
       request.get('/platform/index/indexData').then(res => {
        setDataObj(res)
       })
    }

    useEffect(() => {
        getIndexData()
    },[])
    return (
        <div>
            <div className='topwarp' >
                <h3 className="home-title">项目概况</h3>
                <Row  justify="space-between">
                    <Col className="home-top b1">
                        <Image width={24} src={qiye} />
                        <div className='home-toplabel' >企业数(户)：</div>
                        <h3>{dataObj.companyCount}</h3>
                    </Col>
                    <Col className="home-top b2">
                        <Image width={24} src={geren} />
                        <div className='home-toplabel' >个人数(人)：</div>
                        <h3>{dataObj.staffCount}</h3></Col>
                    <Col className="home-top b3">
                        <Image width={24} src={tongdao} />
                        <div className='home-toplabel' >结算通道(户)：</div>
                        <h3>{dataObj.taxEnterpriseCount}</h3>
                    </Col>
                </Row>
            </div>
            <div className='statisticswarp' >
                <h3 className="home-title">金额统计</h3>
                <div className='flexbox'>
                    <div className='flexitem'>
                        <Statistic value={dataObj.totalAmount} />
                        <div className='statistitle' >任务总金额(元)</div>
                    </div>
                    <div className='flexitem'>
                        <Statistic value={dataObj.serviceAmount} />
                        <div className='statistitle' >平台服务费(元)</div>
                    </div>
                    <div className='flexitem'>
                        <Statistic value={dataObj.billAmount} />
                        <div className='statistitle' >承揽费用(元)</div>
                    </div>
                </div>
            </div>
            <Row justify="space-between" gutter={16} style={{marginTop:'20px'}}>
                <Col span={12} className="home-bottom">
                    <div className='chartbox'>
                        <div className="home-title home-mrbtm">结算通道的任务次数</div>
                        <div style={{padding: '20px'}} >
                            {dataObj.taxEnterpriseCountList&&<ChartPlots vlabel="任务统计" color="#FBCB61" data={dataObj.taxEnterpriseCountList} />}
                        </div>
                    </div>
                </Col>
                <Col span={12} className="home-bottom">
                    <div className='chartbox'>
                        <div className="home-title home-mrbtm">结算通道的任务总额</div>
                        <div style={{padding: '20px'}} >
                            {dataObj.taxEnterpriseAmountList&&<ChartPie data={dataObj.taxEnterpriseAmountList} />}
                        </div>
                    </div>
                </Col> 
            </Row>
            <Row justify="space-between" gutter={16} style={{marginTop:'20px'}}>
                <Col span={12} className="home-bottom">
                    <div className='chartbox'>
                        <div className="home-title home-mrbtm">近6个月的任务数(次)</div>
                        <div style={{padding: '20px'}} >
                            {dataObj.billCountList&&<ChartPlots vlabel="月发布任务数" color="#458EFF" data={dataObj.billCountList} />}
                        </div>
                    </div>
                </Col>
                <Col span={12} className="home-bottom">
                    <div className='chartbox'>
                        <div className="home-title home-mrbtm">近6个月的任务总金额(元)</div>
                        <div style={{padding: '20px'}} >
                            {dataObj.billAmountList&&<ChartLine data={dataObj.billAmountList} />}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Home
