import React, { useEffect,useState} from 'react'
import { Row, Col, message, Card, Button } from 'antd';
import { getFileList } from '../../api/api'

function DdShowFiles(props) {
    const [list, setList] = useState([]);
    const getList = async ()=>{
        let params = getParam()
        let result = await getFileList(params)
        console.log(result)
        if(result.data.success){
            setList(result.data.data)
        }else{
            message.error(result.data.retMsg)
        }
    }
    const getParam = ()=> {
        try {
            let arr = window.location.href.replace('#', '').split('?'),
                parArr = arr[1].split('&'), //[type=OUT_BF,no=332]
                parObj = {};

            parArr.map(item => {
                var list = item.split('=')
                parObj[list[0]] = decodeURIComponent(list[1])
            })
            return parObj
        } catch (error) {
            alert('出错了！没给参数')
        }
    }
    useEffect(() => {
        getList()
    }, [])

    return (
        <>
        <Row>
            {list.map((item,index)=>(<Col span={24} key={index} style={{margin: '16px'}}> 
                <Card type="inner" title={item.typeName}>
                    {item.list.map((item2,i)=>(
                        <div key={i}>
                            <Button type="link" style={{textAlign:'left'}} href={item2.url} block>{item2.name}</Button>
                            <br/>
                        </div>
                    ))}
                </Card>
            </Col>))}
        </Row>
        </>
    )
}

export default DdShowFiles
