import React,{useRef,useState,useEffect} from 'react'
import {
  Spin,
  Table,
  Select,
  Statistic,
  Button,
  Space
 } from 'antd';
import {request} from '../../../../utils/fetch'
import { LoadingOutlined } from '@ant-design/icons';
import { getTaxEnterpriseList } from '../../../../api/api'
import BillUpDataModal from './components/BillUpDataModal'

import './index.css'
const { Option } = Select;
 const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );
function AccountInfo(props) {
    // let path = 'platform:Receipt'
    const [tableData,setTableData] = useState([])
    const [spinLoading, setSpinLoading] = useState(false);
    const [taxEnterpriseCode, settaxEnterpriseCode] = useState('')
    const [balance, setBalance] = useState('') 
    const [serviceAmount, setServiceAmount] = useState('')
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const BillModal = useRef();
    const columns = [
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            align:'center',
        }, {
            title: '账户余额(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align:'center',
        },{
            title: '开户时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align:'center',
        }, 
        {
            title: '操作',
            key: 'action',
            width: 160,
            align:'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle" align="center" >
                    <Button type="link" size='small' onClick={ () => onUpData(record,'上账') } >上账</Button>
                    <Button type="link" size='small' onClick={ () => onUpData(record,'下账') } >下账</Button>
                    <Button type="link" size='small' onClick={ () => goDetail(record) } >交易明细</Button>
                </Space>
            )
        },
    ];
    const onUpData = (record,text) => {
        BillModal.current.showModal(record,text)
    }

    const goDetail = (record)=> {
        props.history.push(`/admin/accountInfo/detail?taxEnterpriseCode=${record.taxEnterpriseCode}&companyCode=${record.companyCode}`)
    }

    const toServiceDetail = (record)=> {
        props.history.push(`/admin/accountInfo/serviceDetail?taxEnterpriseCode=${taxEnterpriseCode}`)
    }
    const selChange = (value) => {
        settaxEnterpriseCode(value)
        methodsFn.getTableList(value)
        getBalances(value)
    }

    const getBalances = async (value)=>{
        setSpinLoading(true)
        let result = await request.get('/platform/companyAccount/accountInfo',{taxEnterpriseCode: value})
        setBalance(result.balance)
        setServiceAmount(result.serviceAmount)
        setSpinLoading(false)
    }
    //方法函数
    const methodsFn =  {
        // 获取所有结算通道
         async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                if(res.data.data&&res.data.data.length>0){
                    settaxEnterpriseCode(res.data.data[0].code)
                    methodsFn.getTableList(res.data.data[0].code)
                    getBalances(res.data.data[0].code)
                }
                setTaxEnterpriseLis(res.data.data)
            }
        },
        getsearchObj(){
            let obj = {
                taxEnterpriseCode: taxEnterpriseCode,
            }
            return obj
        },
        handleSearch() {
            methodsFn.getTableList(taxEnterpriseCode)
        },
        async getTableList(code){
            let result = await request.get('/platform/companyAccount/list',{
                taxEnterpriseCode: code,
            })
            setTableData(result)
        },
    }
    useEffect(() => {
        methodsFn.getTaxEnterpriseListall()
    },[])
    return (
        <div>
        <div className='seletop' >
            <div className="selebox">
                <Select style={{width:'300px'}} onChange={selChange} value={taxEnterpriseCode} allowClear>
                    {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                </Select>
            </div>
            <div className="page-top">
                <div className="balance-box">
                    <div>银行账户余额</div>
                    <div className="balance-fl">
                    <div className="balance" >
                        <Spin spinning={spinLoading} indicator={antIcon} >
                            <Statistic valueStyle={{ color: '#3f8600', fontWeight: '600' }} value={balance} />
                        </Spin>
                    </div>
                    </div>
                </div>
                <div className="balance-box">
                    <div>服务费收入</div>
                    <div className="balance-fl">
                    <div className="balance">
                        <Spin spinning={spinLoading} indicator={antIcon} >
                            <Statistic valueStyle={{ color: '#cf1322', fontWeight: '600' }} value={serviceAmount} />
                        </Spin>
                    </div>
                    </div>
                </div>
                <div className='mingxi' >
                    <Button type="link" size='small' onClick={()=>toServiceDetail()} >服务费明细&gt;&gt;</Button>
                </div>
            </div>
        </div>
        <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={false}
        />
        <BillUpDataModal ref={BillModal} refresh={methodsFn.handleSearch} />
        </div>
    )
}
export default AccountInfo