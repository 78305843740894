import React, { useState, useEffect } from 'react'
import { Modal, Form, Upload, Select, Input, Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { updateManage } from '../../api'

const {Option} = Select
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const ManageModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  // 回显数据
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        managePhone: modalData.managePhone,
        manageName: modalData.manageName,
      })
    }
  }, [visible, form, modalData])
  // 表单初始数据
  const initialFormValues = {
    managePhone: '',
    manageName: ''
  }
  const handleOk = async() => {
    const data = Object.assign({}, form.getFieldsValue(), {companyCode: modalData.companyCode})
    try {
      await form.validateFields()
      setConfirmLoading(true)
      const result = await updateManage(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('修改成功!')
        refresh()
      } else {
        setConfirmLoading(false)
        message.error(result.data.retMsg)
      }
    } catch(err) {
      console.log('__修改管理员', err)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false} 
      title="管理员账户" 
      visible={visible} 
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk} 
      width={740}>
      <Form form={form} initialValues={initialFormValues}>
        <Form.Item {...formItemLayout} name="manageName" rules={[{required: true}]} disabled label="姓名">
          <Input autoComplete="off" placeholder="请输入" />
        </Form.Item>
          <Form.Item {...formItemLayout} name="managePhone" rules={[
            {required: true},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || (/^1[3456789]\d{9}$/).test(value)) {
                  return Promise.resolve();
                }
  
                return Promise.reject('请输入正确的手机号!');
              },
            })]} 
            label="手机号">
          <Input autoComplete="off" placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ManageModal