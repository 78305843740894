import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  DatePicker,
  Popconfirm,
  Tag,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import SecondStatementDetail from './SecondStatementDetail'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList } from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'340px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 7},
    wrapperCol:{ span: 17}
 }

function SecondStatement(props) {
    let path = 'platform:StaffAccount'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const initSearch = {
        // companyCode:''
    }
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])

    const columns = [
        {
            title: '企业',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
        }, 
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 150,
            align:'center',
        }, 
        {
            title: '落地模式',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
        }, 
        {
            title: '二类户开设时间',
            dataIndex: 'accountTime',
            key: 'accountTime',
            width: 150,
            align:'center',
        }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
        },{
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        }, {
            title: '二类户账号',
            dataIndex: 'accountCardNumber',
            key: 'accountCardNumber',
            width: 150,
            align:'center',
            
        }, 
        {
        title: '操作',
        key: 'action',
        width: 220,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.goDetail(record) }>查看</a>}
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <Form.Item style={itemStyle} {...labelStyleObj} name='taxEnterpriseCode' label="结算通道">
                        <Select allowClear placeholder="全部">
                        {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </Form.Item>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <Form.Item style={itemStyle} {...labelStyleObj} name="planType" label="落地方案">
                        <Select allowClear placeholder="全部">
                            <Option value="1">临时税务登记</Option>
                            <Option value="2">个体工商户</Option>
                            <Option value="3">委托代征</Option>
                        </Select>
                    </Form.Item>
                    <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                        <Input placeholder="姓名丨身份证号" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            return formObj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/staffAccount/list',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/secondStatement?idNumber='+record.idNumber+'&name='+record.name)
            setParmObj({
                idNumber:record.idNumber,
                name:record.name,
            })
        },
        // 获取所有结算通道
        async getTaxEnterpriseListall () {
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/secondStatement')
        },
        handleExport(){
            request.downExport('/platform/staffAccount/export',methodsFn.getsearchObj())
        },
      
    }

    useEffect(() => {
        methodsFn.getTaxEnterpriseListall()
        methodsFn.getCompanyList()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                idNumber:searchParam.idNumber,
                name:searchParam.name,
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content' }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.accountCardNumber}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 : <SecondStatementDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                 
            </div>
    )
}

export default SecondStatement
