import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Select,
  Row,
  Col,
  Button,
  Radio,
  message,
  Upload,
  Checkbox
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getCityByCompanyCode, getTaxEnterpriseList } from '../../../../../api/api'
import { contractTempInsert, contractTempGetDetail, contractTempUpdate } from '../../api'
const topCol24 = {
  span: 24,
}
const colSpan = {
  labelCol: { flex: '150px' }, wrapperCol: { flex: '1' }
}
const colSpan1 = {
  labelCol: { flex: '150px' }, wrapperCol: { flex: '300px' }
}
const fieldLists =[
  { field: "name", name: "姓名", flag: 0, span: 8},
  { field: "idNumber", name: "身份证号", flag: 0, span: 8 },
  { field: "phone", name: "手机号", flag: 0, span: 8 },
  { field: "companyName", name: "公司名称", flag: 0, span: 8 },
  { field: "cityName", name: "城市", flag: 0, span: 8 },
  { field: "storeName", name: "门店", flag: 0, span: 8 },
  { field: "brandName", name: "品牌名称", flag: 0, span: 8 },
  { field: "taxEnterpriseCode", name: "结算通道", flag: 0, span: 8 },
]
const action = "/hire/common/uploadFile"
const AddMoel = (props,ref) => {
  const { refresh, companyList } = props
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [cityList, setCityList] = useState([])
  const [allCityList, setAllCityList] = useState([])
  const [title, setTitle] = useState()
  const [recordData, setRecordData] = useState({})
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  const [BusinessList, setBusinessList] = useState([])
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {
    companyCode: null,
    cityIds: [],
    business: [],
    signType: '',
    taxEnterpriseCode: null,
    pdfUrl: '',
    fillField: '',
  }
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: () => {
      setTitle('新增')
      setRecordData({})
      form.resetFields()
      setVisible(true);
      setShowCode(false)
      getTaxEnterpriseListall()
    },
    editModal: (record) => {
      setTitle('修改')
      setCityList([])
      setAllCityList([])
      setBusinessList([])
      setShowCode(false)
      getCityList(record.companyCode,record.cityIds.split(','))
      // cityChange(record.cityIds.split(','))
      getDetail(record)
      getTaxEnterpriseListall()
    }
  }));
   // 获取所有结算通道
  const getTaxEnterpriseListall = async () => {
      const res = await getTaxEnterpriseList()
      if (res.data.success) {
        if(res.data.data&&res.data.data.length>0){
          let lsit = res.data.data.filter(item => item.authorizationFlag == 1)
          setTaxEnterpriseLis(lsit)
        }else{
          setTaxEnterpriseLis([])
        }
      }
  }
  const getDetail = (record)=>{
    contractTempGetDetail({templateNo:record.templateNo}).then(res=>{
      if(res.data.success){
        setVisible(true);
        let data = {
          ...res.data.data,
          cityIds: res.data.data.cityIds.split(','),
          business: res.data.data.business?res.data.data.business.split(','):[],
          signType: res.data.data.signType,
          taxEnterpriseCode: res.data.data.taxEnterpriseCode,
          fillField: res.data.data.fieldList,
          pdfUrl: [{url: res.data.data.pdfUrl, uid: 1, name: '预览', status: 'done'}]
        }
        setShowCode(data.signType=='2')
        setRecordData(data)
        form.setFieldsValue(data)
      }else{
          message.error(res.data.retMsg)
      }
    })
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      let list = e&&e.map((item,index)=>{
        if(item.status === 'done'&&item.response){
          return {
            url: item.url || item.response.data[0].url,
            uid: item.uid || index,
            name: item.name||item.url,
            status: 'done',
          }
        }else{
          return {
            url: item.url,
            uid: item.uid || index,
            name: item.name||item.url,
            status: 'done',
          }
        }
      })
      return list;
    }
    let list1 = e&&e.fileList.map((item,index)=>{
      if(item.status === 'done'&&item.response){
        console.log(item.response)
        return {
          url: item.url || item.response.data[0].url,
          uid: item.uid || index,
          name: item.name||item.url,
          status: 'done',
        }
      }else{
        return {
          url: item.url,
          uid: item.uid || index,
          name: item.name||item.url,
          status: 'done',
        }
      }
    })
    return list1;
  };
  // 上传图片，把图片地址存入 licenseFileUrl
  const changeFile = ({ file, fileList }) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
  }
  const onAllChange = (e) => {
    setCheckedAll(e.target.checked)
    if(e.target.checked){
      let list = cityList.map(e=>{
        return e.cityId
      })
      form.setFieldsValue({
        cityIds: list
      })
      cityChange(list)
    }else{
      form.setFieldsValue({
        cityIds: []
      })
    }
  }
  const getCityList = async (value,cityIdList)=> {
    setAllCityList([])
    const result = await getCityByCompanyCode({ companyCode: value })
    if(result){
      setAllCityList(result)
      // 对result去重
      let list = result.filter((item,index,arr)=>{
        return arr.findIndex(e=>e.cityId===item.cityId)===index
      })
      setCityList(list)
      if(cityIdList){
        let busines = result.filter(e=>cityIdList.includes(e.cityId)&&e.business)
        busines = busines.filter((item,index,arr)=>{
          return arr.findIndex(v=>v.business===item.business)===index
        })
        setBusinessList(busines)
      }
    }else{
      setCityList([])
      setAllCityList([])
    }
  }
  const companyChange = (value)=>{
    form.setFieldsValue({
      cityIds: [],
      business: [],
    })
    setCityList([])
    setAllCityList([])
    getCityList(value)
  }
  const cityChange = (value)=>{
    let list = allCityList.filter(e=>value.includes(e.cityId)&&e.business)
    list = list.filter((item,index,arr)=>{
      return arr.findIndex(v=>v.business===item.business)===index
    })
    setBusinessList(list)
  }
  const signTypeChange = ()=>{
    form.setFieldsValue({
      taxEnterpriseCode: null
    })
    setShowCode(form.getFieldValue('signType')=='2')
  }
  const handleOkEdit = (data) => {
    contractTempUpdate(
      { 
        templateNo: recordData.templateNo, 
        signType: data.signType, 
        taxEnterpriseCode: data.taxEnterpriseCode,
        cityIds: data.cityIds? data.cityIds.join(','): '',
        business: data.business? data.business.join(','): '' 
      }
    ).then(result=>{
      if(result.data.success){
        message.success(result.data.retMsg)
        setVisible(false)
        refresh()
      }else{
        message.error(result.data.retMsg)
      }
      setBtnLoading(false)
    }).catch(()=>{
      setBtnLoading(false)
      message.error('网络错误')
    })
  }
  const handleOk =  () => {
    setBtnLoading(true)
    form.validateFields().then((values)=>{
      const data = { ...values }
      if(title=='修改'){
        handleOkEdit(data)
        return
      }
      let pdfUrl = values.pdfUrl.map(e=>( e.url ))
      data.pdfUrl = pdfUrl.join(',')
      data.cityIds = values.cityIds? values.cityIds.join(',') : ''
      data.business = values.business? values.business.join(',') : ''
      data.fillField = values.fillField? values.fillField.join(',') : ''
      let item = companyList.filter(ele=>{
        return ele.companyCode === data.companyCode 
      })[0]
      data.companyName = item.companyName
      contractTempInsert(data).then(result=>{
        if(result.data.success){
          message.success(result.data.retMsg)
          setVisible(false)
          refresh()
        }else{
          message.error(result.data.retMsg)
        }
        setBtnLoading(false)
      }).catch(()=>{
        setBtnLoading(false)
        message.error('网络错误')
      })
    }).catch((err)=>{
      setBtnLoading(false)
      message.error('请完善表单')
    })
  }
  return (
    <>
    <Modal maskClosable={false} title={title} style={{minWidth: '1000px'}} width='1000px' forceRender visible={visible} onCancel={() => { setVisible(false) }}
      footer={[
        <Button key="back" loading={btnLoading} onClick={() => { setVisible(false) }}>取消</Button>,
        <Button key="link" loading={btnLoading} type="primary" onClick={()=>{handleOk()}} >确定</Button>,
      ]}
    >
      <Form initialValues={initialFormValues} form={form}>
        <Row className="margin-15">
          <Col {...topCol24}>
            <Form.Item name="companyCode" rules={[{ required: true, message: '请选择企业名称!' }]}  {...colSpan1} label="企业名称">
              <Select allowClear placeholder="请选择企业名称" disabled={title == '修改'} onChange={companyChange} >
                {companyList.map(item => {
                  return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col {...topCol24}>
            <div style={{width: '600px',display: 'flex', alignItems: 'center' }}>
              <Form.Item name="cityIds" {...colSpan1} style={{ width: '450px'}} label="适用城市" >
                <Select allowClear placeholder="请选择城市" mode="multiple" onChange={cityChange} >
                  {cityList.map(item => {
                    return <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                  })
                  }
                </Select>
              </Form.Item>
              {form.getFieldValue('companyCode')&&<Checkbox style={{ marginLeft: '10px', marginBottom: '24px'}} checked={checkedAll} onChange={onAllChange}>全部</Checkbox>}
            </div>
          </Col>
          <Col {...topCol24}>
            <Form.Item name="business" {...colSpan1} label="适用业务" >
              <Select allowClear placeholder="请选择适用业务" mode="multiple" >
                {BusinessList.map(item => {
                  return <Option value={item.business} key={item.business}>{item.business}</Option>
                })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col style={{display: 'flex', alignItems: 'center' }} {...topCol24}>
            <Form.Item label="承揽盖章企业" name="signType" style={{width: '350px'}} labelCol={ { flex: '150px' }} wrapperCol={{ flex: '200px' }} rules={[{ required: true, message: '请选择承揽盖章企业!' }]}  >
              <Radio.Group onChange={signTypeChange} >
                <Radio value="1">本企业</Radio>
                <Radio value="2">结算通道</Radio>
              </Radio.Group>
            </Form.Item>
            {showCode && <Form.Item name="taxEnterpriseCode" style={{width: '300px'}} labelCol={ { flex: '0px' }} wrapperCol={{ flex: '300px' }}
              rules={[{ required: true, message: '请选择结算通道!' }]} >
              <Select allowClear placeholder="请选择结算通道">
                {TaxEnterpriseLis.map((e)=><Select.Option key={e.code} value={e.code}>{e.enterpriseName}</Select.Option>)}
              </Select>
            </Form.Item>}
          </Col>
          <Col {...topCol24}>
            <Form.Item label='签署文件' name='pdfUrl' rules={[{ required: true, message: '请上传!' }]} valuePropName="fileList" getValueFromEvent={normFile} {...colSpan} >
              <Upload name="files" 
                data={uploadObj} 
                disabled={title == '修改'}
                action={action} 
                accept=".pdf"
                maxCount={1}
                onChange={({ file, fileList }) => changeFile({ file, fileList })}
                >
                {title == '新增'&&<Button type="primary" icon={<UploadOutlined />} >上传</Button>}
              </Upload>
            </Form.Item>
          </Col>
          <Col {...topCol24}>
            <Form.Item label='填充信息' name='fillField' {...colSpan} >
              <Checkbox.Group disabled={title == '修改'} style={{width: '100%'}} >
                <Row>
                  {fieldLists.map((e)=>(<Col key={e.field} span={e.span}>
                    <Checkbox value={e.field} >{e.name}({e.field})</Checkbox>
                  </Col>))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



