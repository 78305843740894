import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  message,
  Select
} from 'antd';
import { dealBack,  backSettlementList } from '../api'
const FormItem = Form.Item;
const ConfirmModel = (props, ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [settlement, setSettlement] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [serviceAmount, setServiceAmount] = React.useState();
  const [taxAmount, setTaxAmount] = React.useState();
  const [settlementDetailNo, setSettlementDetailNo] = React.useState();
  const initialValues = {
  }
  const { Option } = Select
   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      setVisible(true);
      setRow({...record})
      if(record.serviceStatus!=1&&record.taxStatus!=1){
        setServiceAmount()
        setTaxAmount()
        setSettlementDetailNo()
      }
      form.resetFields()
      form.setFieldsValue(record)
      getJieSuan(record)
    }
  }));
  useEffect(() => {
  }, [])
  const getJieSuan = (record)=>{
    let data = {
      companyCode: record.companyCode,
      taxEnterpriseCode: record.taxEnterpriseCode,
      accountName: record.accountName,
      accountNumber: record.accountNumber,
      amount: record.amount,
    }
    backSettlementList(data).then(res=>{
      if(res.data.success){
        setSettlement(res.data.data)
        if(record.serviceStatus==1||record.taxStatus==1){
          let item = res.data.data.filter(ele=>{
            return record.settlementNo == ele.settlementNo
          })[0]
          setServiceAmount(item.serviceAmount)
          setTaxAmount(item.taxAmount)
          setSettlementDetailNo(item.settlementDetailNo)
        }
      }else{
        message.error(res.data.retMsg)
      }
    }).catch(()=>{
    })
  }
  const onChange = (value)=>{
    let item = settlement.filter(ele=>{
      return value == ele.settlementNo
    })[0]
    setServiceAmount(item.serviceAmount)
    setTaxAmount(item.taxAmount)
    setSettlementDetailNo(item.settlementDetailNo)
  }
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        setLoading(true);
        console.log(values)
        methodsFn.handleAdd(values)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      let data = {
        backNo: row.backNo,
        settlementNo: obj.settlementNo,
        settlementDetailNo: settlementDetailNo,
        serviceAmount: serviceAmount,
        taxAmount: taxAmount,
      }
      dealBack(data).then(res=>{
        if(res.data.success){
          setVisible(false);
          setLoading(false);
          message.success(res.data.retMsg)
          props.refresh('refresh')
        }else{
          message.error(res.data.retMsg)
          setLoading(false);
        }
      }).catch(()=>{
        setLoading(false);
      })
    },
  }

  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='退回'
        width='800px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);setRow({})}}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
          initialValues={initialValues}
          form={form}
        >
          <FormItem name="companyName" label="企业名称" >
              <Input disabled />
          </FormItem>
          <FormItem name="accountName" label="姓名" >
              <Input disabled />
          </FormItem>
          <FormItem name="amount" label="佣金" >
              <Input disabled />
          </FormItem>
          <FormItem name="settlementNo" label="结算单" rules={[{ required: true, message: '请选择结算单' }]} >
            <Select allowClear placeholder='请选择' onChange={(value) => onChange(value)} disabled={row.serviceStatus==1||row.taxStatus==1} >
              {settlement.map(e=>{
                return <Option key={e.settlementNo} value={e.settlementNo}>{e.settlementNo}</Option>
              })}
            </Select>
          </FormItem>
          <FormItem label="服务费" >
              <Input disabled value={row.serviceStatus==1?'':serviceAmount} />
          </FormItem>
          <FormItem label="税费" >
              <Input disabled value={row.taxStatus==1?'':taxAmount} />
          </FormItem>
          <FormItem label="合计应返回" >
              {row.serviceStatus==1&&<Input disabled value={taxAmount||''} />}
              {row.taxStatus==1&&<Input disabled value={serviceAmount||''} />}
              {row.serviceStatus!=1&&row.taxStatus!=1&&<Input disabled value={(taxAmount+serviceAmount)||''} />}
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(ConfirmModel)



