import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, message } from 'antd';
import axios from 'axios'
import qs from 'qs'
// 发布方反查
const OrderInvestigate = (props) => {
  let search = props.location.search
  const [form] = Form.useForm();
  const [formData,setFormData] = useState({})
  let formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  }

  const getData = ()=>{
    let data = qs.parse(props.history.location.search.replace(/\?/g, ''));
    axios({
      method: 'get',
      url: `/hire/platform/reverseData/ddInfo`,
      // url: `/platform/reverseData/ddInfo`,
      params: data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if(res.data.success){
        setFormData(res.data.data)
        form.setFieldsValue(res.data.data)
      }else{
        message.error(res.data.retMsg)
      }
    }).catch(err => {
        console.log(err)
    })
   
  }

  useEffect(() => {
    getData()
  },[])
  return (
    <>
     <Form
      {...formItemLayout}
      form={form}
      initialValues={formData}
    >
      <Row>
        <Col span={12}>
          <Form.Item name="ddbh" label="订单标号">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="dduuid" label="订单UUID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfuuid" label="服务方UUID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfxm" label="服务方姓名">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfsfzjlx" label="身份证件类型">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfsfzjhm" label="身份证件号码">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfjsds" label="当月结算总单量">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fbfddje" label="结算总金额">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfddje" label="实际结算到手金额">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ptfwf" label="平台合计扣款">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="shijian" label="任务描述">
            <Button type="link" href={"/TaskDescription"+ search}>查看明细</Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="jssj" label="订单最终结算时间">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="skrxm" label="收款人姓名">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="skrsfzjhm" label="收款人身份证件号码">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfzffs" label="服务支付方式">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="yhls" label="银行流水">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="yhkh" label="结算账户">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    </>
  )
}

export default OrderInvestigate