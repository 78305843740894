import React, { useState, useEffect } from 'react'
import {
    PageHeader,
    Button,
    Table,
    Space,
    message
} from 'antd'
import { getCompanyPipelineDetail } from '../api'
import { getCompanyBalances } from '../../../../api/api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'

const PlatformDetail = (props) => {
  let path = 'bankSon'
  const [tableData, setTableData] = useState([])
  const companyCode = props.match.params.id
  const companyName = props.location.state.companyName
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  const [balance, setBalance] = useState({
    actualBal: '',
    amt: '',
    freezeAmt: ''
  })
  // 获取详情
  const getDetail = async(page) => {
    let data = {
      companyCode: companyCode,
      pageNum: page.pageNum,
      pageSize: page.pageSize
    }
    try {
      const res = await getCompanyPipelineDetail(data)
      console.log(res)
      if (res.data.success) {
        setTableData(res.data.data.list)
        setPagination(prev => {
          return {
            ...prev,
            total: res.data.data.totalCount
          }
        })
      } else {
        message.error(res.data.retMsg)
      }
    } catch(err) {
      console.log(err)
    }
  }
  // 获取余额
  const getBalance = async() => {
    try {
      const res = await getCompanyBalances({companyCode: companyCode})
      console.log(res)
      if (res) {
        setBalance({
          actualBal: res.actualBal,
          amt: res.amt,
          freezeAmt: res.freezeAmt
        })
      }
    } catch(err) {

    }
  }
  useEffect(() => {
    getDetail(pagination)
    getBalance()
  }, [])
  const tableColumns = [
    {title: '进出账时间', dataIndex: 'dealTime', key: 'dealTime', align: 'center'},
    {title: '打款企业', dataIndex: 'companyName', key: 'companyName', align: 'center'},
    {title: '收支类型', dataIndex: 'dealType', key: 'dealType',  align: 'center',
      render: dealType => {
        switch(dealType) {
          case 'R_10':
            return <span>充值</span>
            break;
          case 'C_10':
            return <span>批量打款</span>
            break;
          case 'R_20':
            return <span>退还服务费</span>
            break;
          case 'R_30':
            return <span>银行失败入账</span>
            break;
        }
      }
    },
    {title: '变动金额（元）', dataIndex: 'dealAmount', key: 'dealAmount',  align: 'center', 
      render: (dealAmount, record) => {
        if (dealAmount > 0 && record.dealType == 'R_20') {
          return '-' + dealAmount
        } else if(dealAmount == 0) {
          return dealAmount
        } else {
          return '+' + dealAmount
        }
      }
    },
    {title: '状态', dataIndex: 'dealAmount', key: 'dealAmount',  align: 'center',
      render: dealAmount => (
        <span>交易成功</span>
      )
    }
  ]
  const tablePageChange = (page, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
    getDetail({pageNum: page, pageSize: pageSize})
  }
  const exportDetail = () => {
    let data = {
      companyCode: companyCode
    }
    request.downExport('/platform/companydetail/exportAccountsCapitalDetail', data)
  }
  return (
    <>
      <PageHeader
        title="查看"
        onBack={() => props.history.goBack()}
      />
      <div style={{background: '#fff'}}>
        <Space size="large">
          <div style={{marginLeft: '20px'}}>企业名称：{ companyName }</div>
          <div>账户余额：￥{balance.amt} = ￥{balance.freezeAmt}（审核冻结）+ ￥{balance.actualBal}（可用余额）</div>
          {store.getState().userReducer.permissions.indexOf(`${path}:DC`)>-1&&<Button onClick={exportDetail}>导出</Button>}
        </Space>
        <Table
          columns={tableColumns} 
          dataSource={tableData}
          scroll={{ x: 'max-content' }}
          rowKey="dealNo"
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            showTotal:(total) =>  `共 ${total} 条数据`,
            onChange: (page, pageSize)　=> tablePageChange(page, pageSize)
          }}>
        </Table>
      </div>
    </>
  )
}

export default PlatformDetail