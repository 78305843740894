import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Table,
  Input,
  Space,
  Row,
  Col,
} from 'antd';
const { TextArea } = Input;

const topCol = {
  span:8,
}
const endCol = {
  span:8,
  offset:6
}


const MyModel3 = (props,ref) => {
  const [visible, setVisible] = React.useState(false);
  


   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
        setVisible(val);
      }
  }));
  const columns = [
    {
        title: '打款时间',
        dataIndex: 'paymentTime',
        key: 'paymentTime',
    }, {
        title: '企业名称',
        dataIndex: 'companyCodeName',
        key: 'companyCodeName',
    },{
        title: '佣金',
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
    },
  ];



  return (
    <>
      <Modal maskClosable={false}
        title="查看"
        width='1000px'
        visible={visible}
        footer={false}
        onCancel={() => {setVisible(false)}}
      >
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">进入审核时间:</span>
            <Input placeholder={props.detailObj.createTime} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">落地城市:</span>
            <Input placeholder={props.detailObj.cityName} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">企业名称:</span>
            <Input placeholder={props.detailObj.companyCodeName} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">落地方案:</span>
            <Input placeholder={props.detailObj.planTypeName}  disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">账单月份:</span>
            <Input placeholder={props.detailObj.month} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">姓名:</span>
            <Input placeholder={props.detailObj.name} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">身份证号:</span>
            <Input placeholder={props.detailObj.idNumber} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">佣金:</span>
            <Input placeholder={props.detailObj.commissionAmount} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">税费:</span>
            <Input placeholder={props.detailObj.taxAmount} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">服务费:</span>
            <Input placeholder={props.detailObj.serviceAmount} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">进入审核的原因:</span>
            <Input placeholder={props.detailObj.auditReasonName} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">打款备注:</span>
            <Input placeholder={props.detailObj.content} disabled={true} />
          </div>
        </Col>
      </Row>
      {
       props.detailObj.auditReason.indexOf(2) > -1
        ? <div>
          <h3>当月累计打款记录</h3>
          <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.companyCode}
            dataSource={props.detailObj.paymentList} 
            />
        </div>
        :null
      }
     
     
      
        <h3>审核操作日志</h3>
        <div>
          <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">操作时间:</span>
                <Input placeholder={props.detailObj.auditTime} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">操作人:</span>
                <Input placeholder={props.detailObj.auditUser} disabled={true} />
              </div>
            </Col>
          </Row>
          <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">操作类型:</span>
                <Input placeholder={props.detailObj.auditStatusName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">补缴服务费:</span>
                <Input placeholder={props.detailObj.addServiceAmount} disabled={true} />
              </div>
            </Col>
          </Row>
            <Space align="center">
            <span style={{'width': '124px','paddingLeft': '50px'}}>审核描述:</span>
              <TextArea cols="100" disabled={true} placeholder={props.detailObj.auditContent} />
            </Space>
        </div>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel3)



