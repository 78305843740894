import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  message
} from 'antd';
import { getAreaList } from '../../../../../api/api'
import { taxEnterpriseInsert } from '../../api'
const topCol = {
  span: 24,
}
const { Option } = Select
const itemCol = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 }
}
const AddMoel = (props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  
  const [title, setTitle] = useState('新增');
  const [disabled, setDisabled] = useState(false);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [countyList, setcountyList] = useState([]);
  const [form] = Form.useForm()
  const initialFormValues = {}

  useEffect(() => {
  }, [])
  
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle) => {
      form.resetFields()
      setVisible(true);
      setTitle(ctitle)
      setDisabled(false)
      getAreaTypeList()
    }
  }));
 
 
 
  
  // 获取城市数据
  const getAreaTypeList = async (type=1,pId='') => {
    let result = await getAreaList({
      level: type,
      pId: pId
    })
    if(result.data.success){
      if(type==1){
        setprovinceList(result.data.data)
      }else if(type==2){
        setcityList(result.data.data)
      }else{
        setcountyList(result.data.data)
      }
    }
  }

  // 新增
  const handleInsert = async (params) => {
    console.log(params)
    let result = await taxEnterpriseInsert(params)
    console.log(result)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query')
    } else {
      message.error(result.data.retMsg)
    }
  }

  const handleOk = async () => {
    
    form.validateFields().then(async (valus)=>{
      const params = form.getFieldsValue()
      handleInsert(params)
    }).catch(()=>{
      message.error('请完善表单')
    })
  }

  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='600px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={()=>{handleOk()}}>
            保存
          </Button>]:null}
      >
        <Form initialValues={initialFormValues} form={form}>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="enterpriseName" rules={[{ required: true }]} {...itemCol} label="结算通道名称">
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="shortName" {...itemCol} rules={[{ required: true }]} label="结算通道简称">
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="provinceId" {...itemCol} rules={[{ required: true }]} label="落地省份">
              <Select placeholder="请选择" disabled={disabled } onChange={(value)=>{getAreaTypeList(2,value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  {provinceList.map(item => (
                    <Option value={item.id} key={item.id}>{item.areaName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityId" {...itemCol} rules={[{ required: true }]} label="落地城市">
              <Select placeholder="请选择" disabled={disabled } onChange={(value)=>{getAreaTypeList(3,value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  {cityList.map(item => (
                    <Option value={item.id} key={item.id}>{item.areaName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="countyId" {...itemCol} rules={[{ required: true }]} label="落地区县">
              <Select placeholder="请选择" disabled={disabled } getPopupContainer={triggerNode => triggerNode.parentElement} >
                  {countyList.map(item => (
                    <Option value={item.id} key={item.id}>{item.areaName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        
      </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



