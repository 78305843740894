import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Radio, Select, Divider, message, InputNumber, DatePicker, Switch } from 'antd'
import { useImmer } from 'use-immer'
import _ from 'lodash'
import { updataConfig } from '../../api'
import { getTaxEnterpriseList } from '../../../../../api/api'

import moment from 'moment'

import '../../companyEnter/index.css'


const {Option} = Select

const ConfigModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [cityCounty, setCityCounty] = useState([]) // 临时税务城市片区
  const [temporaryDuty, setTemporaryDuty] = useState(false) // 是否勾选临时税务
  const [privateCityCounty, setPrivatecityCounty] = useState([]) // 个体工商户城市片区
  const [privately, setPrivately] = useState(false) // 是否勾选个体工商
  const [commissionBasisCityCounty, setCommissionBasisCityCounty] = useState([]) // 委托代征城市片区
  const [commissionBasis, setCommissionBasis] = useState(false) // 是否勾选委托代征
  const [effectType, setEffectType] = useState(2)
  const [effectTime, setEffectTime] = useState()
  // 委托代征
  const [commissionBasisData, updateCommissionBasisData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 3,
    serviceValue: null,
    sendConfirmFlag: null,
    directFlag: null,
  })
  // 回显数据
  useEffect(() => {
    if (visible) {
      const arr = _.groupBy(modalData.serviceConfigVoList, 'planType')
      updateCommissionBasisData(draft => {
        if (arr[3]) {
          setCommissionBasis(true)
          getCityCounty(3)
          draft.areaNo = arr[3][0].areaNo
          draft.cityId = arr[3][0].cityId
          draft.countyId = arr[3][0].countyId
          draft.taxEnterpriseCode = arr[3][0].taxEnterpriseCode
          draft.planType = arr[3][0].planType
          draft.serviceValue = arr[3][0].serviceValue||0
          draft.effectTime = arr[3][0].effectTime
          draft.sendConfirmFlag = arr[3][0].sendConfirmFlag
          draft.directFlag = arr[3][0].directFlag
        }
      })
    }
  }, [visible, modalData, updateCommissionBasisData])
  // 获取城市区县
  const getCityCounty = async(planType) => {
    try {
      const result = await getTaxEnterpriseList({planType:planType})
      if (result.data.success) {
        if (planType === 1) {
          setCityCounty(result.data.data)
        } else if(planType === 2) {
          setPrivatecityCounty(result.data.data)
        } else if(planType === 3){
          setCommissionBasisCityCounty(result.data.data)
        }else{
          message.error(result.data.retMsg)
        }
      } else {
        message.error(result.data.retMsg)
      }
      
    } catch(err) {
      console.log('__企业入驻城市片区', err)
    }
  }
  // 
  const temporaryDutyChange = (e) => {
    if (e.target.checked) {
      getCityCounty(1)
    }
    setTemporaryDuty(e.target.checked)
  }
  // 初次加载 获取落地配置的城市区县
  useEffect(() => {
    if (temporaryDuty) {
      getCityCounty(1)
    }
    if (privately) {
      getCityCounty(2)
    }
    if (commissionBasis) {
      getCityCounty(3)
    }
  }, [])
  // 更新配置数据 type 1 临时税务 2 个体工商  3 委托代征
  // field 字段名  index有的话 该字段是数组，name是field[index]的属性
  const updateData = (value, type, field, index, name) => {
    if (type === 3) {
      if (field === 'taxEnterpriseCode') {
        let arr = commissionBasisCityCounty.filter(item => item.code === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateCommissionBasisData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1) {
        updateCommissionBasisData(draft => {
          draft[field][index][name] = value
        })
      } else {
        if(field === 'directFlag'||field === 'sendConfirmFlag'){
          updateCommissionBasisData(draft => {
            draft[field] = value? 1:0
          })
        }else{
          updateCommissionBasisData(draft => {
            draft[field] = value
          })
        }
      }
    }
  }
  // 生效时间选择
  const effectChange = (value) => {
    console.log(value)
    setEffectType(value)
    if (value === 1) {
      setEffectTime(moment().format('YYYY-MM-DD HH:mm:ss'))
      updateCommissionBasisData(draft => {
        draft.effectTime = moment().format('YYYY-MM-DD HH:mm:ss')
      })
    }
    if (value === 2) {
      updateCommissionBasisData(draft => {
        draft.effectTime = effectTime
      })
    }
  }
  // 
  const dateChange = (value) => {
    console.log(value)
    setEffectTime(value)
    updateCommissionBasisData(draft => {
      draft.effectTime = moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
  }
  const handleOk = async() => {
    let serviceConfigVoList = []
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    if (!temporaryDuty && !privately && !commissionBasis) {
      message.warning('落地配置至少填写一个')
      return
    }
    if (!effectTime) {
      message.warning('请选择生效时间')
      return
    }
    const data = Object.assign({}, {companyCode: modalData.companyCode, serviceConfigVoList: serviceConfigVoList})
    try {
      setConfirmLoading(true)
      const result = await updataConfig(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('修改成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      setConfirmLoading(false)
      console.log('__修改管理员', err)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false} 
      title="落地配置" 
      visible={visible} 
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk} 
      width={1040}>
      <div className="config-modal">
      <div className="checkbox">委托代征</div>
      {commissionBasis &&
        <>
          <div className="row">
            <span className="row-tit">结算通道：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 3, 'taxEnterpriseCode')} value={commissionBasisData.taxEnterpriseCode} style={{ width: '400px' }}>
              {commissionBasisCityCounty.map(item => (
                <Option value={item.code} key={item.code}>{item.enterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <div>
              每笔<InputNumber value={commissionBasisData.serviceValue} min={0} onChange={(value) => updateData(value, 3, 'serviceValue')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">短信：</span>
            <div className='checkedmy' >
              是否发送充值确认结果短信
              <Switch checked={commissionBasisData.sendConfirmFlag} checkedChildren="是" onChange={(value) => updateData(value, 3, 'sendConfirmFlag')} unCheckedChildren="否" defaultChecked />
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否直发：</span>
            <div className='checkedmy' >
              <Switch checked={commissionBasisData.directFlag} checkedChildren="是" onChange={(value) => updateData(value, 3, 'directFlag')} unCheckedChildren="否" defaultChecked />
            </div>
          </div>
        </>
      }
      <Divider />
      <div className="row">
        <span>生效时间：</span>
        <Radio.Group onChange={(e) => effectChange(e.target.value)} value={effectType}>
          <Radio value={1}>立即生效</Radio>
          <Radio value={2}>
            <DatePicker format="YYYY-MM-DD HH:mm:ss" onChange={(value) => dateChange(value)} disabled={effectType === 1} showTime />
          </Radio>
        </Radio.Group>
      </div>
      </div>
    </Modal>
  )
}

export default ConfigModal