import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Button,
  DatePicker,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import CompanyInvoiceDetail from './CompanyInvoiceDetail'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index' 
import { getTaxEnterpriseList }　from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const itemStyle = {
     width:'350px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 6},
    wrapperCol:{ span: 18}
 }

function CompanyInvoiceLeft(props) {
    let path = 'platform:companyTicket'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const initSearch = {
    }
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '支付月份',
            dataIndex: 'payMonth',
            key: 'payMonth',
            width: 150,
            align:'center',
        }, {
            title: '未申请开票金额',
            dataIndex: 'noTicketAmount',
            key: 'noTicketAmount',
            width: 150,
            align:'center',
        },{
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            align: 'center',
            width: 170,
          },{
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 120,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {methodsFn.goDetail(record)}}>明细</a>}
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="支付月份" name="month" style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                    <FormItem label="结算通道" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                companyCode:formObj.companyCode,
                payMonth:month,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/companyTicket/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
      
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/companyTicketLeft?companyCode='+record.companyCode+'&payMonth='+record.payMonth+'&companyName='+record.companyName)
            setParmObj({
                companyCode:record.companyCode,
                payMonth:record.payMonth,
                companyName:record.companyName,
                
            })
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/companyTicketLeft')
        },
        handleExport(){
            request.downExport('/platform/companyTicket/export',methodsFn.getsearchObj())
        },
         // 获取所有结算通道
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
            }
        },
      
    }

    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                companyCode:searchParam.companyCode,
                payMonth:searchParam.payMonth,
                companyName:searchParam.companyName,
                
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content' }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => Math.random()}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 : <CompanyInvoiceDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                 
            </div>
    )
}

export default CompanyInvoiceLeft
