import React, { useState, useEffect } from 'react'
import {
    Modal,
    Form,
    Input
} from 'antd'
import { getLadderRefundDetail } from '../../api'

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18}
}
const CheckModal = (props) => {
    const { visible, close, backNo } = props
    const [form] = Form.useForm()
    const [serviceConfigVoList, setServiceConfigVoList] = useState({})
    const initialFormValues = {
        companyCodeName: '',
        month: '',
        commissionAmount: '',
        alreadyServiceAmount: '',
        shouldServiceAmount: '',
        backAmount: '',
        backCostTime: '',
        backCostUser: ''
    }
    
    // 获取详情
    const getDetail = async(backNo) => {
        console.log(backNo)
        try {
           const res = await getLadderRefundDetail({backNo: backNo})
            if (res.data.success) {
               console.log(res.data)
               form.setFieldsValue({
                    companyCodeName: res.data.data.companyCodeName,
                    month: res.data.data.month,
                    commissionAmount: res.data.data.commissionAmount,
                    alreadyServiceAmount: res.data.data.alreadyServiceAmount,
                    shouldServiceAmount: res.data.data.shouldServiceAmount,
                    backAmount: res.data.data.backAmount,
                    backCostTime: res.data.data.backCostTime,
                    backCostUser: res.data.data.backCostUser
                })
                setServiceConfigVoList(res.data.data.serviceConfigVoList)
            }
        } catch(err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (visible) {
           getDetail(backNo)
        }
    }, [visible, backNo])
    const onCancel = () => {
        close()
    }
    return (
        <Modal maskClosable={false} title="查看" visible={visible} footer={null} onCancel={onCancel}  width={840}>
            <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
                <Form.Item label="企业名称" name="companyCodeName">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="自然月" name="month">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="常规服务费收取规则">
                {serviceConfigVoList && serviceConfigVoList.length > 0 && serviceConfigVoList.map(item => (
                    <div key={item.planType}>
                        {item.planType === 1 && <div className="sub-title">临时税务登记</div>}
                        {item.planType === 2 && <div className="sub-title">个体工商户</div>}
                        <div className="box-row">
                            <div>
                                {item.serviceLadderFlag === 1 && 
                                    <>
                                        <p>阶梯式</p>
                                        {
                                        item.companyConfigDetailList && item.companyConfigDetailList.map(p=>
                                            <p key={p.sort}>第{p.sort}阶段：{p.minAmount}≤当月（自然月）累计批量佣金金额 &lt; {p.maxAmount}万时，单笔佣金的{item.serviceType === 1 ? '固定金额' : '百分比'}，每笔{p.value}{item.serviceType === 1 ? '元' : '%'}</p>
                                        )
                                        }
                                        
                                    </>
                                }
                                {item.serviceLadderFlag === 0 && 
                                    <>
                                        <p>非阶梯</p>
                                        <p>{item.serviceType === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔{item.serviceValue}{item.serviceType === 1 ? '元' : '%'}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                ))}
                </Form.Item>
                <Form.Item label="佣金流水" name="commissionAmount">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="已收常规服务费" name="alreadyServiceAmount">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="应收取的常规服务费" name="shouldServiceAmount">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="退还服务费" name="backAmount">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="退还操作时间" name="backCostTime">
                    <Input disabled></Input>
                </Form.Item>
                <Form.Item label="退还操作人" name="backCostUser">
                    <Input disabled></Input>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CheckModal