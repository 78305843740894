import React, { useRef, useState, useEffect } from 'react'
import {
    Row,
    Table,
    Select,
    Form,
    Input,
    Button,
    Space,
    PageHeader,
    Typography
} from 'antd';
import qs from 'qs'
import { request } from '../../../../../../utils/fetch'
const { Text } = Typography;
const FormItem = Form.Item;
const { Option } = Select;
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}

function Preview(props) {
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const initSearch = {
        status: '',
        keyWord: ''
    }
    const [tableData, setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [statData, setStatData] = useState({TG:{},JRSH:{}})
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 170,
            align: 'center',
        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
        },
        {
            title: '检查结果',
            dataIndex: 'checkResultName',
            width: 150,
            align: 'center',
        }, {
            title: '佣金（元）',
            dataIndex: 'commissionAmount',
            width: 150,
            align: 'center',
        }, {
            title: '收款卡号',
            dataIndex: 'bankNumber',
            width: 170,
            align: 'center',
        }, {
            title: '开户行',
            dataIndex: 'bankName',
            width: 150,
            align: 'center',
        }, {
            title: '打款备注',
            dataIndex: 'content',
            width: 150,
            align: 'center',
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            width: 150,
            align: 'center',
        }, {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            width: 150,
            align: 'center',
        }
    ];
    const columns2 = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 170,
            align: 'center',
        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
        },
        {
            title: '检查结果',
            dataIndex: 'checkResultName',
            width: 150,
            align: 'center',
        }, {
            title: '佣金（元）',
            dataIndex: 'commissionAmount',
            width: 150,
            align: 'center',
        }, {
            title: '打款备注',
            dataIndex: 'content',
            width: 150,
            align: 'center',
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            width: 150,
            align: 'center',
        }, {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            width: 150,
            align: 'center',
        }
    ];

    //组件渲染函数
    const myComponent = {
        totalTxt() {
            return (
                <div style={{ backgroundColor: '#fff' }}>
                    <PageHeader
                        onBack={() => props.history.goBack()}
                        title="预览"
                    />
                    <div style={{ padding: '0 30px 30px', backgroundColor: '#fff', marginBottom: '20px' }}>
                        <div style={{ marginBottom: '15px', borderTop: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: '20px' }}>
                            {statData.TG.rowCount && <Space>
                                <Text>检查通过：</Text>
                                <Text>{statData.TG.rowCount}条数据，</Text>
                                <Text>合计{searchParam.lineType == 1 ? '线上' : '线下'}放款{statData.TG.totalAmountCount}</Text>
                                <Text>（向{statData.TG.rowCount}人佣金打款￥{statData.TG.commissionAmountCount}</Text>
                                <Text>向平台支付服务费￥{statData.TG.serviceAmountCount}</Text>
                                <Text>向平台支付税费￥{statData.TG.taxAmountCount}）</Text>
                            </Space>
                            }
                        </div>
                        <div>
                            {
                            statData.JRSH.rowCount &&
                            <Space>
                                <Text type="danger">进入人工审核：</Text>
                                <Text type="danger">{statData.JRSH.rowCount}条数据，</Text>
                                <Text type="danger">合计放款￥{statData.JRSH.totalAmountCount} </Text>
                                <Text>（向{statData.JRSH.rowCount}人佣金打款 ￥{statData.JRSH.commissionAmountCount}</Text>
                                <Text>向平台支付服务费￥{statData.JRSH.serviceAmountCount} </Text>
                                <Text>向平台支付税费￥{statData.JRSH.taxAmountCount}）</Text>
                            </Space>
                            }
                        </div>
                    </div>
                </div>
            )
        },
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <Row>
                        <FormItem label="检查结果" name="checkResult" style={itemStyle} >
                            <Select>
                                <Option value='JRSH'>进入人工审核</Option>
                                <Option value='TG'>检查通过</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword" style={itemStyle} >
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                    </Row>
                    <Row justify='end' >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                    </Row>
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if(pageNum==1){
                methodsFn.getTableList(form.getFieldsValue())
            }
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList() {
            let formData = form.getFieldsValue()
            let result = await request.get('/platform/billUpload/getPreviewList', {
                pageSize: pageSize,
                pageNum: pageNum,
                divideCode: searchParam.divideCode,
                checkResult:formData.checkResult,
                keyword:formData.keyword
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        async getStatData() {
            let result = await request.get('/platform/billUpload/getPreviewStat', {
                divideCode: searchParam.divideCode,
            })
            console.log(result.TG);
            setStatData(result)
        },
    }

    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    }, [pageNum])

    useEffect(() => {
        methodsFn.getStatData()
    }, [])
    return (
        <div>
            {myComponent.totalTxt()}
            {myComponent.renderSearchForm()}
            <Table
                bordered
                scroll={{ x: 'max-content' }}
                columns={searchParam.lineType == 1 ? columns2 : columns}
                rowKey={(record) => record.idNumber}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
        </div>

    )
}

export default Preview
