// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import {decimalAdd} from '../../../../utils/auth.js'
const { Option } = Select;
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px',
     marginBottom: '0px'
}
 const itemStyle1 = {
    width:'300px',
    marginBottom:'0px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function SettleQueryDetail(props) {
    let path = "platform:Settlement"
    const [form] = Form.useForm();
    const {parmObj} = props
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
        }, 
        {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
            align:'center',
        },
        {
            title: '人员ID',
            dataIndex: 'eleCourierId',
            key: 'eleCourierId',
            width: 150,
            align:'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align:'center',
        },
        {
            title: '银行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align:'center',
        },
         {
            title: '承揽费用（元）',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
        }, 
        {
            title: '状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            width: 150,
            align:'center',
        },
        {
            title: '失败描述',
            dataIndex: 'failDesc',
            key: 'failDesc',
            width: 150,
            align:'center',
        }
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row style={{width: '1000px'}} >
                        <Col span={8} >
                            <FormItem label="企业名称" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.companyCodeName}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="结算通道" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.taxEnterpriseName}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="任务名称" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.taskName}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="任务总额" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.totalServiceAmount&&decimalAdd([parmObj.totalServiceAmount,parmObj.totalCommissionAmount])}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="服务费" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.totalServiceAmount}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="承揽费用" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.totalCommissionAmount}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="结算单生成时间" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.statusUpdateTime}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="任务单号" style={itemStyle1} {...labelStyleObj}>
                                {parmObj.billNo}
                            </FormItem>
                        </Col>
                        <Col span={8} >
                            <FormItem label="参与人数" style={itemStyle} {...labelStyleObj}>
                                {parmObj.staffCount}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <FormItem label="状态" name="paymentStatus"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={'YZF'}>已支付</Option>
                                <Option value={'ZFSB'}>支付失败</Option>
                                <Option value={'FQ'}>放弃支付</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    type:form.getFieldsValue().type,
                    paymentStatus:form.getFieldsValue().paymentStatus,
                    keyword:form.getFieldsValue().keyword,
                    settlementNo:parmObj.settlementNo,
                    lineType:parmObj.lineType,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/settlement/getDetailList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleExport(){
            request.downExport('/platform/settlement/exportDetail',methodsFn.getsearchObj())
        }
   
    }

    useEffect(() => {
        var obj = {
            type:form.getFieldsValue().type,
            paymentStatus:form.getFieldsValue().paymentStatus,
            keyword:form.getFieldsValue().keyword,
            settlementNo:parmObj.settlementNo,
            lineType:parmObj.lineType,
        }
        methodsFn.getTableList(obj)
    
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="明细"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.idNumber}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(SettleQueryDetail)
