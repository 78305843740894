import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Modal,
  Input,
  Button,
  DatePicker,
  Space,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
const { Option } = Select;
const FormItem = Form.Item;
const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
}

function PlatformCheckDetail(props) {
    const [form] = Form.useForm();
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [visible, setVisible] = React.useState(false);
    const [month, setMonth] = React.useState('');
    const [companyList,setCompanyList] = useState([])
    const [companyName,setCompanyName] = useState()
    const [tableData,setTableData] = useState([])
    const [tableData2,setTableData2] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [pageNum2,setPageNum2] = useState(1)
    
     
    const columns = [
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
            
        }, {
            title: '子账户类型',
            dataIndex: 'accountType',
            key: 'accountType',
            width: 150,
            align:'center',
            render: (text, record) => {
                if(record.accountType == 10){
                    return '服务费'
                }else if(record.accountType == 20){
                    return '税费'
                }else{
                    return '企业'
                }
            }
            
        }, {
            title: '子账户余额',
            dataIndex: 'accountAmount',
            key: 'accountAmount',
            width: 150,
            align:'center',
        }, 
        {
            title: '操作',
            key: 'action',
            width: 220,
            align:'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() =>  methodsFn.hanldeDetail(record)}>明细流水</a>
                </Space>
            ),
        },
       
     
    ];
    const modalTable = [
        {
            title: '进出账时间',
            dataIndex: 'dealTime',
            key: 'dealTime',
            width: 150,
            align:'center',
            
        }, {
            title: '收支类型',
            dataIndex: 'dealType',
            key: 'dealType',
            width: 150,
            align:'center',
            render: (text, record) => {
                if(record.dealType == 'R_10'){
                    return '充值'
                }else if(record.dealType == 'C_10'){
                    return '打款'
                }else if(record.dealType == 'R_20'){
                    return '平台退费'
                }else{
                    return '银行失败入账'
                }
            }
            
        }, {
            title: '变动金额（元)',
            dataIndex: 'dealAmount',
            key: 'dealAmount',
            width: 150,
            align:'center',
        }, 
     
       
     
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem  label="企业名称" name="companyCode"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                {
                                    companyList.map(item => {
                                        return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="子账户类型" name="accountType" style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={10}>服务费</Option>
                                <Option value={20}>税费</Option>
                                <Option value={30}>企业</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>
                        </FormItem>
                    </Row>
                </Form>
            )
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    companyCode:form.getFieldsValue().companyCode,
                    accountType:form.getFieldsValue().accountType,
                    
                    // checkMonth:parmObj.checkMonth,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/platformaccountcheck/listDetail',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
            setCompanyList(result)
        },
        async hanldeDetail(record){
            setVisible(true)
            setCompanyName(record.companyName)
            setPageNum2(1)
            let result = await request.get('/platform/platformaccountcheck/listTransactionDetail',{
                checkMonth:parmObj.checkMonth,
                companyCode:record.companyCode,
                pageSize:pageSize,
                pageNum:pageNum2,
            })
            //    setTotal(result.totalCount)
            setTableData2(result.list)
        },
        handleExport(){
            request.downExport('/platform/platformaccountcheck/exportDetail',methodsFn.getsearchObj())
        }
   
    }
    useEffect(() => {
        methodsFn.getCompanyList()
        
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])
   
    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="明细"
                // subTitle={parmObj.companyName}
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
        <Modal maskClosable={false}
            title="明细流水"
            width='800px'
            visible={visible}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <h3>企业名称：{companyName}</h3>
            <h3>对账月份： {parmObj.checkMonth}</h3>
            <Table 
                scroll={{ x: 'max-content' }}
                columns={modalTable} 
                rowKey={(record) => record.id}
                pagination={false}
                dataSource={tableData2} 
            />
          
        </Modal>
         
        </div>
    )
}

export default withRouter(PlatformCheckDetail)
