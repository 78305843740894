import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Tag,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import OfflineConfirmDetail from './OfflineConfirmDetail'
 import MyModel1 from './MyModel1';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList } from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function OfflineConfirm(props) {
    let path = 'platform:BillOffline'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const initSearch = {
        date:[],
        // companyCode:''
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '提交时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align:'center',
        }, 
        { title: '结算通道', dataIndex: 'taxEnterpriseName', key: 'taxEnterpriseName', align: 'center', width: 150 },
        {
            title: '企业名称',
            dataIndex: 'companyCodeName',
            key: 'companyCodeName',
            width: 150,
            align:'center',
        },{
            title: '来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 150,
            align:'center',
            
        }, {
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
            
        }, 
        {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 150,
            align:'center',
            
        }, {
            title: '个人佣金（元）',
            dataIndex: 'sureCommissionAmount',
            key: 'sureCommissionAmount',
            width: 150,
            align:'center',
            
        },  {
            title: '平台服务费（元）',
            dataIndex: 'sureServiceAmount',
            key: 'sureServiceAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '平台税费（元）',
            dataIndex: 'sureTaxAmount',
            key: 'sureTaxAmount',
            width: 150,
            align:'center',
            
        },
        {
            title: '申请放款合计（元）',
            dataIndex: 'sureTotalAmount',
            key: 'sureTotalAmount',
            width: 180,
            align:'center',
        },
        {
            title: '状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            width: 150,
            
        },
        {
        title: '操作',
        key: 'action',
        width: 320,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                <a onClick={() =>  window.location.href = record.fileUrl }>下载源文件</a>
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {methodsFn.goDetail(record)}}>人员调整</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:confirm`)>-1&&<a onClick={() => {methodsFn.handlePay(record)}}>确认完成</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:offlineAbandon`)>-1&&<Popconfirm
                        title="确定放弃吗？"
                        onConfirm={() => {
                            methodsFn.offlineAbandon(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>放弃</a>
                    </Popconfirm>}
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="提交时间" name="date" style={{marginLeft:'40px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="结算通道" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                          {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                    <FormItem  label="来源" name="source" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            <Select.Option value={1}>佣金账单</Select.Option>
                            <Select.Option value={2}>审核账单</Select.Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
         // 获取所有结算通道
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        },
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                companyCode:formObj.companyCode,
                month:month,
                source:formObj.source,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/billOffline/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        offlineAbandon(obj){
             request.post('/platform/billOffline/offlineAbandon',{
                settlementNo:obj.settlementNo,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()

            })
              
        },
        handlePay(record){
            myModel1.current.showModal(record)
        },
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/offlineConfirm?settlementNo='+record.settlementNo)
            setParmObj({
                settlementNo:record.settlementNo
            })
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/offlineConfirm')
        },
        handleExport(){
            request.downExport('/platform/billOffline/export',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo:searchParam.settlementNo,
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                        scroll={{ x: 'max-content' }}
                        columns={columns} 
                        dataSource={tableData} 
                        rowKey={(record) => record.settlementNo}
                        pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 : <OfflineConfirmDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                <MyModel1 ref={myModel1} refresh={methodsFn.refresh} />
                 
            </div>
    )
}

export default OfflineConfirm
