import React,{useImperativeHandle,useState,forwardRef} from 'react'
import { 
  Modal,
  Input,
  Form,
  Row,
  Select,
  message,
  Col,
} from 'antd';
import {request} from '../../../../utils/fetch'

const { TextArea } = Input;
const FormItem = Form.Item;
const formItemLayout = {
 
  wrapperCol: {
    span: 16,
  },
};
const { Option } = Select;
const MyModel1 = (props,ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [detailObj, setDetailObj] = React.useState({});
  const [receiptList,setReceiptList] = useState([])     

  const topCol = {
    span:8,
  }
  const endCol = {
    span:8,
    offset:6
  }

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (obj) => {
        form.resetFields()
        setVisible(true)
        setDetailObj(obj)
        getReceiptList(obj.companyCode)
      }
  }));
  const getReceiptList = (companyCode) => {
    request.get('/platform/companyBase/receiptList',{companyCode:companyCode}).then(res => setReceiptList(res))
  }
  const handleOk = async() => {
    try {
      const values = await form.validateFields();
      let obj = form.getFieldsValue()
      
      let type = '',receiptNo = ''
      if(detailObj.ticketTypeName == '企业申请'){
        type = 1
        receiptNo = ''
      }else{
        type = 2
        receiptNo = obj.receiptNo
      }
      setLoading(true)
      request.post('/platform/companyTicketApply/confirm',{
        ticketApplyNo:detailObj.ticketApplyNo,
        ticketNote:obj.ticketNote,
        receiptNo:receiptNo,
        type:type,
        
      }).then(res => {
        setVisible(false);
        setLoading(false);
        message.success(res.msg)
        props.refresh()
      }).catch(err =>  setLoading(false))
      
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }
  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='确认开票'
        width='1000px'
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">企业名称:</span>
                <Input placeholder={detailObj.companyName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">纳税人类型:</span>
                <Input placeholder={detailObj.taxpayerTypeName} disabled={true} />
              </div>
            </Col>
        </Row>

        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">统一社会信用代码:</span>
                <Input placeholder={detailObj.taxpayerNumber} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">注册电话:</span>
                <Input placeholder={detailObj.registeredPhone} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">注册地址:</span>
                <Input placeholder={detailObj.address} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">对公账户:</span>
                <Input placeholder={detailObj.bankNumber} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">开户银行:</span>
                <Input placeholder={detailObj.bankName} disabled={true} />
              </div>
            </Col>
        </Row>
        {
        detailObj.ticketTypeName == '企业申请'? <div>
        <Row className="margin-15">
           <Col {...topCol}>
             <div className="flex">
               <span className="left-span">申请开票时间:</span>
               <Input placeholder={detailObj.createTime} disabled={true} />
             </div>
           </Col>
           <Col {...endCol}>
             <div className="flex">
               <span className="left-span">申请开票金额:</span>
               <Input placeholder={detailObj.ticketAmount} disabled={true} />
             </div>
           </Col>
       </Row>
       </div>
       :<div>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">开票金额:</span>
                <Input placeholder={detailObj.ticketAmount} disabled={true} />
              </div>
            </Col>
        </Row>
       </div>
        }
      
      
        <Form
         {...formItemLayout}
        form={form} >
        {
           detailObj.ticketTypeName == '阶梯退费'
           ?<FormItem
           rules={[{ required: true, message: '请选择' }]}
           label="收件信息"
           name="receiptNo">
             <Select placeholder="">
             {
                 receiptList.map(item => {
                     return <Option value={item.receiptNo} key={item.receiptNo}>{item.receiptName+'/'+item.receiptPhone+'/'+item.receiptAddress}</Option>
                 })
             }
             </Select>
         </FormItem>
         :null
        }
                <FormItem 
                    name="ticketNote" label="开票备注">
                    <TextArea  cols="600" showCount allowClear maxLength={100} />
                </FormItem>
        </Form>
      
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



