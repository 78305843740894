import { request } from '../../../../utils/fetch'

// 列表
export const paymentGetList = (params)　=> {
    return request.requestGet('/platform/payment/getList', params)
}

// 明细
export const getDetailList = (params)　=> {
    return request.requestGet('/platform/payment/getDetailList', params)
}

// 放弃支付
export const abandon = (data) => {
    return request.requestPost('/platform/payment/abandon', data)
}

// 获取验证码
export const sendCode = (params) => {
    return request.requestGet('/platform/payment/sendCode', params)
}

// 获取配置号码
export const getPhone = (params) => {
    return request.requestGet('/platform/payment/getPhone', params)
}

// 线上支付
export const payment = (data) => {
    return request.requestPost('/platform/payment/payment', data)
}

// 查询余额
export const getCount = (params) => {
    return request.requestGet('/platform/abcEnterpriseAccountConfig/getCount', params)
}
