import React from 'react';
import { Pie } from '@ant-design/charts';
import './mychart.css'
const ChartPie = (props) => {
  let data = props.data || [];
  if(data.length>0){
    data = data.map((item) => {
      return {
        name: item.name,
        value: Number(item.value),
      };
    });
  }
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'name',
    radius: 0.9,
    legend: false,
    label: false,
    tooltip: {
      customContent: (title, data) => {
        return (<div className='mycustomtooltip'>
          <div className='tooltitle' >{title}</div>
          <div>
            {data.map((item) => {
              return (
                <span>任务总金额: <span style={{marginLeft: '10px'}} >{item.value}</span>元</span>
              )
            })
          }
          </div>
        </div>)
      }
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie style={{height: '350px'}} {...config} />;
};

export default ChartPie;