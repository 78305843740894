import React, { useRef } from 'react'
import Search from './components/search'
import BaseTable from './components/baseTable'
function TrafficArchives(props){
    const BaseTableRef = useRef();
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "search":
                BaseTableRef.current.getlist(data);
                break;
            default: 
            break;
          }
    }
    return (
        <div>
            <Search refresh={refresh} type={1}></Search>
            <BaseTable ref={BaseTableRef} refresh={refresh}></BaseTable>
        </div>
    )
}
export default TrafficArchives;