import React, { useState, useEffect } from 'react'
import { Modal, Form, Upload, Select, Input, Button, message } from 'antd'

import { getBankList, openAccount } from '../../api'

const {Option} = Select
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
}
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const BaseModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [bankList, setBankList] = useState([])

  // 获取银行列表
  const getBankLists = async() => {
    let res = await getBankList()
    console.log(res)
    if (res.data.success) {
      setBankList(res.data.data)
    }
  }
  // 回显数据
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        companyName: modalData.companyName,
        taxpayerNumber: modalData.taxpayerNumber,
        bankNo: modalData.bankNo,
        bankNumber: modalData.bankNumber,
      })
      getBankLists()
    }
  }, [visible, form, modalData])
  // 表单初始数据
  const initialFormValues = {
    companyName: '',
    taxpayerNumber: '',
    bankNo: '',
    bankNumber: ''
  }

  const handleOk = async() => {
    const data = Object.assign({}, form.getFieldsValue(), {companyCode: modalData.companyCode})
    try {
      await form.validateFields()
      setConfirmLoading(true)
      const result = await openAccount(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('提交成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      setConfirmLoading(false)
      console.log('__重新开户', err)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false} 
      title="重新开户" 
      visible={visible} 
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk} 
      width={740}>
      <Form form={form} initialValues={initialFormValues}>
        <Form.Item {...formItemLayout} label="开户失败原因">
          <div>{modalData.msg}</div>
        </Form.Item>
        <Form.Item {...formItemLayout} name="companyName" label="企业名称">
          <Input placeholder="请输入" disabled />
        </Form.Item>
        <Form.Item {...formItemLayout} name="taxpayerNumber" rules={[{required: true}]} label="统一社会信用代码">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="bankNo" rules={[{required: true}]} label="开户行名称">
          {/* <Input placeholder="请输入" /> */}
          <Select placeholder="请选择">
            {
              bankList.map(item => (
                <Option value={item.bankNo} key={item.bankNo}>{item.bankName}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="bankNumber" rules={[{required: true}]} label="银行账号">
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BaseModal