import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Select, Button, DatePicker, Row, Col, Space } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
import ImportModal from '../../components/ImportModal'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum } = props
    let path = 'accidentArchives'
    const [form] = Form.useForm()
    const [importVisible, setImportVisible] = useState(false)
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }
    const itemStyle = { width: '390px', marginRight: '15px' }
    const initialFormValues = {}
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    // 查询
    const handelQuery = () => {
        let params = form.getFieldsValue()
        refresh('query', Object.assign({},params))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    const upFileProps = {
        url: '/hire/platform/ddc_settlement/import',
        type: 'DDC_JS',
        importVisible: importVisible,
        setImportVisible: setImportVisible,
        handelQuery: handelQuery
    }
    // 导出
    const exportData = () => {
        let data = form.getFieldsValue()
        let params ={
            status: data.status, 
            beginCollectDate: data.dsrq ? moment(data.dsrq[0]).format('YYYY-MM-DD') : '',
            endCollectDate: data.dsrq ? moment(data.dsrq[1]).format('YYYY-MM-DD') : '',
            beginPreSettlementDate: data.yjrq ? moment(data.yjrq[0]).format('YYYY-MM-DD') : '',
            endPreSettlementDate: data.yjrq ? moment(data.yjrq[1]).format('YYYY-MM-DD') : '',
            beginSettlementTime: data.sjjssj ? moment(data.sjjssj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endSettlementTime: data.sjjssj ? moment(data.sjjssj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        }
        request.downExport('/platform/ddc_settlement/export', params)
    }
    useEffect(() => {
        handelQuery()
    }, [pageNum])
    return (
        <>
        
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={itemStyle} name='dsrq' label='代收日期'>
                    <RangePicker />
                </Form.Item>
                <Form.Item style={itemStyle} name='yjrq' label='应结日期'>
                    <RangePicker />
                </Form.Item>
                <Form.Item style={itemStyle} name='sjjssj' label='实际结算时间'>
                    <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                </Form.Item>
                <Form.Item style={itemStyle} name='status' label='结算状态'>
                    <Select allowClear placeholder='全部'>
                        <Option value='待结算'>待结算</Option>
                        <Option value='结算中'>结算中</Option>
                        <Option value='结算成功'>结算成功</Option>
                        <Option value='结算失败'>结算失败</Option>
                    </Select>
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 && (
                            <Button type='primary' onClick={Query}>
                                查询
                            </Button>
                        )}
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                        <Button onClick={()=>{setImportVisible(true)}}>导入</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <ImportModal upFileProps={upFileProps} />
        </>
    )
}

export default QueryForm
