import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Input,
  Form,
  Row,
  message,
  Col,
  Radio,
  Button
} from 'antd';
import {request} from '../../../../../utils/fetch'
const formItemLayout = {
    labelCol: { flex: '100px' },
    wrapperCol: { flex: 1 },
}

const AuditModal = forwardRef((props,ref) => {
  const [visible, setVisible] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [recordData, setRecordData] = React.useState({});
  const [form] = Form.useForm()
  const initialFormValues = {
    rechargeNo: null,
    status: null,
  }
   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
        form.setFieldsValue({...record,status: null})
        setRecordData(record)
        setVisible(true);
      }
  }));

  const handleOk = () => {
    form.validateFields().then(async (values)=>{
      try {
        let data = {
          rechargeNo: values.rechargeNo,
          taxEnterpriseCode: recordData.taxEnterpriseCode,
          companyCode: recordData.companyCode,
          sureAmount: values.amount,
          status: values.status,
          remark: recordData.remark,
        }
        await request.post('/platform/companyAccount/rechargeAudit',data)
        setVisible(false);
        message.success('审核成功')
        props.refresh()
      } catch (error) {
        console.log(error)
      }
    })
  }

  return (
    <>
      <Modal maskClosable={false}
        title="审核"
        width='600px'
        visible={visible}
        footer={[
          <Button key="linkw" loading={btnLoading} type="primary" onClick={()=>{handleOk()}} >提交</Button>,
        ]}
        onCancel={() => {setVisible(false)}}
      >
        <Form  initialValues={initialFormValues} {...formItemLayout} form={form}>
          <Row className="margin-15">
            <Col className="gutter-row" span={24}>
              <Form.Item name="companyName" label="充值企业:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="createTime" label="申请时间:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="taxEnterpriseName" label="结算通道:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="rechargeNo" label="汇款识别码:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="amount" label="充值金额:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="status" rules={[{ required: true, message: '请选择审核结果' }]} label="审核结果:">
                <Radio.Group>
                  <Radio value={1}>确认成功</Radio>
                  <Radio value={2}>确认失败</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
export default AuditModal



