import React, { useRef } from 'react'
import { Spin } from 'antd'
import QueryForm from './components/QueryForm'
import TableList from './components/TableList'
import AddMoel from './components/AddMoel'
import UpLoadMoel from './components/UpLoadMoel'

function TaxSource() {
    const TableListRef = useRef()
    const AddMoelRef = useRef()
    const UpLoadMoelRef = useRef()
    const refresh = (val,record) => {
        switch (val) {
            case 'query':
                TableListRef.current.getlist()
                break
            case 'add':
                AddMoelRef.current.showModal('新增')
                break
            case 'upSeal':
                UpLoadMoelRef.current.showModal(record)
                break
            default:
        }
    }
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh}></QueryForm>
            <TableList refresh={refresh} ref={TableListRef}></TableList>
            <AddMoel refresh={refresh} ref={AddMoelRef}></AddMoel>
            <UpLoadMoel ref={UpLoadMoelRef} refresh={refresh}></UpLoadMoel>
        </Spin>
    )
}
export default TaxSource
