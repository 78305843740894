import React, { useState, useEffect } from 'react'
import {Form,Input,Select,Button,Row,Col,Table,PageHeader} from 'antd'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import './index.css'
const { Option } = Select
const itemStyle = { width: '364px', marginRight: '15px' }
function CardPushDetail(props){
    let path = 'bankPush'
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const state = props.location.state
    const FormItem = Form.Item
    const [tableData, setTableData] = useState([
        
    ])
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0
    })
    const initSearch = {
        keword: '',
    }
    const [form] = Form.useForm()
    useEffect(() => {
        getList()
    }, [])
    const columns = [
        { title: '姓名',dataIndex: 'name',width: 160,align: 'center'},
        { title: '身份证号',dataIndex: 'idNumber',width: 100,align: 'center' },
        { title: '银行名称',dataIndex: 'bankName',width: 100,align: 'center' },
        { title: '支行名称',dataIndex: 'branchBankName',width: 120,align: 'center'},
        { title: '银行卡号',dataIndex: 'bankCard',width: 100,align: 'center' },
        { title: '推送状态',dataIndex: 'pushStatusName',width: 100,align: 'center' },
        { title: '失败原因',dataIndex: 'reason',width: 100,align: 'center' },
    ]
    //查询
    const handleSearch =()=>{
        setPagination(prev => {
            return {
                ...prev,
                pageNum: 1
            }
        })
        getList()
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    const getList = ()=>{
        const formData = form.getFieldsValue()
        const params = {
            pushNo:state.pushNo,
            pageSize: pagination.pageSize, 
            pageNum: pagination.pageNum,
            ...formData
        }
        console.log(props.match.params.pushNo);
        request.requestGet('/platform/bankPush/getDetail', params).then((res)=>{
            console.log(res);
            if(res.data.success){
                setTableData(res.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: res.data.data.totalCount
                    }
                })
            }
        })
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getList({ pageSize: pageSize, pageNum: pageNum })
    }
    const exp = () =>{
        const formData = form.getFieldsValue()
        request.downExport('/platform/bankPush/export', { pushNo:state.pushNo, ...formData })
    }
    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => props.history.goBack()}
                title="查看"
                subTitle=""
            />
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label='推送状态' labelCol={{ style: { width: 100 } }} style={itemStyle} wrapperCol={{ flex: 300 }} name='pushStatus'>
                        <Select allowClear placeholder='全部' >
                            <Option value='1'>推送成功</Option>
                            <Option value='2'>推送失败 </Option>
                        </Select>
                    </FormItem>
                    <FormItem label='关键字' labelCol={{ style: { width: 100 } }} wrapperCol={{ flex: 300 }} name='keyword'>
                        <Input style={{ width: 350 }} placeholder='姓名丨身份证号' />
                    </FormItem>
                    <Col span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                            { (
                                <Button type='primary' onClick={handleSearch}>
                                    查询
                                </Button>
                            )}
                            <Button style={{ marginLeft: 10 }} onClick={resetForm}>
                                重置
                            </Button>
                            {permissions.includes(`${path}:export`)&&(
                                <Button style={{ marginLeft: 10 }} onClick={exp}>
                                    导出
                                </Button>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Table
                scroll={{ x: 'max-content' }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: pagination.total,
                    current: pagination.pageNum,
                    pageSize: pagination.pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
                }}
            />
        </div>
    )
}
export default CardPushDetail;