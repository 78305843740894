import React from 'react'
import { Form, Button, Row, Col, Space } from 'antd'
import store from '../../../../../store/index'
const QueryForm = (props) => {
    let path = 'platform:taxEnterprise'
    const { refresh } = props
    const [form] = Form.useForm()
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
    }

    const initialFormValues = {}

    // 新增
    const handAdd = () => {
        refresh('add')
    }
    
    return (
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:updateSealUrl`) > -1 && (
                            <Button type='primary' onClick={handAdd}>
                                新增
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}

export default QueryForm
