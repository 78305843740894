import React, { useState, useEffect } from 'react'
import { Row, Table, Form, Button, Space, PageHeader, Input, Spin, message } from 'antd'
import qs from 'qs'
import { request } from '../../../../utils/fetch'
import { getDetailList } from './api'
const FormItem = Form.Item
const labelStyleObj = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 15,
    },
}

function Detail(props) {
    const search = qs.parse(props.history.location.search.replace(/\?/g, ''))
    const path = 'platform:BillUpload'
    const [form] = Form.useForm()
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [loading, setLoading] = useState(false)
    const columns =[
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        },
        {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 150,
            align: 'center',
        },
        {
            title: '银行名称',
            dataIndex: 'bankName',
            width: 150,
            align: 'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'bankNumber',
            width: 150,
            align: 'center',
        },
        {
            title: '个人佣金(元)',
            dataIndex: 'commissionAmount',
            width: 150,
            align: 'center',
        },
        {
            title: '服务费(元)',
            dataIndex: 'serviceAmount',
            width: 150,
            align: 'center',
        },
        {
            title: '税费(元)',
            dataIndex: 'taxAmount',
            width: 150,
            align: 'center',
        },
    ]
    //导航和查询组件
    const renderSearchForm = () => {
        return (
            <Form form={form} {...labelStyleObj}>
                <Row>
                    <FormItem label='关键字' name='keyword' labelCol={{ span: 5 }} style={{ width: '430px' }}>
                        <Input placeholder='姓名丨身份证号' />
                    </FormItem>
                </Row>
                <Row justify='end'>
                    <Space>
                        <Button onClick={handleSearch} type='primary'>
                            查询
                        </Button>
                        <Button onClick={handleFormReset}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                    </Space>
                </Row>
            </Form>
        )
    }

    //重置表单
    const handleFormReset = () => form.resetFields()
    const handleSearch = () => {
        setPageNum(1)
        if(pageNum==1){
            getTableList()
        }
    }
    //导出
    const exportData = () => {
        let formData = form.getFieldsValue()
        const params = {
            keyword: formData.keyword,
            settlementNo: search.settlementNo,
        }

        let url = '/platform/payment/exportDetail'
        request.downExport(url, params)
    }

    const getTableList = async () => {
        const formData = form.getFieldsValue()
        let params = {
            pageSize: pageSize,
            pageNum: pageNum,
            keyword: formData.keyword,
            settlementNo: search.settlementNo,
        }
        setLoading(true)
        getDetailList(params).then(res=>{
            if(res.data.success){
                setTotal(res.data.data.totalCount)
                setTableData(res.data.data.list)
            }else{
                message.error(res.data.retMsg)
            }
            setLoading(false)
        }).catch(()=>{
            setLoading(false)
        })
    }

    useEffect(() => {
        getTableList()
    }, [pageNum])

    return (
        <Spin spinning={loading}>
            <PageHeader
                title="查看"
                onBack={() => props.history.goBack()}
            />
            {renderSearchForm()}
            <Table
                scroll={{ x: 'max-content' }}
                columns={columns}
                rowKey='settlementDetailNo'
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
        </Spin>
    )
}

export default Detail
