import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  DatePicker,
  Radio,
  Select,
  Row,
  Col,
  Table,
  Space,
  Spin,
  message,
  Popconfirm,
  Typography,
  Modal
} from 'antd'
import QRCodeModal from './components/QRCodeModal'
import OpenAccount from './components/OpenAccount'
import { getCompanyList, companyUpdateStatus, companyDelete, updateBindCode, getCompanyAccountLists, getCompanyAuthCompanyUrl, getCompanyBeforeAuthSign } from '../api'
import { getCityList, getCountyList, getTaxEnterpriseList } from '../../../../api/api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import moment from 'moment'

import './index.css'

const { RangePicker } = DatePicker
const { Option } = Select
const { Paragraph, Link } = Typography

const labelStyleObj = {
  wrapperCol: { span: 18 }
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const pageSize = 20

function CompanyLists(props) {
  let path = 'platform:CompanyBase'
  const [form] = Form.useForm()
  const [bindForm] = Form.useForm()
  const [radio, setRadio] = useState(10)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState()
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState({})
  const [openVisible, setOpenVisible] = useState(false)   // 重新开户弹窗状态
  const [bindVisible, setBindVisible] = useState(false)  // 绑定子户
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [companyAccountLists, setCompanyAccountLists] = useState([])
  const [companyCode, setCompanyCode] = useState()
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  const [configVoList, setConfigVoList] = useState([])
  const [previewTitle, setpreviewTitle] = useState('认证链接')
  const [textUrl, settextUrl] = useState('')
  const [previewVisible, setpreviewVisible] = useState(false)
  // form 表单域默认值
  const initialFormValues = {
    date: null,
    status: undefined,  // 状态
    keyword: '', // 关键字
  }

  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: pageSize,
    total: 0
  })
  useEffect(() => {
    getTaxEnterpriseListall()
    getList(pagination)
  }, [])
  // 
  async function getList(page) {
    const date = form.getFieldsValue().date
    const params = {
      beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
      endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
      keyword: form.getFieldsValue().keyword,
      status: form.getFieldsValue().status,
      projectType: form.getFieldsValue().projectType,
      projectCodeList: form.getFieldsValue().projectCodeList,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
      pageSize: page.pageSize,
      pageNum: page.pageNum
    }
    try {
      setLoading(true)
      const result = await getCompanyList(params)
      if (result.data.success) {
        setTableData(result.data.data.list || [])
        setPagination(prev => {
          return { ...prev, total: result.data.data.totalCount }
        })
      } else {
        message.error(result.data.retMsg)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('___企业列表', err)
    }
  }
  // 列表字段
  const tableColumns = [
    { title: '时间', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 200 },
    { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 200 },
    { title: '结算通道', dataIndex: 'taxEnterpriseNames', key: 'taxEnterpriseNames', align: 'center', width: 300,
      render: (taxEnterpriseNames) => {
        if(taxEnterpriseNames){
          return taxEnterpriseNames.replace(/,/g, "；")
        }else{
          return ''
        }
      } 
    },
    // { title: '落地城市区县', dataIndex: 'cityCountyNames', key: 'cityCountyNames', align: 'center', width: 280,
    // render: (cityCountyNames) => {
    //   if(cityCountyNames){
    //     return cityCountyNames.replace(/,/g, "；")
    //   }else{
    //     return ''
    //   }
    // } },
    { title: '企业ID', dataIndex: 'companyCode', key: 'companyCode', align: 'center', width: 160 },
    { title: '管理员用户', dataIndex: 'manageName', key: 'manageName', align: 'center', width: 120 },
    { title: '管理员账号', dataIndex: 'managePhone', key: 'managePhone', align: 'center', width: 120 },
    { title: '项目名称', dataIndex: 'projectName', key: 'projectName', align: 'center', width: 120 },
    { title: '入驻状态', dataIndex: 'statusName', key: 'statusName', align: 'center', width: 100 },
    {
      title: '认证状态',
      dataIndex: 'certificationFlag',
      key: 'certificationFlag',
      align: 'center',
      width: 100,
      render: (certificationFlag, record) => (
        <Space>
          <span>{certificationFlag == 1 ? '实名 ' : '未实名 '}</span>{' '}
        </Space>
      ),
    },
    {
      title: '授权状态',
      dataIndex: 'authorizationFlag',
      key: 'authorizationFlag',
      align: 'center',
      width: 100,
      render: (authorizationFlag, record) => (
        <Space>
          <span>{authorizationFlag == 1 ? '授权 ' : '未授权 '}</span>{' '}
        </Space>
      ),
    },
    {
      title: '操作',
      key: 'control',
      fixed: 'right',
      width: 310,
      align: 'center',
      render: (record) => {
        const control = []
        const title = record.status === 1 ? '是否确定要停用?' : '是否确定要启用?'
        // 待提交 修改 删除
        if (record.status === -1) {
          store.getState().userReducer.permissions.indexOf(`${path}:update`) > -1 && control.push(<a key="1" onClick={() => modify(record)}>修改</a>)
          store.getState().userReducer.permissions.indexOf(`${path}:remove`) > -1 && control.push(
            <Popconfirm
              title="是否确定要删除?"
              onConfirm={() => deleteRow(record)}
              key="2"
              okText="确定"
              cancelText="取消"
            >
              <a>删除</a>
            </Popconfirm>)
        } else {
          control.push(<a key="3" onClick={() => QRModalOpeen(record)}>二维码</a>)
          store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && control.push(<a key="4" onClick={() => modalShow(record)}>查看</a>)
          store.getState().userReducer.permissions.indexOf(`${path}:status`) > -1 && control.push(
            <Popconfirm
              title={title}
              onConfirm={() => changeStatus(record)}
              key="6"
              okText="确定"
              cancelText="取消"
            >
              <a key="5">{record.status === 1 ? '停用' : '启用'}</a>
            </Popconfirm>
          )
          store.getState().userReducer.permissions.indexOf(`${path}:updateBindCode`) > -1 && control.push(<a key="7" onClick={() => bindAccount(record)}>绑定子户</a>)
        }

        store.getState().userReducer.permissions.includes(`${path}:list`) && record.certificationFlag != 1 &&control.push(<Link onClick={() => handelView(record, '认证链接')}>认证链接</Link>)
        store.getState().userReducer.permissions.includes(`${path}:list`) && record.authorizationFlag != 1 &&control.push(<Link onClick={() => handelView(record, '授权链接')}>授权链接</Link>)
        
        const result = []
        control.forEach(item => {
          result.push(item)
        })

        return (
          <Space>{result}</Space>
        )
      }
    }
  ]
  const handelView = async (row, title) => {
    setpreviewTitle(title)
    let result
    if (title == '认证链接') {
        result = await getCompanyAuthCompanyUrl({ companyCode: row.companyCode })
    } else {
        result = await getCompanyBeforeAuthSign({ companyCode: row.companyCode })
    }
    if (result.data.success) {
        settextUrl(result.data.data)
        setpreviewVisible(true)
    } else {
        message.error(result.data.retMsg)
    }
}
  // 时间radio选择
  const radioChange = e => {
    let startDate, endDate
    setRadio(e.target.value)
    startDate = moment().subtract(e.target.value - 1, 'days')
    endDate = moment()
    form.setFieldsValue({ date: [startDate, endDate] })
  }
  const submit = () => {
    getList({ pageNum: 1, pageSize: pageSize })
    setPagination(prev => {
      return {
        ...prev,
        pageNum: 1
      }
    })
  }
  // 重置
  const resetForm = () => {
    form.resetFields()
    setRadio(1)
  }
  // 
  const rangeChange = (dates) => {
    console.log(dates)
    if (dates === null) {
      setRadio(10)
    }
  }
  // 刷新列表
  const refresh = () => {
    getList(pagination)
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
    getList({ pageNum: page, pageSize: pageSize })
  }
  // 二维码弹窗
  const QRModalOpeen = (record) => {
    setVisible(true)
    let list = record.planTypes.split(',')
    setConfigVoList(list)
    setDetail({
      companyName: record.companyName,
      unitPictureUrl: record.unitPictureUrl,
      taxPictureUrl: record.taxPictureUrl,
      entrustPictureUrl: record.entrustPictureUrl
    })
  }
  const closeModal = () => {
    setVisible(false)
  }
  // 关闭重新开户弹窗
  const openClose = () => {
    setOpenVisible(false)
  }
  // 查看
  const modalShow = (record) => {
    props.history.push({ pathname: `/admin/companyLists/${record.companyCode}` })
  }
  // 修改
  const modify = (record) => {
    props.history.push({ pathname: `/admin/companyUpdate/${record.companyCode}` })
  }
  // 重新绑定
  const bindAccount = (record) => {
    setCompanyCode(record.companyCode)
    setBindVisible(true)
  }
  const bindCancel = () => {
    setBindVisible(false)
    bindForm.resetFields()
  }
   // 获取所有结算通道
  const getTaxEnterpriseListall = async () => {
      const res = await getTaxEnterpriseList()
      if (res.data.success) {
        setTaxEnterpriseLis(res.data.data)
      }
  }
  const bindSubmit = async () => {
    try {
      setConfirmLoading(true)
      let res = await updateBindCode({ companyCode: companyCode, refAccountCompanyCode: bindForm.getFieldsValue().refAccountCompanyCode })
      if (res.data.success) {
        bindCancel()
        submit()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch (err) {
      console.log('__重新绑定', err)
    }
  }
  // 导出
  const exportData = () => {
    const date = form.getFieldsValue().date
    const data = {
      beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
      endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
      keyword: form.getFieldsValue().keyword,
      status: form.getFieldsValue().status,
      projectType: form.getFieldsValue().projectType,
      projectCodeList: form.getFieldsValue().projectCodeList,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode
    }
    request.downExport('/platform/companyBase/export', data)
  }
  // 启停用
  const changeStatus = async (record) => {
    const status = record.status === 1 ? 0 : 1
    try {
      const result = await companyUpdateStatus({ companyCode: record.companyCode, status: status })
      if (result.data.success) {
        message.success('成功')
        getList(pagination)
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      console.log(err)
    }
  }
  // 删除
  const deleteRow = async (record) => {
    try {
      const result = await companyDelete({ companyCode: record.companyCode })
      if (result.data.success) {
        message.success('删除成功！')
        getList({ pageNum: 1, pageSize: pageSize })
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      console.log(err)
    }
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
      return option
    }
  }

  return (
    <Spin spinning={loading}>
      <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
        <Row gutter={4}>
          <Col span={6}>
            <Form.Item name="date" label="时间">
              <RangePicker onChange={(dates) => rangeChange(dates)} allowClear={false} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Radio.Group onChange={radioChange} value={radio}>
                <Radio value={1}>今天</Radio>
                <Radio value={7}>近7天</Radio>
                <Radio value={30}>近30天</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item {...labelStyleObj} name="status" label="入驻状态">
              <Select allowClear placeholder="全部">
                <Option value="1">启用</Option>
                <Option value="0">停用</Option>
                <Option value="-1">草稿</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item {...labelStyleObj} name="projectCodeList" label="项目名称">
              <Select allowClear mode='multiple' placeholder="全部">
                <Option value='ELE'>饿了么</Option>
                <Option value='CN'>菜鸟</Option>
                <Option value='HM'>盒马</Option>
                <Option value='WL'>物流</Option>
                <Option value='DD'>达达</Option>
                <Option value='QT'>其他</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item {...labelStyleObj} name="taxEnterpriseCode" label="结算通道">
              <Select allowClear placeholder="全部">
                {TaxEnterpriseLis.map((e)=><Select.Option key={e.code} value={e.code}>{e.enterpriseName}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="keyword" label="关键字">
              <Input autoComplete="off" placeholder="企业名称丨管理员用户丨管理员账号丨企业ID" />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
            </Space>
          </Col>
        </Row>
      </Form>

      <div className="pg-table" style={{ marginTop: '20px' }}>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          rowKey={record => record.companyCode}
          scroll={{ x: 'max-content' }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 条数据`,
            onChange: (page, pageSize) => tablePageChange(page, pageSize)
          }}
        />
      </div>
      <Modal maskClosable={false}
        title="绑定子户"
        visible={bindVisible}
        onCancel={bindCancel}
        confirmLoading={confirmLoading}
        onOk={bindSubmit}
        width={740}>
        <Form {...formItemLayout} form={bindForm} confirmLoading={confirmLoading}>
          <Form.Item name="refAccountCompanyCode" label="银行子户">
            <Select>
              {
                companyAccountLists.map(item => (
                  <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>

      </Modal>
      <QRCodeModal
        visible={visible}
        close={closeModal}
        detail={detail}
        configVoList={configVoList}>
      </QRCodeModal>
      <OpenAccount
        visible={openVisible}
        close={openClose}
        refresh={refresh}
        modalData={detail}>
      </OpenAccount>
      <Modal maskClosable={false}
                width={800}
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => {
                    setpreviewVisible(false)
                }}
            >
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    copyable={{
                        text: textUrl,
                        tooltips: ['点击复制', '复制成功'],
                    }}
                >
                    {textUrl}
                </Paragraph>
            </Modal>
    </Spin>
  )
}

export default CompanyLists