import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  Popconfirm,
  Space,
  message,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function OfflineConfirmDetail(props) {
    let path = 'platform:BillOffline'
    const [form] = Form.useForm();
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        },{
            title: '状态',
            dataIndex: 'rejectFlag',
            key: 'rejectFlag',
            width: 150,
            align:'center',
            render: (text, record) => record.rejectFlag?'剔除':'未剔除'
            
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        }, {
            title: '收款卡号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align:'center',
            
        },  {
            title: '开户行',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align:'center',
            
        },  {
            title: '打款备注',
            dataIndex: 'content',
            key: 'content',
            width: 150,
            align:'center',
            
        },{
            title: '个人佣金（元）',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            key: 'serviceAmount',
            width: 150,
            align:'center',
            
        },  {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align:'center',
        },
        {
            title: '操作',
            key: 'action',
            width: 220,
            align:'center',
            fixed: 'right',
            render: (text, record) => (
                store.getState().userReducer.permissions.indexOf(`${path}:rejectOrRecovery`)>-1&&<Space size="middle">
                     <Popconfirm
                        title={`确定${record.rejectFlag == 1?'恢复':'剔除'}吗？`}
                        onConfirm={() => {
                            methodsFn.rejectOrRecovery(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>{record.rejectFlag == 1?'恢复':'剔除'}</a>
                    </Popconfirm>
                </Space>
            ),
            },
     
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                        <FormItem  label="状态" name="rejectFlag" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Select.Option value={0}>未剔除</Select.Option>
                            <Select.Option value={1}>已剔除</Select.Option>
                        </Select>
                    </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                   
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    keyword:form.getFieldsValue().keyword,
                    rejectFlag:form.getFieldsValue().rejectFlag,
                    settlementNo:parmObj.settlementNo,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/billOffline/getDetailList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        rejectOrRecovery(obj){
            let type;
            if(obj.rejectFlag == 1){
                type = 2
            }else{
                type = 1
            }
            request.post('/platform/billOffline/rejectOrRecovery',{
                type:type,
                settlementDetailNo:obj.settlementDetailNo,
                settlementNo:parmObj.settlementNo,
            }).then(res => {
                message.success('操作成功')
                methodsFn.handleSearch()

            })
        },
        handleExport(){
            request.downExport('/platform/billOffline/exportDetail',methodsFn.getsearchObj())
        }
   
    }

    useEffect(() => {
        methodsFn.getTableList({
            keyword:form.getFieldsValue().keyword,
            settlementNo:parmObj.settlementNo
        })
    
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="人员调整"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.idNumber}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(OfflineConfirmDetail)
