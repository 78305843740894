import React, { useState, useEffect } from 'react'
import {
    PageHeader,
    InputNumber,
    Button,
    Select,
    Row,
    Col,
    Space,
    Form,
    Table,
    Spin,
    DatePicker
} from 'antd'
import qs from 'qs'
import {getDealList} from '../api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import moment from 'moment'
const { Option } = Select;
const { RangePicker } = DatePicker;
const AccountInfoDetail = (props) => {
  let path = 'platform:Financialdeal'
  const params = qs.parse(props.history.location.search.replace(/\?/g,''));
  console.log(props)
  const taxEnterpriseCode = params.taxEnterpriseCode
  const companyCode = params.companyCode
  

  const [form] = Form.useForm()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 列表字段
  const tableColumns = [
    {
      title: '交易类型',
      dataIndex: 'dealTypeName',
      key: 'dealTypeName',
      align: 'center'
    },{
      title: '交易金额（元）',
      dataIndex: 'dealAmount',
      key: 'dealAmount',
      align: 'center'
    },{
      title: '交易时间',
      dataIndex: 'dealTime',
      key: 'dealTime',
      align: 'center'
    },
    {
      title: '交易前金额（元）',
      dataIndex: 'beforeBalance',
      key: 'beforeBalance',
      align: 'center'
    },
    {
      title: '交易后余额（元）',
      dataIndex: 'afterBalance',
      key: 'afterBalance',
      align: 'center'
    },
   
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center'
    },
  ]
  // 获取详情
  const getDetail = async(page) => {
    let values = form.getFieldsValue()
    let params = {
      taxEnterpriseCode: taxEnterpriseCode,
      companyCode: companyCode,
      statusOrTypeList: values.statusOrTypeList,
      pageNum: page.pageNum,
      pageSize: page.pageSize
    }
    if(values.date&&values.date.length>0){
      params.beginTime = moment(values.date[0]).format('YYYY-MM-DD')
      params.endTime = moment(values.date[1]).format('YYYY-MM-DD')
    }
    try {
      setLoading(true)
      const res = await getDealList(params)
      if (res.data.success) {
        setPagination(prev => {
          return {
            ...prev,
            total: res.data.data.totalCount
          }
        })
        setTableData(res.data.data.list)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDetail(pagination)
  }, [])
  const submit = () => {
    getDetail({pageNum: 1, pageSize: 20})
  }
  const resetForm = () => {

  }
  const exportData = () => {
    let values = form.getFieldsValue()
    let params = {
      taxEnterpriseCode: taxEnterpriseCode,
      companyCode: companyCode,
      statusOrTypeList: values.statusOrTypeList,
    }
    if(values.date&&values.date.length>0){
      params.beginTime = moment(values.date[0]).format('YYYY-MM-DD')
      params.endTime = moment(values.date[1]).format('YYYY-MM-DD')
    }
    let url = '/platform/companyAccount/exportDealList'
    request.downExport(url, params)
  }
  const tablePageChange = (pageNum, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: pageNum
      }
    })
    getDetail({pageNum: pageNum, pageSize: pageSize})
  }
  return (
    <Spin spinning={loading}>
      <PageHeader title="明细" onBack={() => props.history.goBack()} />
      <Form form={form}>
        <Row>
          <Form.Item labelCol={{ flex: '100px' }} name="statusOrTypeList" style={{width: '330px'}} label="交易类型">
            <Select placeholder="全部" allowClear >
              <Option value={'1'}>充值</Option>
              <Option value={'2'}>上账</Option>
              <Option value={'3'}>下账</Option>
              <Option value={'4'}>任务支付</Option>
              <Option value={'5'}>任务退回</Option>
            </Select>
          </Form.Item>
          <Form.Item labelCol={{ flex: '100px' }} name="date" style={{width: '330px'}} label="交易时间">
            <RangePicker />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              <Button onClick={exportData}>导出数据</Button>
            </Space>
          </Col>                              
        </Row>
      </Form>
      <Table
        columns={tableColumns} 
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
        rowKey={(record, index) => `rowkey${record.name}${index}`}
        pagination={{
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          showTotal:(total) =>  `共 ${total} 条数据`,
          onChange: (pageNum, pageSize)　=> tablePageChange(pageNum, pageSize)
        }}>

      </Table>
    </Spin>
  )
}

export default AccountInfoDetail