import React, { useState, useEffect } from 'react'
import {
  PageHeader,
  Table,
  Spin,
} from 'antd'
import qs from 'qs'
import {getServiceDealList} from '../api'
import { request } from '../../../../utils/fetch'
const AccountInfoServiceDetail = (props) => {
  let path = 'platform:Financialdeal'
  let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
  const taxEnterpriseCode = searchParam.taxEnterpriseCode

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  // 列表字段
  const tableColumns = [
    {
      title: '任务单号',
      dataIndex: 'dealNo',
      key: 'dealNo',
      align: 'center'
    },{
      title: '企业名称',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center'
    },{
      title: '交易时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },{
      title: '金额（元）',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
  ]
  // 获取详情
  const getDetail = async(page) => {
    let params = {
      taxEnterpriseCode: taxEnterpriseCode,
    }
    try {
      setLoading(true)
      const res = await getServiceDealList(params)
      if (res.data.success) {
        setTableData(res.data.data.list)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDetail()
  }, [])
  return (
    <Spin spinning={loading}>
      <PageHeader title="服务费明细" onBack={() => props.history.goBack()} />
      <Table
        columns={tableColumns} 
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
        rowKey={(record, index) => `rowkey${record.name}${index}`}
        pagination={false}>
      </Table>
    </Spin>
  )
}

export default AccountInfoServiceDetail