import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  DatePicker,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import CompanyInvoiceDetail from './CompanyInvoiceDetail'
 import MyModel1 from './MyModel1';
 import MyModel2 from './MyModel2';
 import store from '../../../../store/index'
import {request} from '../../../../utils/fetch'
import { getTaxEnterpriseList }　from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
    width:'300px',
    marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ flex: '70px'},
    wrapperCol:{ flex: '1'}
 }

function CompanyInvoice(props) {
    let path = 'platform:CompanyTicketApply'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const myModel2 = useRef();
    
    const initSearch = {
        date:[],
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])

    const columns = [
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
            align:'center',
        }, {
            title: '开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
        },{
            title: '开票状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 150,
            align:'center',
            
        }, 
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 200,
            align: 'center'
          },
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
            
        }, 
        {
        title: '操作',
        key: 'action',
        width: 220,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
            {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {methodsFn.handleLook(record,'查看')}}>查看</a>}
            {
                store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<Space size="middle">
                    <a onClick={() => {methodsFn.goDetail(record)}}>明细</a>
                </Space>
            }
            {
                store.getState().userReducer.permissions.indexOf(`${path}:confirm`)>-1&&record.status != 'KPCG'&&<a onClick={() => {methodsFn.handleLook(record,'确认开票')}}>确认开票</a>
            }
           
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="时间" {...labelStyleObj} name="date" >
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem  label="开票状态" name="status" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            <Option value={'YSQ'}>待处理</Option>
                            <Option value={'KPCG'}>开票成功</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="结算通道" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="收件人丨收件电话" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0px' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
         // 获取所有结算通道
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
            }
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                applyBeginDate:beginTime,
                applyEndDate:endTime,
                companyCode:formObj.companyCode,
                status:formObj.status,
                keyword:formObj.keyword,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/companyTicketApply/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleLook(record,name){
            request.get('/platform/companyTicketApply/getDetail',{
                ticketApplyNo:record.ticketApplyNo,
                companyCode:record.companyCode,
            }).then(res => {
                if(name == '确认开票'){
                    myModel1.current.showModal(res)
                }else if(name == '查看'){
                    myModel2.current.showModal(res)
                }
            })
        },
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/companyInvoice?companyCode='+record.companyCode+'&companyName='+record.companyName+'&ticketApplyNo='+record.ticketApplyNo)
            setParmObj({
                companyCode:record.companyCode,
                companyName:record.companyName,
                ticketApplyNo:record.ticketApplyNo
                
            })
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/companyInvoice')
        },
        handleExport(){
            request.downExport('/platform/companyTicketApply/export',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                companyCode:searchParam.companyCode,
                companyName:searchParam.companyName,
                ticketApplyNo:searchParam.ticketApplyNo
                
                
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content' }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.ticketApplyNo}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 : <CompanyInvoiceDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                <MyModel1 ref={myModel1} refresh={methodsFn.refresh} />
                <MyModel2 ref={myModel2} refresh={methodsFn.refresh} />
                 
            </div>
    )
}

export default CompanyInvoice
