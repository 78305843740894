import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Space,
  Button,
  Table,
  message,
  Divider
} from 'antd'
import { getBatchList } from '../../api'
import { request } from '../../../../../utils/fetch'
import moment from 'moment'
const { Option } = Select
const { RangePicker } = DatePicker
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
}
const topCol = {
    span: 12,
}
const BatchExportModal = (props,ref) => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [tableData, setTableData] = useState([])
    const [rows, setRows] = useState([])
    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const [totalCount, setTotalCount] = useState()
    const [timeType, setTimeType] = useState(2) //根据时间类型判断是否有时分秒
    const initialFormValues = {timeType:2,exportType:'1',timeDate: [moment(new Date()).subtract(1,'months'), moment()]}
    const tableColumns = [
        { title: '双方签署完成时间', dataIndex: 'signTime', key: 'signTime', align: 'center', width: 170 },
        { title: '姓名', dataIndex: 'name', key: 'name', align: 'center', width: 100 },
        { title: '身份证', dataIndex: 'idNumber', key: 'idNumber', align: 'center', width: 120 },
        { title: '落地方案', dataIndex: 'planTypeName', key: 'planTypeName', align: 'center', width: 100 },
        { title: '成立日期', dataIndex: 'establishDate', key: 'establishDate', align: 'center', width: 100 },
        { title: '存档状态', key: 'keepStatusName', align: 'center', width: 100, render: (record) => { 
            return record.planType==2?record.keepStatusName:''
          } },
    ]
    // 查询
    const handelQuery= async (type)=>{
        let data = form.getFieldsValue()
        if(type==1){
            data = Object.assign(data,{timeType:2, exportType:'1',timeDate: [moment(new Date()).subtract(1,'months'), moment()]})
        }
        console.log(data)
        const date = data.timeDate
        let params = {}
        Object.keys(data).forEach(key => {
            if(data[key]){
                if(key == 'timeDate'){
                    if(timeType == 1){
                        params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD') : ''
                        params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD') : ''
                    }else{
                        params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                        params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                    }
                }else{
                    params[key] = data[key]
                }
            }
        });
        let result = await getBatchList(params)
        if(result.data.success){
            setTableData(result.data.data.list)
            setTotalCount(result.data.data.totalCount)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 重置
    const resetForm= ()=>{
        form.resetFields()
    }
    // 导出
    const exportData= ()=>{//exportBatch
        if(rows.length<1){
            message.error('请选择要导出的数据')
            return
        }
        let data = form.getFieldsValue()
        const date = form.getFieldsValue().timeDate
        let params = {}
        let keepNoList = rows.map(e=>{
            return e.keepNo
        })
        Object.keys(data).forEach(key => {
            if(data[key]){
                if(key == 'timeDate'){
                    if(timeType == 1){
                        params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD') : ''
                        params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD') : ''
                    }else{
                        params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                        params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                    }
                }else{
                    params[key] = data[key]
                }
            }
        });
        params.keepNoList = keepNoList.length == totalCount? [] : keepNoList.join(',')
        request.downExport('/platform/staffKeep/exportBatch',params)
    }
     // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: () => {
        setVisible(true)
        form.resetFields()
        setRows([])
        setselectedRowKeys([])
        handelQuery(1)
    }
  }));
  useEffect(() => {
   
  })
  return ( 
    <>
      <Modal maskClosable={false} title="批量导出"  visible={visible} onCancel={() => { setVisible(false) }} footer={null} width={900}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
            <Row>
            <Form.Item style={{ width: '160px', marginLeft: '20px', display: 'inline-block' }} name="timeType" wrapperCol={24}>
                <Select onSelect={(value) => { setTimeType(value); form.setFieldsValue({ timeDate: null }) }}>
                    <Option value={2}>双方签署完成时间</Option>
                    <Option value={1}>成立日期</Option>
                </Select>
            </Form.Item>
            <Form.Item style={{ display: 'inline-block' }} name="timeDate" wrapperCol={24}>
                <RangePicker style={{ width: '340px' }} showTime={timeType == 2} format={timeType == 1 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
            <Col {...topCol}>
                <Form.Item name="planType" label="落地方案">
                    <Select allowClear>
                        <Option value="1">临时税务登记</Option>
                        <Option value="2">个体工商户</Option>
                        <Option value="3">委托代征</Option>
                    </Select>
                </Form.Item>
            </Col>
            
            <Col {...topCol}>
                <Form.Item name="keepStatus" label="存档状态">
                    <Select allowClear>
                        <Option value="WSC">未上传</Option>
                        <Option value="YSC">已上传</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col {...topCol}>
                <Form.Item name="exportType" label="导出类型">
                    <Select>
                        <Option value="1">身份证</Option>
                        <Option value="2">营业执照</Option>
                        <Option value="3">身份证+营业执照</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col {...topCol}>
                <Form.Item label="关键字" name="keyword">
                    <Input placeholder="姓名|身份证号"></Input>
                </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', }}>
                <div>已选{rows.length}条</div>
                <div style={{ textAlign: 'right',flex: 1 }}>
                    <Space>
                        <Button type="primary" onClick={handelQuery}>查询</Button>
                        <Button onClick={resetForm}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                    </Space>
                </div>
            </Col>
            </Row>
        </Form>
        
        <Table
            columns={tableColumns}
            dataSource={tableData}
            scroll={{ x: 'max-content' }}
            rowKey={record => record.keepNo}
            rowSelection={{selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
                setRows(selectedRows)
                setselectedRowKeys(selectedRowKeys)
              }}}
            pagination={false}
        />
        {totalCount>0&&<Divider style={{ margin: '0', }}>共{totalCount}条数据</Divider>}
      </Modal>
    </>
  )
}

export default forwardRef(BatchExportModal)