import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Modal, Form, Upload, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { updateSealUrl } from '../../api'

const action = '/hire/common/uploadFile'
const UpLoadMoel = (props,ref) => {
    const { refresh } = props
    const [visible, setVisible] = useState(false)
    const [code, setCode] = useState() //结算通道code
    const [sealFileUrl, setSealFileUrl] = useState()
    const [previewData, setPreviewData] = useState({
        previewVisible: false,
        previewImage: null,
        fileList: '',
        previewTitle: '',
    })

    const [form] = Form.useForm()
    const uploadObj = {
        token: localStorage.getItem('token'),
        mobile: localStorage.getItem('mobile'),
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    )

    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setCode(record.code)
            setVisible(true)
            form.resetFields()
            if (record.sealUrl) {
                form.setFieldsValue({
                    seal: [
                        {
                            uid: '-1',
                            name: '',
                            url: record.sealUrl,
                            status: 'done',
                        },
                    ],
                })
                setPreviewData({
                    previewVisible: false,
                    previewImage: '',
                    fileList: [
                        {
                            uid: '-1',
                            name: '',
                            url: record.sealUrl,
                            status: 'done',
                        },
                    ],
                    previewTitle: '',
                })
                setSealFileUrl(record.sealUrl)
            }else{
                setPreviewData({
                    previewVisible: false,
                    previewImage: '',
                    fileList: [ ],
                    previewTitle: '',
                })
            }
        },
    }))

    const normFile = (e) => {
        console.log('Upload event:', e)
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // 图片预览
    const handlePreview = async (file) => {
        console.log(file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        setPreviewData((prev) => {
            return {
                ...prev,
                previewImage: file.url || file.preview,
                previewVisible: true,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            }
        })
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }
    // 上传图片
    const handleChange = ({ fileList }) => {
        let sealFileUrl = ''

        fileList.forEach((item) => {
            if (item.status === 'done') {
                if (item.response.retCode === '000302') {
                    localStorage.clear()
                    window.location = '/login'
                } else {
                    sealFileUrl = item.response.data[0].url
                }
            }
        })
        setSealFileUrl(sealFileUrl)
        setPreviewData((prev) => {
            return {
                ...prev,
                fileList: fileList,
            }
        })
    }
    const handleCancel = () => {
        setPreviewData((prev) => {
            return {
                ...prev,
                previewVisible: false,
            }
        })
    }
    const onOk = () => {
        if (sealFileUrl) {
            updateSealUrl({
                code: code,
                sealUrl: sealFileUrl,
            })
                .then((res) => {
                    console.log(res.data.retMsg)
                    if (res.data.success) {
                        message.success('上传成功！')
                        setVisible(false)
                        refresh('query')
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch(() => {})
        } else {
            message.error('请上传印章后提交')
        }
    }

    return (
        <>
            <Modal maskClosable={false}
                title='上传'
                width='600px'
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={onOk}
            >
                <Form form={form}>
                    <Form.Item name='seal' labelCol={{ span: 4 }} valuePropName='fileList' getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label='印章' rules={[{required: true,message:'请上传印章'}]}>
                        <Upload accept='.png' action={action} listType='picture-card' data={uploadObj} name='files' fileList={previewData.fileList} onPreview={handlePreview} onChange={handleChange}>
                            {previewData.fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal maskClosable={false} width={1000} visible={previewData.previewVisible} title={previewData.previewTitle} footer={null} onCancel={handleCancel}>
                <img alt='example' style={{ width: '100%' }} src={previewData.previewImage} />
            </Modal>
        </>
    )
}
export default forwardRef(UpLoadMoel)
