import React, { useState, useEffect } from 'react'
import { Modal, Form, Upload, Select, Input, Button, message, Radio } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'

import { updateBase } from '../../api'

const { Option } = Select
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
}
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const BaseModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [licenseFileUrl, setLicenseFileUrl] = useState()
  const [fileUrl, setFileUrl] = useState([])

  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  // 回显数据
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        companyName: modalData.companyName,
        miniShowName: modalData.miniShowName,
        industryType: modalData.industryType,
        taxpayerType: modalData.taxpayerType,
        taxpayerNumber: modalData.taxpayerNumber,
        address: modalData.address,
        registeredPhone: modalData.registeredPhone,
        bankName: modalData.bankName,
        checkMode: modalData.checkMode,
        bankNumber: modalData.bankNumber,
        bankAccountName: modalData.bankAccountName,
        legalPersonName: modalData.legalPersonName,
        contactEmail: modalData.contactEmail,
        refAccountCompanyCode: modalData.refAccountCompanyCode,
        projectCode: modalData.projectCode
      })
      setLicenseFileUrl(modalData.licenseFileUrl)
      if (modalData.licenseFileUrl) {
        setPreviewData(prev => {
          return {
            ...prev,
            fileList: [{ uid: 'uid2', url: modalData.licenseFileUrl }]
          }
        })
      }
      if (modalData.fileUrlStr) {
        let list = modalData.fileUrlStr.split(',')
        setFileUrl(list)
        let urls = list.map((item,i)=>{
          return {uid: `uid${i}`, name: item, url: item}
        })
        setPreviewData1(prev=>{
          return {
            fileList: urls
          }
        })
      }
    }
  }, [visible, form, modalData])
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList: [],
    previewTitle: ''
  })
  const [previewData1, setPreviewData1] = useState({
    fileList: '',
  })
  // 表单初始数据
  const initialFormValues = {
    companyName: '',
    miniShowName: '',
    industryType: '',
    taxpayerType: '',
    taxpayerNumber: '',
    address: '',
    registeredPhone: '',
    bankName: '',
    bankNumber: '',
    bankAccountName: '',
    legalPersonName: '',
    contactEmail: '',
    checkMode: '',
    projectCode:'',
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }

    })
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  // 上传图片，把图片地址存入 licenseFileUrl
  const handleChange = ({ file, fileList }) => {
    console.log(file, fileList)
    let licenseFileUrl = ''
    fileList.forEach(item => {
      if (item.status === 'done') {
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          licenseFileUrl = item.response.data[0].url
        }
      }
    })
    setLicenseFileUrl(licenseFileUrl)
    setPreviewData(prev => {
      return {
        ...prev,
        fileList: fileList
      }
    })

  }
  // 上传文件，把图片地址存入 fileUrlStr
  const handleChange1 = ({ file, fileList }) => {
    console.log(file, fileList)
    let fileUrlStr = []

    fileList.forEach(item => {
      if (item.status === 'done') {
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          fileUrlStr.push(item.response.data[0].url)
        }
      }
    })
    setFileUrl(fileUrlStr)
    setPreviewData1(prev => {
      return {
        ...prev,
        fileList: fileList
      }
    })

  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const handleOk = async () => {
    let fileUrlStr = fileUrl.join(',')
    const data = Object.assign({}, form.getFieldsValue(), { fileUrlStr: fileUrlStr, licenseFileUrl: licenseFileUrl, companyCode: modalData.companyCode,auditMode: modalData.auditMode })
    try {
      await form.validateFields()
      setConfirmLoading(true)
      const result = await updateBase(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('修改成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      setConfirmLoading(false)
      console.log('__修改企业基本信息', err)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false}
      title="基础信息"
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      width={740}>
      <Form form={form} initialValues={initialFormValues}>
        <Form.Item {...formItemLayout} name="companyName" disabled label="企业名称">
          <Input disabled placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="miniShowName" rules={[{ required: true }]} label="小程序显示名称">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="projectCode" rules={[{ required: true }]} label="项目名称">
          <Select placeholder="请选择">
            <Option value='ELE'>饿了么</Option>
            <Option value='CN'>菜鸟</Option>
            <Option value='HM'>盒马</Option>
            <Option value='WL'>物流</Option>
            <Option value='DD'>达达</Option>
            <Option value='QT'>其他</Option>
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="industryType" rules={[{ required: true }]} label="行业分类">
          <Select placeholder="请选择">
            <Option value={1}>交通运输服务业</Option>
            <Option value={2}>信息技术服务业</Option>
            <Option value={3}>物流辅助服务业</Option>
            <Option value={4}>广播影视服务业</Option>
            <Option value={5}>供应链服务业</Option>
            <Option value={6}>居民生活日常服务业</Option>
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="taxpayerType" rules={[{ required: true }]} hasFeedback label="纳税人类型">
          <Select placeholder="请选择">
            <Option value={1}>一般纳税人</Option>
            <Option value={2}>小规模纳税人</Option>
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="taxpayerNumber" rules={[{ required: true }]} label="统一社会信用代码">
          <Input disabled placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="address" rules={[{ required: true }]} label="单位地址">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="registeredPhone" rules={[{ required: true }]} label="注册电话">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="bankName" label="开户行名称">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="bankNumber" label="银行账号">
          <Input disabled placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="bankAccountName" label="账号名称">
          <Input disabled placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="legalPersonName" rules={[{ required: true }]} label="法人姓名">
          <Input placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item {...formItemLayout} name="checkMode" rules={[{required: true}]} label="浦发银行审核模式">
          <Select>
            <Option value="0">不启用（信任模式）</Option>
            <Option value="1">企业网银审核</Option>
            <Option value="2">开放银行审核</Option>
            <Option value="3">短信验证审核</Option>
            <Option value="4">文件对比审核</Option>
          </Select>
        </Form.Item> */}
        <Form.Item {...formItemLayout} name="contactEmail" rules={[
          {
            type: 'email',
            message: '邮箱格式不正确!',
          }, {
            required: true
          }]} label="联系邮箱">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item {...formItemLayout} rules={[{ required: true }]} label="营业执照">
          <Upload
            action="/hire/common/uploadFile"
            listType="picture-card"
            name="files"
            data={uploadObj}
            fileList={previewData.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {previewData.fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal maskClosable={false}
            visible={previewData.previewVisible}
            title={previewData.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
          </Modal>
        </Form.Item>
        <Form.Item {...formItemLayout} rules={[{ required: true }]} label="附件">
          <Upload
            action="/hire/common/uploadFile"
            data={uploadObj}
            name="files"
            fileList={previewData1.fileList}
            onChange={handleChange1}
          >
            <Button icon={<UploadOutlined />}>上传</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BaseModal