/*个人管理接口*/

import { request } from '../../../utils/fetch'

// 个人列表
export const getPersonalLists = (params) => {
    return request.requestGet('/platform/staffBase/list', params)
}
// 个人列表启停用
export const changePersonalStatus = (data) => {
    return request.requestPost('/platform/staffBase/updateStatus', data)
}
// 个人列表查看二类户
export const getElectronicCard = (params) => {
    return request.requestGet('/platform/staffBase/electronicCard', params)
}
// 个人列表修改基础信息
export const changeUpdateBase = (params) => {
    return request.requestPost('/platform/staffBase/updateBase', params)
}
// 平台签署
export const getSign = (data) => {
    return request.requestPost('/platform/staffBase/platformSign', data)
}
// 获取企业落地配置
export const getServiceConfig = (params) => {
    return request.get('/platform/companyBase/serviceConfig', params)
}

// 证件留档列表
export const getDocumentLists = (params) => {
    return request.requestGet('/platform/staffKeep/list', params)
}

// 证件审核注销
export const staffKeep = (data) => {
    return request.requestPost('/platform/staffKeep/audit', data)
}
// 确认办理 批量办理
export const staffKeepBatch = (data) => {
    return request.requestPost('/platform/staffKeep/batchTransact', data)
}
// 批量导入办理
export const staffBatchByFile = (data) => {
    return request.requestPost('/platform/staffKeep/batchTransactByFile', data)
}
// 确认注销 批量注销
export const staffKeepCancel = (data) => {
    return request.requestPost('/platform/staffKeep/batchCancel', data)
}
// 上传证件
export const uploadTransact = (data) => {
    return request.requestPost('/platform/staffKeep/uploadTransactPicture', data)
}
// 修改
export const updateStaffKeep = (data) => {
    return request.requestPost('/platform/staffKeep/update', data)
}

// 批量短信查询
export const getSMSList = (params) => {
    return request.requestGet('/platform/staffKeep/getSMSList', params)
}
// 批量导出查询
export const getBatchList = (params) => {
    return request.requestGet('/platform/staffKeep/getBatchList', params)
}
// 批量发送短信
export const batchSMS = (params) => {
    return request.requestPost('/platform/staffKeep/batchSMS', params)
}
// 合同换签新增
export const changeSignInsert = (params) => {
    return request.requestPost('/platform/changeSign/insert', params)
}
// 合同换签停用
export const changeSignChangeStatus = (params) => {
    return request.requestPost('/platform/changeSign/changeStatus', params)
}
// 获取手写签名
export const getBase64 = (params) => {
    return request.requestGet('/platform/staffKeep/getBase64', params)
}
