import Decimal from "decimal.js"

export const setToken = function(token) {
   localStorage.setItem('token',token)
}
export const setMobile = function(mobile) {
    localStorage.setItem('mobile',mobile)
 }
export const setUsername = function(username) {
    localStorage.setItem('username',username)
}
export const setMenu = function(list) {
    localStorage.setItem('menu',list)
}
export const setPermissions = function(list) {
    localStorage.setItem('permissions',list)
}
export const getToken = function() {
    localStorage.getItem('token')
}
export const clearToken = function() {
    localStorage.removeItem('token')
}
export const isLogin = function() {
    if(localStorage.getItem('token')) return true
    return false
}

export function setLocalStorage(name, data) {
   const dataStr = JSON.stringify(data)
   return localStorage.setItem(name, dataStr)
}

export function getLocalStorage(name) {
   return localStorage.getItem(name)
}

// 不传参，删除所有
export function removeLocalStorage(name) {
   name === 'undefined'
      ? localStorage.clear()
      : localStorage.removeItem(name)
}

//计算时间差
export function subtractTime(startDate,endDate) {
    let time = endDate-startDate
    console.log(time);
    let days = 1000*60*60*24
    let hours = 1000*60*60
    let minutes = 1000*60
    let day =0
    let hour =0
    let minute =0
    let second = 0
    let timeStr=''
    if(time/days>1){
      day = parseInt(time/days)
      timeStr = day+'天'
    }
    if(time/hours>1){
      hour = parseInt((time-(day*days))/hours)
      timeStr = timeStr+hour+'时'
    }
    if(time/minutes>1){
      minute = parseInt((time-(day*days)-(hour*hours))/minutes)
      timeStr = timeStr+minute+'分'
    }
    if(time/1000>1){
      second = parseInt((time-(day*days)-(hour*hours)-(minute*minutes))/1000)
      timeStr = timeStr+second+'秒'
    }
    console.log(timeStr);
    return timeStr
  }

  export function decimalAdd(list) {
    // list 每一项相加
    let sum = list.reduce((prev, next) => {
      let n = new Decimal(prev).plus(next).toFixed()
      return n
    },0)
    return Number(sum)
  }