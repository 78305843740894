import { request } from '../../../../utils/fetch'

// 列表
export const getBackList = (params) => {
    return request.requestGet('/platform/payment/getBackList', params)
}

// 退回
export const dealBack = (data) => {
    return request.requestPost('/platform/payment/dealBack', data)
}

// 结算单下拉列表
export const backSettlementList = (params) => {
    return request.requestGet('/platform/payment/backSettlementList', params)
}
