import React, { useState, useEffect } from 'react';
import { request } from '../../../../../utils/fetch'
import axios from 'axios'
import {Form,Input,Modal,Select,Button,DatePicker,Radio,Row,Col,Space,message,Upload} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import store from '../../../../../store/index'
import moment from 'moment'
const FormItem = Form.Item
const { Option } = Select
const { RangePicker } = DatePicker
const Search = (props)=> {
    let path = 'bankPush'
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const { refresh } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]) //文件列表
    const [month,setMonth] = useState()
    const [importForm] = Form.useForm()
    const [form] = Form.useForm()
    const initSearch = {
    }
    const [radio, setRadio] = useState(10)
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }
    // 搜索表单通用样式
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }

    useEffect(() => {
    }, [])
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    //查询
    const handleSearch =()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            statusList: formData.statusList,
        }
        refresh('search',params)
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    const showModal = () => {
        setIsModalOpen(true);
        setMonth('')
        console.log(month);
    };
    //关闭新增弹窗
    const onImportCancel = () =>{
        setIsModalOpen(false)
        importForm.resetFields()
    }
   
    // 上传文件
    const onHandleUp = async () =>{
        let values = await importForm.validateFields()
        let file = values.ffile.file
        let data = {
            file: file
        }
        setLoading(true)
        request.requestFormDataPost('/platform/bankPush/import', data).then(({ data: { retMsg, success } }) => {
            setLoading(false)
            if (success) {
                onImportCancel()
                message.success(retMsg)
                handleSearch()
            } else {
                if (retMsg.includes('#')) {
                    retMsg = retMsg.split('#')
                    retMsg = retMsg.map(item => <p>{item}</p>)
                }
                message.error(retMsg)
            }
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
        })
    }
    return (
        <>
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <Form.Item name="date" style={itemStyle} {...labelStyleObj} wrapperCol={{ span: 24 }} label="创建时间">
                            <RangePicker
                                allowClear={true}
                                onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                                format="YYYY-MM-DD" />
                    </Form.Item>
                    <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                        <Radio value={1}>今天</Radio>
                        <Radio value={7}>近7天</Radio>
                        <Radio value={30}>近30天</Radio>
                    </Radio.Group>
                </Row>
                <Row>
                    <FormItem label='状态' name='statusList' style={{...itemStyle, marginBottom: 0}} {...labelStyleObj}>
                        <Select allowClear placeholder='全部' mode='multiple'>
                            <Option value='1'>待推送</Option>
                            <Option value='2'>部分成功 </Option>
                            <Option value='3'>推送成功</Option>
                            <Option value='4'>推送失败</Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                    <Col span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: 0 }}>
                            { 
                                <Button type='primary' onClick={handleSearch}>
                                    查询
                                </Button>
                            }
                            <Button style={{ marginLeft: 10 }} onClick={resetForm}>
                                重置
                            </Button>
                            {permissions.includes(`${path}:import`)&& (
                                <Button style={{ marginLeft: 10 }} onClick={showModal}>
                                    新增
                                </Button>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Modal maskClosable={false} title='新增' confirmLoading={loading} visible={isModalOpen} onOk={onHandleUp} onCancel={onImportCancel}>
                <div style={{marginLeft:10}}>
                    银行卡信息导入后推送给无忧云控平台
                </div>
                <Form form={importForm}>
                    <FormItem label='导入文件' name='ffile' rules={[{ required: true, message: '请上传文件' }]}>
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                            <Button
                                type='link'
                                onClick={e => {
                                    e.stopPropagation()
                                    window.location.href = `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=YHK_TS`
                                }}
                            >
                                下载模板
                            </Button>
                        </Upload>
                    </FormItem>
                </Form>
            </Modal>
        </>
    )
}

export default Search
