import { request } from '../../../utils/fetch'

// 车行信息-列表
export const getCarDealerInfoList = params => {
    return request.requestGet('/platform/ddc_dealerInfo/getList', params)
}
// 车行信息-新增
export const carDealerInfoInsert = data => {
    return request.requestPost('/platform/ddc_dealerInfo/insert', data)
}
// 车行信息-修改
export const carDealerInfoUpdate = data => {
    return request.requestPost('/platform/ddc_dealerInfo/update', data)
}
// 车行信息-启停用
export const carDealerInfoEnable = data => {
    return request.requestPost('/platform/ddc_dealerInfo/enable', data)
}
// 商户信息-列表
export const getMerchantInfoList = params => {
    return request.requestGet('/platform/ddc_businessInfo/getList', params)
}
// 商户信息-明细详情
export const getMerchantInfoDetail = params => {
    return request.requestGet('/platform/ddc_businessInfo/getDetailList', params)
}
// 商户信息-启停用
export const merchantInfoEnable = data => {
    return request.requestPost('/platform/ddc_businessInfo/enable', data)
}
// 电动车-订单列表
export const ddcOrderInfoGetList = params => {
    return request.requestGet('/platform/ddc_orderInfo/getList', params)
}
// 电动车-账单列表
export const ddcBillGetList = params => {
    return request.requestGet('/platform/ddc_bill/getList', params)
}
// 电动车-结算列表
export const ddcSettlementGetList = params => {
    return request.requestGet('/platform/ddc_settlement/getList', params)
}
// 电动车-资产列表
export const ddcAssetGetList = params => {
    return request.requestGet('/platform/ddc_asset/getList', params)
}
// 服务费明细-列表
export const getServiceChargeList = data => {
    return request.requestGet('/platform/ddc_serviceFee/getList', data)
}
// 服务费明细-明细列表
export const getServiceChargeDetailList = data => {
    return request.requestPost('/platform/ddc_serviceFee/getDetailList', data)
}
// 服务费明细-删除
export const serviceChargeDelete = data => {
    return request.requestPost('/platform/ddc_serviceFee/delete', data)
}
// 用户信息-新增
export const usersInfoInsert = data => {
    return request.requestPost('/platform/ddc_userInfo/insert', data)
}
// 用户信息-修改
export const usersInfoUpdate = data => {
    return request.requestPost('/platform/ddc_userInfo/update', data)
}
// 用户信息-列表
export const getUsersInfoList = data => {
    return request.requestGet('/platform/ddc_userInfo/getList', data)
}
// 用户信息-停用
export const usersInfoDisabled = data => {
    return request.requestPost('/platform/ddc_userInfo/disabled', data)
}
// 用户信息-删除
export const usersInfoDelete = data => {
    return request.requestPost('/platform/ddc_userInfo/delete', data)
}

