
import Login from './pages/Login';
import NotFind from './pages/NotFind';

import Home from './pages/admin/home/Home';
import DownPage from './pages/admin/downPage/index';
import ManualCheck from './pages/admin/commissionManage/ManualCheck/ManualCheck';
import commissionUpload from './pages/admin/commissionManage/CommissionUpload/CommissionUpload';  //佣金上传
import CommissionUploadDetail from './pages/admin/commissionManage/CommissionUpload/Detail/Detail';//佣金上传-明细 
import CommissionUploadDetailPreview from './pages/admin/commissionManage/CommissionUpload/Detail/Preview/Preview';//佣金上传-明细-预览 
import CommissionUploadDetailStatement from './pages/admin/commissionManage/CommissionUpload/Detail/Statement/Statement';//佣金上传-明细-查看结算单 
import CommissionUploadDetailCheck from './pages/admin/commissionManage/CommissionUpload/Detail/Check/Check';//佣金上传-明细-审核查询 
import CompanyLists from './pages/admin/companyManage/companyLists'
import CheckDetail from './pages/admin/companyManage/companyLists/CheckDetail'
import ProtocolTemplate from './pages/admin/companyManage/protocolTemplate'
import OfflineConfirm from './pages/admin/commissionManage/OfflineConfirm/OfflineConfirm';
import CityCounty from './pages/admin/systemSet/CityCounty/CityCounty';
import AccountManage from './pages/admin/systemSet/AccountManage/AccountManage';
import ResourceManage from './pages/admin/systemSet/ResourceManage/ResourceManage';
import RolePermission from './pages/admin/systemSet/RolePermission/RolePermission';
import ColonyRole from './pages/admin/systemSet/ColonyRole/ColonyRole';
import ColonyAccount from './pages/admin/systemSet/ColonyAccount/ColonyAccount';

import CompanyPermission from './pages/admin/systemSet/CompanyPermission/CompanyPermission'; 
import CompanyAccount from './pages/admin/systemSet/CompanyAccount/CompanyAccount';
import CompanyRole from './pages/admin/systemSet/CompanyRole/CompanyRole';

import CompanyEnter from './pages/admin/companyManage/companyEnter'
import ContractLists from './pages/admin/companyManage/contractLists'
import ElectronicContract from './pages/admin/personalManage/ElectronicContract/ElectronicContract'
import ContractTemp from './pages/admin/personalManage/ContractTemp/ContractTemp'
import PersonalLists from './pages/admin/personalManage/personalLists'
import DocumentSave from './pages/admin/personalManage/documentSave'
import ChangeContract from './pages/admin/personalManage/changeContract'
import ChangeContractDetail from './pages/admin/personalManage/changeContract/Detail'
import HistoryConfig from './pages/admin/companyManage/companyLists/HistoryConfig'
import PlatformRecord from './pages/admin/financeManage/platformRecord'
import PlatformDetail from './pages/admin/financeManage/platformRecord/detail'
import CompanyPipeline from './pages/admin/financeManage/companyPipeline'
import SecondStatement from './pages/admin/financeManage/secondStatement'
import PlatformCheck from './pages/admin/financeManage/platformCheck'
import BankSlip from './pages/admin/financeManage/bankSlip'
import TaxSource from './pages/admin/financeManage/taxSource'
import AccountInfo from './pages/admin/financeManage/accountInfo'
import AccountInfoDetail from './pages/admin/financeManage/accountInfo/detail'
import AccountInfoServiceDetail from './pages/admin/financeManage/accountInfo/serviceDetail'
import RechargeAudit from './pages/admin/financeManage/rechargeAudit'
import Withdrawal from './pages/admin/financeManage/withdrawal'


import CompanyPipelineDetail from './pages/admin/financeManage/companyPipeline/detail'
import CompanyInvoice from './pages/admin/invoiceManage/companyInvoice'
import CompanyInvoiceLeft from './pages/admin/invoiceManage/companyInvoiceLeft'
import SettleQuery from './pages/admin/commissionManage/SettleQuery/SettleQuery';
import CompanyUpdate from './pages/admin/companyManage/companyLists/updateCompany'
import BankSon from './pages/admin/companyManage/bankSon'
import WithdrawRecord from './pages/admin/financeManage/platformRecord/withdrawRecord'



import OrderInvestigate from './pages/admin/InvestigatePage/OrderInvestigate'
import PublisherInvestigate from './pages/admin/InvestigatePage/PublisherInvestigate'
import ServiceInvestigate from './pages/admin/InvestigatePage/ServiceInvestigate'
import TaskDescription from './pages/admin/InvestigatePage/TaskDescription'
import ddShowFiles from './pages/admin/ddShowFiles'

import billDetail from './pages/admin/electricCarModel/billDetail'
import settlementRecord from './pages/admin/electricCarModel/settlementRecord'
import ServiceChargeDetail from './pages/admin/electricCarModel/serviceChargeDetail'

import selfEmployed from './pages/admin/personalManage/selfEmployed/index'//九江个体户
import bankCardPush from './pages/admin/personalManage/bankCardPush/index'//银行卡推送
import cardPushDetail from './pages/admin/personalManage/bankCardPush/cardPushDetail'//银行卡推送


import CnlinePayment from './pages/admin/commissionManage/CnlinePayment/index';
import CnlinePaymentDetail from './pages/admin/commissionManage/CnlinePayment/Detail';

import PaymentRefund from './pages/admin/commissionManage/PaymentRefund/index';

//不需要权限
export const mainRouter = [
    { path: "/login", name: "Login", component: Login },
    { path: "/404", name: "NotFind", component: NotFind },   
    { path: '/ddShowFiles', name: '附件列表', component: ddShowFiles}, 
    { path: '/order_info', name: '订单反查', component: OrderInvestigate}, 
    { path: '/publish_info', name: '发布方反查', component: PublisherInvestigate}, 
    { path: '/service_info', name: '服务方反查', component: ServiceInvestigate}, 
    { path: '/TaskDescription', name: '服务方反查', component: TaskDescription}, 
]

//需要登录
export const adminRouter = [
    { path: '/admin/companyLists', name: '企业列表', component: CompanyLists},
    { path: '/admin/companyLists/:id', name: '企业列表详情', component: CheckDetail},
    { path: '/admin/protocolTemplate', name: '企业协议模板', component: ProtocolTemplate},
    { path: '/admin/companyEnter', name: '企业入驻', component: CompanyEnter},
    { path: '/admin/companyUpdate/:companyCode', name: '企业信息修改', component: CompanyUpdate},
    { path: '/admin/contractLists', name: '发包信息', component: ContractLists},
    { path: '/admin/personalLists', name: '个人列表', component: PersonalLists},
    { path: '/admin/documentSave', name: '证件留档', component: DocumentSave},
    { path: '/admin/historyConfig/:id', name: '历史配置', component: HistoryConfig},
    { path: '/admin/accountInfo', name: '账户余额', component: AccountInfo},
    { path: '/admin/accountInfo/detail', name: '账户余额-明细', component: AccountInfoDetail},
    { path: '/admin/accountInfo/serviceDetail', name: '账户余额-明细', component: AccountInfoServiceDetail},
    { path: '/admin/rechargeAudit', name: '充值审核', component: RechargeAudit},
    { path: '/admin/withdrawal', name: '提现记录', component: Withdrawal},
    { path: '/admin/platformRecord', name: '平台入账', component: PlatformRecord},
    { path: '/admin/platformRecord/detail/:id', name: '平台入账明细', component: PlatformDetail},
    { path: '/admin/companyPipeline', name: '企业流水', component: CompanyPipeline},
    { path: '/admin/companyPipeline/detail/:id', name: '企业流水明细', component: CompanyPipelineDetail},
    { path: '/admin/companyInvoice', name: '企业开票', component: CompanyInvoice },
    { path: '/admin/companyTicketLeft', name: '企业余票', component: CompanyInvoiceLeft },
    { path: '/admin/bankSon', name: '银行子户', component: BankSon},
    { path: '/admin/withdrawRecord', name: '提现记录', component: WithdrawRecord},
    { path: "/admin/home", name: "首页", component: Home },
    { path: "/admin/downPage", name: "下载列表", component: DownPage },
    { path: "/admin/commissionUpload", name: "佣金上传", component: commissionUpload },
    { path: "/admin/commissionUpload/detail", name: "佣金上传-详情", component: CommissionUploadDetail },
    { path: "/admin/commissionUpload/detail/preview", name: "佣金上传-详情-预览", component: CommissionUploadDetailPreview },
    { path: "/admin/commissionUpload/detail/statement", name: "佣金上传-详情-查看结算单", component: CommissionUploadDetailStatement },
    { path: "/admin/commissionUpload/detail/check", name: "佣金上传-详情-审核查询", component: CommissionUploadDetailCheck },
    { path: "/admin/manualCheck", name: "人工审核", component: ManualCheck },
    { path: '/admin/offlineConfirm', name: '线下确认', component: OfflineConfirm},
    { path: '/admin/settleQuery', name: '结算查询', component: SettleQuery},
    { path: '/admin/cityCounty', name: '落地区县', component: CityCounty},
    { path: '/admin/accountManage', name: '账号管理', component: AccountManage},
    { path: '/admin/resourceManage', name: '资源管理', component: ResourceManage},
    { path: '/admin/rolePermission', name: '角色权限', component: RolePermission},
    { path: '/admin/companyPermission', name: '企业账号权限', component: CompanyPermission},
    { path: '/admin/companyPermission/detail1', name: '账号权限', component: CompanyAccount},
    { path: '/admin/companyPermission/detail2', name: '角色权限', component: CompanyRole},
    { path: '/admin/electronicContract', name: '电子合同', component: ElectronicContract},
    { path: '/admin/contractTemp', name: '合同模板', component: ContractTemp},
    { path: '/admin/changeContract', name: '合同换签', component: ChangeContract},
    { path: '/admin/changeContract/detail', name: '合同换签-详情', component: ChangeContractDetail},
    { path: '/admin/secondStatement', name: '二类户流水', component: SecondStatement},
    { path: '/admin/platformCheck', name: '平台对账', component: PlatformCheck},
    { path: '/admin/bankSlip', name: '银行回单', component: BankSlip},
    { path: '/admin/colonyRole', name: '集群角色', component: ColonyRole},
    { path: '/admin/colonyAccount', name: '集群账号', component: ColonyAccount},
    { path: '/admin/TaxSource', name: '结算通道', component: TaxSource},
    { path: '/admin/billDetail', name: '账单明细', component: billDetail},
    { path: '/admin/settlementRecord', name: '结算记录', component: settlementRecord},
    { path: '/admin/serviceChargeDetail', name: '服务费明细', component: ServiceChargeDetail},
    { path: '/admin/selfEmployed', name: '九江个体户', component: selfEmployed},
    { path: '/admin/bankCardPush', name: '银行卡推送', component: bankCardPush},
    { path: '/admin/bankCardPush/cardPushDetail', name: '银行卡推送', component: cardPushDetail},
    { path: '/admin/CnlinePayment', name: '线上支付', component: CnlinePayment},
    { path: '/admin/CnlinePayment/Detail', name: '线上支付', component: CnlinePaymentDetail},
    { path: '/admin/PaymentRefund', name: '支付退回', component: PaymentRefund},
]

