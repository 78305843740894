import React, { useState, useEffect } from 'react'
import {
    Row,
    Table,
    Select,
    Form,
    Button,
    message,
    DatePicker,
    Upload,
    Popconfirm,
    Modal,
    Space,
    Typography
} from 'antd';

import axios from 'axios'
import { UploadOutlined, } from '@ant-design/icons';
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList } from '../../../../api/api'
const { Link } = Typography;
const FormItem = Form.Item;
const { Option } = Select;

const itemStyle = {
    width: '360px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

function CommissionUpload(props) {
    let path = 'platform:BillUpload'
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    // 初始化搜索数据
    const initSearch = {
        month: null,
        type: ''
    }
    // 初始化搜索数据
    const initUpLoad = {
        type: '2'
    }

    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const [tableData, setTableData] = useState([])
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [errData, setErrData] = useState({ list: [], totalCount: 0 })
    const fileProps = {
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false;
        },

        onChange(info) {
            let fileCon = [...info.fileList];
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1);
            setFileList(fileCon)
        },
    };
    const columns = [
        {
            title: '上传时间',
            dataIndex: 'createTime',
            width: 150,
            align: 'center',
        },
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 150,
            align:'center',
        },
        {
            title: '操作人',
            dataIndex: 'createUser',
            width: 150,
            align: 'center',
        }, {
            title: '账单月份',
            dataIndex: 'month',
            width: 150,
            align: 'center',
        }, {
            title: '发放方式',
            dataIndex: 'lineTypeName',
            width: 150,
            align: 'center',
        }, {
            title: '来源',
            dataIndex: 'sourceName',
            width: 150,
            align: 'center',
        }, {
            title: '数据处理状态',
            dataIndex: 'taskStatusName',
            width: 150,
            align: 'center',
        },
        {
            title: '操作',
            width: 260,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {
                        store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <Link onClick={() => methodsFn.goDown(record)}>
                            下载源文件
                        </Link>
                    }
                    {
                        parseInt(record.taskStatus) === 1 && store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <Link onClick={() => methodsFn.goDetail(record)}>明细分表</Link>
                    }
                    {
                        parseInt(record.taskStatus) === 2 &&
                        <>
                            {
                                store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                                <Link onClick={() => methodsFn.handleErrInfor(record)}>查看错误信息</Link>
                            }
                            {
                                store.getState().userReducer.permissions.indexOf(`${path}:remove`) > -1 &&
                                <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => {
                                    methodsFn.handleDel(record)
                                }}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Link>删除</Link>
                            </Popconfirm>
                            }
                        </>
                    }
                </Space>
            ),
        },
    ];

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <Row>
                        <FormItem label="数据处理状态" name="taskStatus" style={itemStyle} >
                            <Select placeholder="全部" allowClear>
                                <Option value='0'>拆分处理中</Option>
                                <Option value='1'>拆分完成</Option>
                                <Option value='2'>拆分失败，有错误数据</Option>
                            </Select>
                        </FormItem>
                        <Form.Item name='taxEnterpriseCode' style={itemStyle} label="结算通道">
                            <Select placeholder="全部" allowClear>
                                {TaxEnterpriseLis.map((e)=><Select.Option key={e.code} value={e.code}>{e.enterpriseName}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item style={itemStyle} name="keepStatus" label="存档状态">
                            <Select allowClear>
                                <Option value="WSC">未上传</Option>
                                <Option value="YSC">已上传</Option>
                            </Select>
                        </Form.Item> */}
                        <FormItem label="账单月份" name="month" style={itemStyle} >
                            <DatePicker picker="month" />
                        </FormItem>
                        <FormItem label="发放方式" name="lineType" style={itemStyle} >
                            <Select placeholder="全部" allowClear>
                                <Option value='1'>线上发放</Option>
                                <Option value='2'>线下发放</Option>
                            </Select>
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space>
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button onClick={methodsFn.handleFormReset}>重置</Button>
                        </Space>
                    </Row>
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if(pageNum==1){
                methodsFn.getTableList()
            }
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList() {
            let formData = form.getFieldsValue()
            let result = await request.get('/platform/billUpload/getRecordList', {
                pageSize: pageSize,
                pageNum: pageNum,
                month: formData.month && formData.month.format('YYYY-MM'),
                taskStatus: formData.taskStatus,
                lineType: formData.lineType,
                taxEnterpriseCode: formData.taxEnterpriseCode,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        async handleErrInfor(record) {
            setVisible1(true)
            let result = await request.get('/platform/billUpload/getErrorInfo', {
                importCode: record.importCode,
            })
            setErrData(result)
        },
        handleDel(record) {
            request.post('/platform/billUpload/deleteRecord', {
                importCode: record.importCode,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()
            })
        },
        async handleUp() {
            let values = await form2.validateFields()
            let data = new FormData(); //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)
            data.append('source', values.source)
            data.append('month', values.month.format('YYYY-MM'))
            data.append('type', values.type)

            setLoading(true)

            axios({
                method: 'post',
                url: `/hire/platform/billUpload/uploadBillFile`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                setLoading(false)
                if (res.data.success) {
                    setVisible2(false)
                    message.success(res.data.retMsg)
                    methodsFn.handleSearch()
                } else {
                    message.error(res.data.retMsg)
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
        },
        goDetail(record) {
            props.history.push(`/admin/commissionUpload/detail?importCode=${record.importCode}&lineType=${record.lineType}&taxEnterpriseCode=${record.taxEnterpriseCode}`)
        },
        
        goDown(record) {
            let downloadUrl = window.location.origin + record.fileUrl, fileName = record.fileName
            let loadUrl = `/hire/common/pageUrlDownload?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&fileName=${fileName}&urlStr=${downloadUrl}`
            window.location.href = loadUrl
        },
         // 获取所有结算通道
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        }
    }

    useEffect(() => {
        methodsFn.getTableList()
        methodsFn.getTaxEnterpriseListall()
    }, [pageNum])

    return (
        <div>
            <div>
                <div style={{ display: 'flex', background: '#fff', marginBottom: '20px', padding: '30px' }}>
                    <div>
                        <div>上传表格请使用固定的 表格模板</div>
                        <div>同一身份证号在1次批量导入中不能重复</div>
                    </div>
                    {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 &&
                        <Button
                            type="primary"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                                setFileList([])
                                form2.resetFields()
                                setVisible2(true)
                            }}>
                            上传文件
                        </Button>
                    }

                </div>
                {myComponent.renderSearchForm()}
                <Table
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={tableData}
                    pagination={{
                        position: ['bottomCenter'],
                        total: total,
                        current: pageNum,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        showTotal: (total) => `共 ${total} 条数据`,
                        onChange: (pageNum, pageSize) => setPageNum(pageNum)
                    }}
                />
            </div>

            <Modal maskClosable={false}
                forceRender
                title='错误信息'
                visible={visible1}
                footer={false}
                onCancel={() => { setVisible1(false) }} >
                <p>累计已超过{errData.totalCount}行错误，请修改后重新上传</p>
                {
                    errData.list.map(item => <p key={item.id}>第{item.rowNumber}行 {item.name} {item.idNumber} ：{item.message}</p>)
                }
            </Modal>
            <Modal maskClosable={false}
                title='上传文件'
                confirmLoading={loading}
                visible={visible2}
                onOk={methodsFn.handleUp}
                onCancel={() => { setVisible2(false) }}>
                <Form
                    form={form2}
                    initialValues={initUpLoad}
                    {...formItemLayout}>
                    <Form.Item
                        rules={[{ required: true, message: '请选择账单月份' }]}
                        label="账单月份"
                        name="month">
                        <DatePicker style={{ width: '100%' }} picker="month" />
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: '请选择来源' }]}
                        label="来源"
                        name="source">
                        <Select placeholder="请选择来源">
                            <Option value="1">蜂鸟</Option>
                            <Option value="2">万之象</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: '发放方式' }]}
                        label="发放方式"
                        name="type">
                        <Select disabled placeholder="请选择发放方式">
                            {/* <Option value="1">线上发放</Option> */}
                            <Option value="2">线下发放</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="发放文件"
                        name="ffile"
                        rules={[{ required: true, message: '请上传文件' }]}>
                        <Upload {...fileProps} >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button type='link' onClick={(e) => { e.stopPropagation(); window.location.href = `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=PT_YJDR` }} >
                                下载模板
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CommissionUpload
