import { SET_USER, SET_MENUpower } from "../Action";



const initState = {
    userName:localStorage.getItem('username') || 'xxx',
    permissions: localStorage.getItem('permissions')|| []
}

const userReducer = (state = initState,action) => {
    switch(action.type){
        case SET_USER:
            return{
                ...state,
                userName:action.preload

            }
        case SET_MENUpower:
            return{
                ...state,
                permissions: action.preload
            }
        default:
            return state

    }

}

export default userReducer