import React, { useState, useEffect } from 'react';
import { request } from '../../../../../utils/fetch'
import {Form,Input,Modal,Select,Button,DatePicker,Radio,Row,Col,Space,message,Upload,Spin} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import store from '../../../../../store/index'
import '../index.css'
import axios from 'axios'
import moment from 'moment'
const FormItem = Form.Item
const { OptGroup,Option } = Select
const { RangePicker } = DatePicker
const Search = (props)=> {
    let path = 'trafficEventArchives'
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const { refresh } = props
    const [personList,setPersonList] =useState([])
    const [idNumberd, setIdNumberd] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [IdCardData,setIdCardData] = useState({})
    const [IdCardData2,setIdCardData2] = useState({})
    const [IdCardData3,setIdCardData3] = useState({})
    const [fileList1, setFileList] = useState([]) //文件列表
    const [importForm] = Form.useForm()
    const [form] = Form.useForm()
    const initSearch = {
    }
    const topCol = {
        span: 12,
    }
    const topCol2 = {
        span: 24,
    }
    const labCol = {
        span:6,
      }
    const wrapCol = {
        span:14,
    }
    const labCol2 = {
        span:3,
    }
    const wrapCol2 = {
        span:19,
    }
    const [radio, setRadio] = useState(10)
    const [previewData, setPreviewData] = useState({
        previewVisible: false,
        previewImage: null,
        fileList1: '',
        fileList2: '',
        fileList3: '',
        previewTitle: '',
      })
    const uploadObj = {
        token: localStorage.getItem('token'),
        mobile: localStorage.getItem('mobile')
    }
    const action = "/hire/common/uploadFile"
    // 图片预览
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
        }
        setPreviewData(prev => {
        return {
            ...prev,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        }
        })
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      }
    const handleCancel = () => {
        setPreviewData(prev => {
            return {
                ...prev,
                previewVisible: false
            }
        })
    }
    const changeFile = ({ file, fileList }, type) => {
        fileList = fileList.slice(-1)
        const file1 = {...fileList[0]}
        fileList = fileList.map(item => {
            if (item.response&&item.response.success) {
              item.url = item.response.data[0].url;
            }
            return item;
        });
        setPreviewData(prev => {
            const data = {...prev}
            data[type] = fileList
            return data
        })
        console.log(file1,file,"filelist");
        let importFormData = importForm.getFieldValue()
        if(!(file.response&&file.response.success))return
        if(type=="fileList3"){
            const query = {
                url: fileList[0].response.data[0].url,
                zmSerialNo:IdCardData.serialNo
            }
            setLoading(true)
            request.requestPost('/platform/firmApply/face',query).then((res)=>{
                console.log(res);
                if (res.data.success) {
                    setIdCardData3(res.data.data)
                }else{
                    message.error(res.data.retMsg)
                }
                setLoading(false)
            })
        }else{
            setLoading(true)
            const query = {
                url: file.response.data[0].url,
                type:type=="fileList1"?0:1,
                name:importFormData.name,
                idNumber:importFormData.idNumber
            }
            request.requestPost('/platform/firmApply/IdCard',query).then((res)=>{
                console.log(res);
                if (res.data.success) {
                    type=="fileList1"?setIdCardData(res.data.data):setIdCardData2(res.data.data)
                }else{
                    message.error(res.data.retMsg)
                }
                setLoading(false)
            })

        }
    }
    const beforeUpload1 = (file)=>{
        console.log(file);
        const flag = file.size / 600 / 1024 <= 1;
        if (!flag) {
            message.error('文件大小不能超过600kb');
        }
        return flag;
    }
    const beforeUpload2 = (file)=>{
        const flag = file.size / 1024 / 1024 <= 3;
        if (!flag) {
            message.error('文件大小不能超过3M');
        }
        return flag;
    }
    // 搜索表单通用样式
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }
    useEffect(() => {
    }, [])
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    //查询
    const handleSearch =()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            statusList:formData.statusList,
        }
        refresh('search',params)
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    // 获取人员
    const getPersonList =  () => {
        request.requestGet('/platform/firmApply/getPersonList').then((res)=>{
            if (res.data.success) {
                setPersonList(res.data.data||[])
            }
        })
    }
    // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }
    //选择人员后
    const personChange = (value)=>{
        setIdNumberd(value)
        let row = personList.filter(item=>{
            return item.idNumber == value
        })
        console.log(row)
        importForm.setFieldsValue({
            name:row[0].name,
            eleCourierId: row[0].staffId,
            phone: row[0].phone,
            keepNo:row[0].keepNo
        })
        console.log(importForm.getFieldValue());
    }
    const showModal = () => {
        setIsModalOpen(true);
        setFileList([])
        getPersonList()
    };
    //关闭新增弹窗
    const onImportCancel = () =>{
        setIsModalOpen(false)
        importForm.resetFields()
    }
    const submit = ()=>{
        let importFormData = importForm.getFieldValue()
        console.log(importFormData);
        const params = {
            ...importFormData
        }
        params.zmSerialNo=IdCardData.serialNo
        params.smSerialNo = IdCardData.smSerialNo
        params.fmSerialNo = IdCardData2.serialNo
        params.jcSerialNo= IdCardData3.jcSerialNo
        params.zmPictureUrl = params.zmPictureUrl.fileList[0].response.data[0].url
        params.fmPictureUrl = params.fmPictureUrl.fileList[0].response.data[0].url
        params.jcVideoUrl = params.jcVideoUrl.fileList[0].response.data[0].url
        setLoading(true)
        request.requestPost('/platform/firmApply/save',params).then((res)=>{
            console.log(res);
            if (res.data.success) {
                message.success(res.data.retMsg)
                setIsModalOpen(false)
            }else{
                message.error(res.data.retMsg)
            }
            setLoading(false)
        })
    }
    return (
        <>
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <Form.Item style={itemStyle} {...labelStyleObj} name="date" label="申请时间">
                            <RangePicker
                                allowClear={true}
                                onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                                format="YYYY-MM-DD" />
                    </Form.Item>
                    <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                        <Radio value={1}>今天</Radio>
                        <Radio value={7}>近7天</Radio>
                        <Radio value={30}>近30天</Radio>
                    </Radio.Group>
                </Row>
                <Row>
                    <FormItem label='状态' name='statusList' style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder='全部' mode='multiple'>
                            <Option value='0'>待审核</Option>
                            <Option value='3'>已初审</Option>
                            <Option value='4'>已录入</Option>
                            <Option value='1'>已审核</Option>
                            <Option value='2'>已否决</Option>
                            <Option value='-1'>申请中</Option>
                            <Option value='-2'>申请失败</Option>
                        </Select>
                    </FormItem>
                    <FormItem label='关键字' style={itemStyle} {...labelStyleObj} name='keyword'>
                        <Input placeholder='姓名丨身份证号' />
                    </FormItem>
                </Row>
                <Row>
                    <Col span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                            {permissions.includes(`${path}:select`) && (
                                <Button type='primary' onClick={handleSearch}>
                                    查询
                                </Button>
                            )}
                            <Button style={{ marginLeft: 10 }} onClick={resetForm}>
                                重置
                            </Button>
                            {/* {permissions.includes(`${path}:select`)&& (
                                <Button style={{ marginLeft: 10 }} onClick={showModal}>
                                    新增
                                </Button>
                            )} */}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            
            <Modal maskClosable={false} width='788px' title='新增' confirmLoading={loading} visible={isModalOpen} 
            onOk={submit} onCancel={onImportCancel}>
                <Spin spinning={loading} tip="解析中">
                <Form form={importForm}>
                    <Row className="selfFrom">
                        <Col {...topCol2}>
                            <Form.Item name="idNumber" labelCol={labCol2} wrapperCol={{ span: 7 }} label="姓名">
                                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement}
                                    onChange={(value) => personChange(value)} >
                                    (personList?{personList.map(item => (
                                        <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                                    ))}:[])
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...topCol}>
                            <Form.Item name="phone" labelCol={labCol} wrapperCol={wrapCol} label="手机号">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...topCol}>
                            <Form.Item name="idNumber" labelCol={labCol} wrapperCol={wrapCol} label="身份证号">
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col {...topCol2}>
                            <FormItem label='身份证正面' name='zmPictureUrl' labelCol={labCol2} wrapperCol={wrapCol2} rules={[{ required: true, message: '请上传文件' }]}>
                                <Upload accept='.jpg,.jpeg,.png'
                                disabled={!importForm.getFieldValue("idNumber")}
                                action={action}
                                data={uploadObj}
                                multiple
                                maxCount={1}
                                name="files"
                                fileList={previewData.fileList1}
                                onPreview={handlePreview}
                                onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList1')}
                                beforeUpload={(file)=>beforeUpload1(file)}>
                                    <Button style={{width:"210px"}} type="primary" disabled={!importForm.getFieldValue("idNumber")} icon={<UploadOutlined />}>请上传身份证正面</Button>
                                    <div style={{color:'#f57070',height:"32px",lineHeight:"32px",marginLeft:'10px'}}>
                                        (请上传600kb以内身份证照片)
                                    </div>
                                </Upload>
                            </FormItem>
                        </Col>
                        <Col {...topCol2}>
                            <FormItem label='身份证反面' name='fmPictureUrl' labelCol={labCol2} wrapperCol={wrapCol2} rules={[{ required: true, message: '请上传文件' }]}>
                                <Upload accept='.jpg,.jpeg,.png'
                                disabled={!importForm.getFieldValue("idNumber")}
                                action={action}
                                data={uploadObj}
                                multiple
                                maxCount={1}
                                name="files"
                                fileList={previewData.fileList2}
                                onPreview={handlePreview}
                                onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList2')}
                                beforeUpload={(file)=>beforeUpload1(file)}>
                                    <Button style={{width:"210px"}} type="primary" disabled={!importForm.getFieldValue("idNumber")} icon={<UploadOutlined />}>请上传身份证反面</Button>
                                    <div style={{color:'#f57070',height:"32px",lineHeight:"32px",marginLeft:'10px'}}>
                                        (请上传600kb以内身份证照片)
                                    </div>
                                </Upload>
                            </FormItem>
                        </Col>
                        <Col {...topCol2}>
                            <FormItem label='导入文件' name='jcVideoUrl' labelCol={labCol2} wrapperCol={wrapCol2} rules={[{ required: true, message: '请上传文件' }]}>
                                <Upload accept='video/*'
                                disabled={!importForm.getFieldValue("zmPictureUrl")}
                                action={action}
                                data={uploadObj}
                                multiple
                                maxCount={1}
                                name="files"
                                fileList={previewData.fileList3}
                                onPreview={handlePreview}
                                onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList3')}
                                beforeUpload={(file)=>beforeUpload2(file)}>
                                    <Button style={{width:"210px"}} type="primary" disabled={!importForm.getFieldValue("zmPictureUrl")} icon={<UploadOutlined />}>选择文件</Button>
                                    <div style={{color:'#f57070',height:"32px",lineHeight:"32px",marginLeft:'10px'}}>
                                        (请上传3M以内活体认证视频)
                                    </div>
                                </Upload>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default Search
