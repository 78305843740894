// 订单列表
import React, { useState } from 'react'
import { Modal, Form, Upload, message, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
function ImportModal(props) {
    const upFileProps = props.upFileProps
    const [loading, setLoading] = useState(false)
    const [importForm] = Form.useForm()
    const [fileList, setFileList] = useState([]) //文件列表
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },
        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }
    //关闭新增弹窗
    const onImportCancel = ()=> {
        upFileProps.setImportVisible(false)
        importForm.resetFields()
        setFileList([])
    }
    // 上传文件
    const onHandleUp = async()=> {
        let values = await importForm.validateFields()
        let data = new FormData() //通过FormData将文件转成二进制数据
        let file = values.file.file
        data.append('token', localStorage.getItem('token'))
        data.append('mobile', localStorage.getItem('mobile'))
        data.append('file', file)

        setLoading(true)

        axios({
            method: 'post',
            url: upFileProps.url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({ data: { retMsg, success } }) => {
            setLoading(false)
            if (success) {
                upFileProps.setImportVisible(false)
                message.success(retMsg)
                upFileProps.handelQuery()
                importForm.resetFields()
                setFileList([])
            } else {
                message.error(retMsg)
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }
    return (
        <Modal maskClosable={false} title='导入' confirmLoading={loading} visible={upFileProps.importVisible} onOk={onHandleUp} onCancel={onImportCancel}>
            <Form form={importForm}>
                <Form.Item label='上传表格' name='file' rules={[{ required: true, message: '请上传表格' }]}>
                    <Upload {...fileProps}>
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                        <Button
                            type='link'
                            onClick={e => {
                                e.stopPropagation()
                                window.location.href = `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=${upFileProps.type}`
                            }}
                        >
                            下载模板
                        </Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ImportModal
