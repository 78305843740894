import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Space
 } from 'antd';
import {request} from '../../../../utils/fetch'
 import moment from 'moment';
 import MyModel1 from './MyModel1';
 import MyModel2 from './MyModel2';
 import MyModel3 from './MyModel3';
 import store from '../../../../store/index'
 import { getTaxEnterpriseList } from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function ManualCheck(props) {
    let path = 'platform:BillAudit'
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const myModel2 = useRef();
    const myModel3 = useRef();
    const initSearch = {
        date:[],
        auditStatus: null,
       
    }
    const [dayValue, setDayValue] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [reasonList,setReasonList] = useState([])
    const pageSize = 20     //每页条数
    const [companyList,setCompanyList] = useState([])
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [rows,setRows] = useState([])
    const [titleModel,setTitleModel] = useState('通过')
    const [detailObj,setDetailObj] = useState({
        auditReason:''
    })       //查看详情
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '进入审核时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align:'center',
        }, 
        { title: '结算通道', dataIndex: 'taxEnterpriseName', key: 'taxEnterpriseName', align: 'center', width: 150 },
        {
            title: '企业名称',
            dataIndex: 'companyCodeName',
            key: 'companyCodeName',
            width: 150,
            align:'center',
        },{
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
        }, {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 150,
            align:'center',
        },  {
            title: '发放方式',
            dataIndex: 'lineType',
            key: 'lineType',
            width: 150,
            align:'center',
            render: (text, record) => record.lineType == 1 ?'线上发放':'线下发放'
            
        }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
        },  {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
        }, {
            title: '佣金',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
        },  {
            title: '进入审核的原因',
            dataIndex: 'auditReasonName',
            key: 'auditReasonName',
            width: 150,
            align:'center',
        },
        {
            title: '审核状态',
            dataIndex: 'auditStatusName',
            key: 'auditStatusName',
            width: 150,
            align:'center',
        },
        {
            title: '打款备注',
            dataIndex: 'content',
            key: 'content',
            width: 150,
            align:'center',
        },
        {
        title: '操作',
        key: 'action',
        width: 180,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
            {
                record.auditStatus == 'SHTG' ||  record.auditStatus == 'SHJJ' || record.auditStatus == 'FQ' || record.auditStatus == 'ZDTG'
                ? store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.handleLook(record) }>查看</a>
                : <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&<a onClick={() => methodsFn.handlePass(record)}>通过</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&<a onClick={() => methodsFn.handleRefuse(record)}>拒绝</a>}
                </Space>
            }
            </Space>
        ),
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="进入审核日期" name="date">
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="审核状态" name="auditStatus" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            <Option value={'SHZ'}>审核中</Option>
                            <Option value={'SHJJ'}>审核拒绝</Option>
                            <Option value={'SHTG'}>审核通过</Option>
                            <Option value={'FQ'}>企业放弃</Option>
                            {/* <Option value={'ZDTG'}>自动审核通过</Option> */}
                        </Select>
                    </FormItem>
                    <FormItem  label="企业名称" name="companyCode"  style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="账单月份" name="month"  style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                    <FormItem label="进入审核的原因" name="auditReason" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            {
                                reasonList.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.reason}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="结算通道" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                        <Input placeholder="姓名|身份证号码" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleSomePass}>批量通过</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleSomeRefuse}>批量拒绝</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                auditStatus:formObj.auditStatus,
                companyCode:formObj.companyCode,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
                month:month,
                auditReasonList:formObj.auditReason,
                keyword:formObj.keyword,
            }
            return obj
        },
        handleSearch() { 
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/billAudit/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getAuditReason(){
            let result = await request.get('/common/getAuditReason')
            let arr = []
            for( var key in result){
                arr.push({
                    id:key,
                    reason:result[key]
                })
            }
            setReasonList(arr)
       },
       async getDetail(obj){
        const result = await request.get('/platform/billAudit/getDetail',{
            billDetailNo:obj.billDetailNo,
            auditNo:obj.auditNo,
        })
        setDetailObj(result)
   },
   handleLook(record){
        methodsFn.getDetail(record)
        myModel3.current.showModal(true)
   },
   handlePass(record){
        methodsFn.getDetail(record)
        myModel2.current.showModal(true)
        setTitleModel('通过')
   },
   handleRefuse(record){
        methodsFn.getDetail(record)
        myModel2.current.showModal(true)
        setTitleModel('拒绝')
    },
    async getCompanyList(){
        let result = await request.get('/platform/companyBase/allList')
        setCompanyList(result)
    },
 
   handleSomePass(){   //批量通过
        if(rows.length < 1){
            message.error('请先勾选列表')
            return
        }
        myModel1.current.showModal(true)
        setTitleModel('通过')
    },
    handleSomeRefuse(){  //批量拒绝
        if(rows.length < 1){
            message.error('请先勾选列表')
            return
        }
        myModel1.current.showModal(true)
        setTitleModel('拒绝')
    },
    refresh(){
        methodsFn.getTableList(methodsFn.getsearchObj())
    },
    tableChange(key,row){
        setRows(row)
    },

    handleExport(){
        request.downExport('/platform/billAudit/export',methodsFn.getsearchObj())
    },
     // 获取所有结算通道
     async getTaxEnterpriseListall(){
        const res = await getTaxEnterpriseList()
        if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
        }
    }
    }
    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getAuditReason()
        methodsFn.getTaxEnterpriseListall()
        
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
           <Table 
             rowSelection={{
                type: "checkbox",
                onChange:methodsFn.tableChange,
              }}
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.billDetailNo}
            dataSource={tableData} 
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

           <MyModel1 ref={myModel1} titleModel={titleModel} tableData={rows} refresh={methodsFn.refresh}/>
           <MyModel2 ref={myModel2} detailObj={detailObj} refresh={methodsFn.refresh} titleModel={titleModel}/>
           <MyModel3 ref={myModel3} detailObj={detailObj}/>
        </div>
    )
}

export default ManualCheck
