import React, { useState, useEffect } from 'react'
import {
  Modal,
  Input,
  Button,
  Table,
  message
} from 'antd'
import { staffKeepBatch} from '../../api'
import _ from 'lodash'

const { Search } = Input

function BatchTransact(props) {
  const [keyword, setKeyword] = useState()
  const { visible, close, modalData, refresh } = props
  const [tableData, setTableData] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepNoList, setKeepNoList] = useState([])

  const filterData = () => {
    console.log(modalData)
    const data = modalData.filter(item => (item.transactStatus === 'DDBL' || item.transactStatus === 'BZSHTG') && (item.planType==2&&item.keepStatus!='WSC'||item.planType!=2))
    let arr = []
    data.forEach(item => {
      arr.push(item.keepNo)
    })
    setKeepNoList(arr)
    setTableData(data)
  }
  useEffect(() => {
    if (visible) {
      filterData(modalData)
    }
  }, [visible, modalData])
  const tableColumns = [
    {title: '双方签署完成时间',dataIndex: 'signTime',key: 'signTime',align: 'center'},
    {title: '成立日期',dataIndex: 'establishDate',key: 'establishDate',align: 'center', width: 100},
    {title: '落地城市',dataIndex: 'cityName',key: 'cityName',align: 'center',width: 100},
    {title: '落地区县',dataIndex: 'countyName',key: 'countyName',align: 'center',width: 140},
    {title: '落地方案',dataIndex: 'planTypeName',key: 'planTypeName',align: 'center',width: 100}, 
    {title: '姓名',dataIndex: 'name',key: 'name',align: 'center',width: 100}, 
    {title: '身份证号',dataIndex: 'idNumber',key: 'idNumber',align: 'center',width: 180}
  ]
  const onCancel = () => {
    close()
    setKeyword()
  }
  const inputChange = (value) => {
    setKeyword(value)
  }
  const onSearch = () => {
    const cloneData = _.cloneDeep(tableData)
    if (keyword){
      const tableFilter = cloneData.filter(data => data.name.includes(keyword) || data.idNumber.includes(keyword))
      setTableData(tableFilter)
    } else {
      filterData(modalData)
    }
  }
  const confirm = async() => {
    try {
      if (keepNoList.length < 1) {
        message.warning('没有需要办理的数据')
        return
      }
      setConfirmLoading(true)
      const res = await staffKeepBatch({keepNoList: keepNoList.join(',')})
      if (res.data.success){
        message.success('请求成功')
        close()
        refresh()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
      console.log(err)
    }
  }
  return (
    <Modal maskClosable={false} title="批量办理" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={confirm} width={1040}>
      <div style={{color: '#FF0000', marginBottom: '10px'}}>{tableData.length > 0 ? '确定以下人员的证件线下已完成办理么' : '没有选中可办证的人员'}</div>
      <div>
        <span>关键字：</span>
        <Search 
          value={keyword} 
          onChange={(e) => inputChange(e.target.value)}
          onSearch={onSearch}
          placeholder="姓名 | 身份证" 
          style={{width: '320px'}}
          enterButton="查询"
        >
        </Search>
      </div>
      <Table 
        scroll={{ x: 'max-content' }}
        columns={tableColumns}
        dataSource={tableData}
        rowKey={record => record.keepNo}
        pagination={false}
      />
    </Modal>
  )
  
}

export default BatchTransact