import React, { useState, useEffect } from 'react'
import { Row, Table, Form, Button, Space, Typography, Col, Input, Spin, Select } from 'antd'
import qs from 'qs'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select
const labelStyleObj = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 15,
    },
}

function Detail(props) {
    const search = qs.parse(props.history.location.search.replace(/\?/g, ''))
    let searchParam = JSON.parse(search.record)
    searchParam.companyType = search.type
    // console.log(searchParam)
    // const path = 'platform:BillUpload'
    const [form] = Form.useForm()
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [loading, setLoading] = useState(false)
    const columns =
        searchParam.companyType === '1'
            ? [
                  {
                      title: '姓名',
                      dataIndex: 'name',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '身份证号',
                      dataIndex: 'idNumber',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '手机号',
                      dataIndex: 'phone',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '换签日期',
                      dataIndex: 'signDate',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '签署文件',
                      width: 150,
                      align: 'center',
                      dataIndex: 'pdfViewUrl',
                      fixed: 'right',
                      render: (pdfViewUrl) =>
                          pdfViewUrl && (
                              <Space size='middle'>
                                  <Link href={pdfViewUrl} target='_blank'>
                                      查看
                                  </Link>
                              </Space>
                          ),
                  },
              ]
            : [
                  {
                      title: '姓名',
                      dataIndex: 'name',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '身份证号',
                      dataIndex: 'idNumber',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '手机号',
                      dataIndex: 'phone',
                      width: 150,
                      align: 'center',
                  },
                  {
                      title: '签署状态',
                      dataIndex: 'signStatusName',
                      width: 150,
                      align: 'center',
                  },
              ]
    //导航和查询组件
    const renderSearchForm = () => {
        return (
            <Form form={form} {...labelStyleObj}>
                <Row style={{ padding: '10px 0 20px 5px', fontSize: 16 }} align='middle'>
                    <Space>
                        <Col onClick={goHome} style={{ fontWeight: 700, cursor: 'pointer' }}>
                            合同换签
                        </Col>
                        <Col>{`> ${searchParam.companyType === '1' ? '已' : '未'}换签人员`}</Col>
                        <Col>{`> ${searchParam.companyName}`}</Col>
                    </Space>
                </Row>
                <Row style={{ padding: '0 0 30px 0', fontSize: 16 }}>
                    <Col
                        style={{ paddingRight: 25, borderRight: '1px solid #d9d9d9', marginRight: 20 }}
                    >{`【换签前】结算通道：${searchParam.beforeEnterpriseName} 落地方案：${searchParam.beforePlanName}`}</Col>
                    <Col>{`【换签后】结算通道：${searchParam.afterEnterpriseName} 落地方案：${searchParam.afterPlanName}`}</Col>
                </Row>
                <Row>
                    <FormItem label='关键字' name='keyword' labelCol={{ span: 5 }} style={{ width: '430px' }}>
                        <Input placeholder='姓名丨身份证号丨手机号' />
                    </FormItem>
                    {searchParam.companyType === '2' && (
                        <FormItem label='签署状态' name='signStatusList' labelCol={{ span: 5 }} style={{ width: '430px' }}>
                            <Select mode='multiple' allowClear>
                                <Option value='DDYH'>等待用户签署</Option>
                                <Option value='DDGS'>等待平台签署</Option>
                                <Option value='WC'>双方完成签署</Option>
                                <Option value='YHSB'>用户签署失败</Option>
                                <Option value='GSSB'>平台签署失败</Option>
                                <Option value='0'>等待认证</Option>
                                <Option value='1'>已认证</Option>
                                <Option value='2'>认证失败</Option>
                            </Select>
                        </FormItem>
                    )}
                </Row>
                <Row justify='end'>
                    <Space>
                        <Button onClick={handleSearch} type='primary'>
                            查询
                        </Button>
                        <Button onClick={handleFormReset}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                    </Space>
                </Row>
            </Form>
        )
    }
    // 返回列表页
    const goHome = () => {
        props.history.push('/admin/changeContract')
    }

    //重置表单
    const handleFormReset = () => form.resetFields()
    const handleSearch = () => {
        setPageNum(1)
        if(pageNum==1){
            getTableList()
        }
    }
    //导出
    const exportData = () => {
        let formData = form.getFieldsValue()
        const params = {
            keyword: formData.keyword,
            signNo: searchParam.signNo,
        }

        let url = searchParam.companyType === '1' ? '/platform/changeSign/exportHasSignList' : '/platform/changeSign/exportNoSignList'

        if (searchParam.companyType === '2') {
            params.companyCode = searchParam.companyCode
            params.beforeEnterpriseCode = searchParam.beforeEnterpriseCode
            params.beforePlanType = searchParam.beforePlanType
            formData.signStatusList && (params.signStatusList = formData.signStatusList)
        }

        request.downExport(url, params)
    }

    const getTableList = async () => {
        const formData = form.getFieldsValue()
        //根据companyType判断换签状态，来选择对应链接和参数
        let url = searchParam.companyType === '1' ? '/platform/changeSign/getHasSignList' : '/platform/changeSign/getNoSignList'
        let params = {
            pageSize: pageSize,
            pageNum: pageNum,
            keyword: formData.keyword,
            signNo: searchParam.signNo,
        }
        if (searchParam.companyType === '2') {
            params.companyCode = searchParam.companyCode
            params.beforeEnterpriseCode = searchParam.beforeEnterpriseCode
            params.beforePlanType = searchParam.beforePlanType
            formData.signStatusList && (params.signStatusList = formData.signStatusList)
        }
        setLoading(true)
        let result = await request.get(url, params).catch((err) => {
            setLoading(false)
            throw err
        })
        console.log(result)
        setTotal(result.totalCount)
        setTableData(result.list)
        setLoading(false)
    }

    useEffect(() => {
        getTableList()
    }, [pageNum])

    return (
        <Spin spinning={loading}>
            {renderSearchForm()}
            <Table
                scroll={{ x: 'max-content' }}
                columns={columns}
                rowKey='idNumber'
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
        </Spin>
    )
}

export default Detail
