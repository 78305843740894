import React, { useState, useEffect } from 'react'
import { useImmer } from 'use-immer'
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Space,
  Modal,
  Spin,
  message
} from 'antd'
import { getContractLists, getContractDetail, updateContractLists } from '../api'
import store from '../../../../store/index'
const { TextArea } = Input
const { Option } = Select
const labelStyleObj = {
  wrapperCol:{ span: 18}
}
const itemStyle = {width: '340px', marginRight: '15px'}

const ContractLists = (props) => {
  let path = 'platform:CompanySendConfig'
  const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [disabled, setDiasbled] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [companyCode, setCompanyCode] = useState()

  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })

  useEffect(() => {
    getTableLists(pagination)
  }, [pagination.pageNum])

  // 
  const getTableLists = async({pageNum, pageSize}) => {
    try {
      setLoading(true)
      const result = await getContractLists(Object.assign({}, form.getFieldsValue(), {pageNum: pageNum, pageSize: pageSize}))
      console.log(result)
      if (result.data.success) {
        setTableData(result.data.data.list)
        setPagination(prev => {
          return {
            ...prev,
            total: result.data.data.totalCount
          }
        })
      } else {
        message.error(result.data.retMsg)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
   
  }
  // 查看弹窗字段
  const initialFormValues = {
    companyName: '',
    taskType: '',
    serviceProject: '',
    orderCondition: '',
    orderWay: '',
    assessStandard: ''
  }
  // 列表字段
  const tableColumns = [
    {
      title: '企业名称',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center'
    },{
      title: '企业ID',
      dataIndex: 'companyCode',
      key: 'companyCode',
      align: 'center'
    },{
      title: '任务类型',
      dataIndex: 'taskTypeName',
      key: 'taskTypeName',
      align: 'center'
    },{
      title: '状态',
      dataIndex: 'configStatusName',
      key: 'configStatusName',
      align: 'center'
    },{
      title: '操作',
      key: 'control',
      render: (record) => (
        <Space>
          {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => modify(record)}>修改</a>}
          {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => checkDetail(record)}>查看</a>}
        </Space>
      )
    }
  ]

  // 重置
  const resetForm = () => {
    form.resetFields()
  }
  //分页改变
  const tablePageChange = (pageNum, pageSize) => {
    setPagination(pagination => {
      return {
        ...pagination,
        pageNum: pageNum
      }
    })
  }
  // 查看
  const checkDetail = async(record) => {
    try{
      setModalVisible(true)
      setDiasbled(true)
      const res = await getContractDetail({companyCode:record.companyCode})
      if (res.data.success) {
        modalForm.setFieldsValue({
          companyName: res.data.data.companyName,
          taskType: res.data.data.taskType,
          serviceProject: res.data.data.serviceProject,
          orderCondition: res.data.data.orderCondition,
          orderWay: res.data.data.orderWay,
          assessStandard: res.data.data.assessStandard
        })
      } else {
        message.error(res.data.retMsg)
      }
    } catch(err) {
      console.log('__发包信息详情', err)
    }
    
  }
  // 修改
  const modify = async(record) => {
    try {
      setModalVisible(true)
      setDiasbled(false)
      const res = await getContractDetail({companyCode:record.companyCode})
      setCompanyCode(record.companyCode)
      if (res.data.success) {
        modalForm.setFieldsValue({
          companyName: res.data.data.companyName,
          taskType: res.data.data.taskType,
          serviceProject: res.data.data.serviceProject,
          orderCondition: res.data.data.orderCondition,
          orderWay: res.data.data.orderWay,
          assessStandard: res.data.data.assessStandard
        })
      } else {
        message.error(res.data.retMsg)
      }
      
    } catch(err) {
      console.log('__发包信息详情', err)
    }
    
  }

  const submit = () => {
    // setLoading(true)
    getTableLists({pageNum: 1, pageSize: 20})
    setPagination(prev => {
      return {
        ...prev,
        pageNum: 1
      }
    })
  }
  const ModalCancel = () => {
    setModalVisible(false)
  }
  // 修改
  const updateContract = async() => {
    const data = Object.assign({}, modalForm.getFieldsValue(), {companyCode: companyCode})
    try {
      setConfirmLoading(true)
      const res = await updateContractLists(data)
      setConfirmLoading(false)
      if (res.data.success) {
        message.success('修改成功')
        setModalVisible(false)
        getTableLists(pagination)
      } else {
        message.error(res.data.retMsg)
      }
    } catch(err) {
      console.log('__修改发包信息', err)
    }
  }
  const ModalOk = () => {
    if (disabled) {
      setModalVisible(false)
    } else {
      updateContract()
    }
  }
  return (
    <Spin spinning={loading}>
      <div className="contract-lists">
        <Form labelCol={{ span: 6 }}  form={form}>
          <Row>
            <Form.Item style={itemStyle} {...labelStyleObj} name="configStatus" label="状态">
              <Select allowClear>
                <Option value="1">已配置</Option>
                <Option value="0">未配置</Option>
              </Select>
            </Form.Item>
            <Form.Item labelCol={{ span: 5 }} style={{width: '430px'}} name="keyword" label="关键字">
              <Input  placeholder="企业名称丨企业ID" />
            </Form.Item>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'right'}}>
              <Space>
                <Button type="primary" onClick={submit}>查询</Button>
                <Button onClick={resetForm}>重置</Button>
              </Space>
            </Col>                              
          </Row>
        </Form>

        <Table 
          columns={tableColumns} 
          dataSource={tableData}
          scroll={{ x: 'max-content' }}
          rowKey={record => record.companyCode}
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            onChange: (pageNum, pageSize)　=> tablePageChange(pageNum, pageSize)
          }}
        >

        </Table>
        <Modal maskClosable={false} title={disabled ? '查看' : '修改'} confirmLoading={confirmLoading} visible={modalVisible} onCancel={ModalCancel} onOk={ModalOk}>
          <Form form={modalForm}　initialValues={initialFormValues}>
            <Form.Item label="发包企业" name="companyName">
              <Input disabled></Input>
            </Form.Item>
            <Form.Item label="任务类型" name="taskType">
              <Select placeholder="请选择" disabled={disabled}>
                <Option value={1}>配送服务</Option>
                <Option value={2}>交通运输服务</Option>
                <Option value={3}>广播影视服务</Option>
                <Option value={4}>收派服务</Option>
                <Option value={5}>居民日常服务</Option>
                <Option value={6}>技术咨询服务</Option>
                <Option value={7}>销售推广服务</Option>
              </Select>
            </Form.Item>
            <Form.Item label="服务项目" name="serviceProject">
              <TextArea disabled={disabled}></TextArea>
            </Form.Item>
            <Form.Item label="接单条件" name="orderCondition">
              <TextArea disabled={disabled}></TextArea>
            </Form.Item>
            <Form.Item label="结算方式" name="orderWay">
              <TextArea disabled={disabled}></TextArea>
            </Form.Item>
            <Form.Item label="考核标准" name="assessStandard">
              <TextArea disabled={disabled}></TextArea>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  )
}

export default ContractLists