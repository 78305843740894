export const SET_USER = 'SET_USER'      //设置用户名
export const SET_MENUpower = 'SET_MENUpower'      //设置菜单权限





export function setUser(name){
    return {
        type:SET_USER,
        preload:name || '用户名'
    }
 }


export function setPower(data){
    return {
        type: SET_MENUpower,
        preload: data || {}
    }
 }