import React, { useState, useEffect } from 'react'
import { useImmer } from 'use-immer'
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Spin,
  Switch,
  Modal,
  Radio,
  Checkbox,
  Space,
  message,
  InputNumber,
  Row,
  Col,
  PageHeader
} from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import store from '../../../../store/index'
import { updateCompany, getCityCountyByType, getCompanyDetail } from '../api'
import { getTaxEnterpriseList } from '../../../../api/api'
import _ from 'lodash'
import './index.css'

const { Option } = Select

function CompanyUpdate(props) {
  let path = 'platform:CompanyBase'
  const [form] = Form.useForm()
  const params = props.match.params 
  const [licenseFileUrl, setLicenseFileUrl] = useState()
  const [fileUrl, setFileUrl] = useState([])
  const [commissionBasis, setCommissionBasis] = useState(true) // 委托代征
  const [submitLoading, setSubmitLoading] = useState(false)
  const [draftLoading, setDraftLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cityCounty, setCityCounty] = useState([])
  const [privateCityCounty, setPrivatecityCounty] = useState([])
  const [commissionBasisCityCounty, setCommissionBasisCityCounty] = useState([])
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8 },
  }

  // 获取城市区县
  const getCityCounty = async (planType) => {
    try {
      const result = await getTaxEnterpriseList({ planType: planType })
      if (result.data.success) {
        if (planType === 1) {
          setCityCounty(result.data.data)
        } else if (planType === 2) {
          setPrivatecityCounty(result.data.data)
        } else if (planType === 3) {
          setCommissionBasisCityCounty(result.data.data)
        } else {
          message.error(result.data.retMsg)
        }
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      console.log('__企业入驻城市片区', err)
    }
  }
  const initialFormValues = {
    companyName: '',
    industryType: null,
    taxpayerType: null,
    taxpayerNumber: '',
    address: '',
    registeredPhone: '',
    bankName: '',
    checkModal: '',
    bankNumber: '',
    bankAccountName: '',
    legalPersonName: '',
    contactEmail: '',
    // refAccountCompanyCode: '',
    projectCode: null, //项目名称 ELE饿了么 CN菜鸟 HM盒马
    auditMode: 1  //佣金审核模式 0-人工审核 1-自动审核通过
  }
  // 委托代征
  const [commissionBasisData, updateCommissionBasisData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: null,
    planType: 3,
    serviceValue: null,
    sendConfirmFlag: 1,
    directFlag: 1,
  })
  const [administrator, setAdministrator] = useState({
    managePhone: '',
    manageName: ''
  })
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList: '',
    previewTitle: ''
  })
  const [previewData1, setPreviewData1] = useState({
    fileList: '',
  })

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  // 上传图片，把图片地址存入 licenseFileUrl
  const handleChange = ({ file, fileList }) => {
    console.log(file, fileList)
    let licenseFileUrl = ''

    fileList.forEach(item => {

      if (item.status === 'done') {
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          licenseFileUrl = item.response.data[0].url
        }
      }
    })
    setLicenseFileUrl(licenseFileUrl)
    setPreviewData(prev => {
      return {
        ...prev,
        fileList: fileList
      }
    })

  }
  // 上传文件，把图片地址存入 fileUrlStr
  const handleChange1 = ({ file, fileList }) => {
    console.log(file, fileList)
    let fileUrlStr = []

    fileList.forEach(item => {
      if (item.status === 'done') {
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          fileUrlStr.push(item.response.data[0].url)
        }
      }
    })
    setFileUrl(fileUrlStr)
    setPreviewData1(prev => {
      return {
        ...prev,
        fileList: fileList
      }
    })

  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const commissionBasisChange = (e) => {
    if (e.target.checked) {
      getCityCounty(3)
    }
    setCommissionBasis(e.target.checked)
  }

// 获取详情回显数据
const getDetail = async() => {
  try {
    setLoading(true)
    const result = await getCompanyDetail({companyCode: params.companyCode})
    if (result.data.success) {
      // setDetail(result.data.data)
      form.setFieldsValue({
        companyName: result.data.data.companyName,
        industryType: result.data.data.industryType,
        taxpayerType: result.data.data.taxpayerType,
        taxpayerNumber: result.data.data.taxpayerNumber,
        address: result.data.data.address,
        registeredPhone: result.data.data.registeredPhone,
        bankName: result.data.data.bankName,
        bankNumber: result.data.data.bankNumber,
        bankAccountName: result.data.data.bankAccountName,
        legalPersonName: result.data.data.legalPersonName,
        contactEmail: result.data.data.contactEmail,
        // refAccountCompanyCode: result.data.data.refAccountCompanyCode,
        projectCode:result.data.data.projectCode,
        auditMode: result.data.data.auditMode
      })
      setLicenseFileUrl(result.data.data.licenseFileUrl)
      
      if (result.data.data.licenseFileUrl) {
        setPreviewData(prev=>{
          return {
            ...prev,
            fileList: [{uid: 'uid2', url: result.data.data.licenseFileUrl}]
          }
        })
      }
      if (result.data.data.fileUrlStr) {
        let list = result.data.data.fileUrlStr.split(',')
        setFileUrl(list)
        let urls = list.map((item,i)=>{
          return {uid: `uid${i}`, name: item, url: item}
        })
        setPreviewData1(prev=>{
          return {
            fileList: urls
          }
        })
      }
     
      const arr = _.groupBy(result.data.data.serviceConfigVoList, 'planType')
      updateCommissionBasisData(draft => {
        if (arr[3]) {
          setCommissionBasis(true)
          getCityCounty(3)
          draft.areaNo = arr[3][0].areaNo
          draft.cityId = arr[3][0].cityId
          draft.countyId = arr[3][0].countyId
          draft.taxEnterpriseCode = arr[3][0].taxEnterpriseCode
          draft.planType = arr[3][0].planType
          draft.serviceValue = arr[3][0].serviceValue
          draft.sendConfirmFlag = arr[3][0].sendConfirmFlag
          draft.directFlag = arr[3][0].directFlag
        }
      })
      setAdministrator(prev=> {
        return {
          ...prev,
          managePhone: result.data.data.managePhone,
          manageName: result.data.data.manageName
        }
      })
    } else {
      message.error(result.data.retMsg)
    }
    console.log(result)
    setLoading(false)
  } catch(err) {
    setLoading(false)
    console.log('__企业详情', err)
  }
  
}

  // 初次加载 获取落地配置的城市区县
  useEffect(() => {
    if (commissionBasis) {
      getDetail()
      getCityCounty(3)
    }
  }, [])

  // // 审核模式选择
  // const checkModeChange = (value) => {
  //   setCheckMode(value)
  // }
  // 更新配置数据 type 1 临时税务 2 个体工商 3 委托代征
  // field 字段名  index有的话 该字段是数组，name是field[index]的属性
  const updateData = (value, type, field, index, name) => {
    if (type === 3) {
      if (field === 'taxEnterpriseCode') {
        let arr = commissionBasisCityCounty.filter(item => item.code === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateCommissionBasisData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1) {
        updateCommissionBasisData(draft => {
          draft[field][index][name] = value
        })
      } else {
        if(field === 'directFlag'||field === 'sendConfirmFlag'){
          updateCommissionBasisData(draft => {
            draft[field] = value? 1:0
          })
        }else{
          updateCommissionBasisData(draft => {
            draft[field] = value
          })
        }
      }
    }
  }
  // 
  const updateManagePhone = (value) => {

    setAdministrator(prev => {
      return { ...prev, managePhone: value }
    })
  }
  const updateManageName = (value) => {
    setAdministrator(prev => {
      return { ...prev, manageName: value }
    })
  }
  // 提交
  const submit = async () => {
    let serviceConfigVoList = []
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    let fileUrlStr = fileUrl.join(',')
    let data = Object.assign({}, form.getFieldsValue(), { operateType: 2, fileUrlStr: fileUrlStr, licenseFileUrl: licenseFileUrl, serviceConfigVoList: serviceConfigVoList, companyCode: params.companyCode }, administrator)
    try {
      await form.validateFields()
      if (!licenseFileUrl) {
        message.warning('请上传营业执照')
        return
      }
      if (!fileUrl) {
        message.warning('请上传附件')
        return
      }
      if (!administrator.manageName) {
        message.warning('请填写管理员姓名！')
        return
      }

      if (!(/^1[3456789]\d{9}$/).test(administrator.managePhone) || !administrator.managePhone) {
        message.warning('请输入正确的手机号！')
        return
      }
      setSubmitLoading(true)
      const result = await updateCompany(data)
      setSubmitLoading(false)
      console.log(result)
      if (result.data.success) {
        message.success('入驻成功')
        props.history.push({ pathname: '/admin/companyLists' })
      } else {
        message.error(result.data.retMsg)
      }
    } catch (error) {
      console.log('__企业入驻', error)
      setSubmitLoading(false)
    }
  }
  // 暂存
  const draft = async () => {
    let serviceConfigVoList = []
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    let fileUrlStr = fileUrl.join(',')
    let data = Object.assign({}, form.getFieldsValue(), { operateType: 1, fileUrlStr: fileUrlStr, licenseFileUrl: licenseFileUrl, serviceConfigVoList: serviceConfigVoList, companyCode: params.companyCode }, administrator)
    try {
      if (!form.getFieldValue('companyName')) {
        message.warning('请填写企业名称！')
        return
      }
      if (!form.getFieldValue('industryType')) {
        message.warning('请选择行业分类！')
        return
      }
      setDraftLoading(true)
      const result = await updateCompany(data)
      if (result.data.success) {
        message.success('暂存成功')
        props.history.push({ pathname: '/admin/companyLists' })
      } else {
        setDraftLoading(false)
        message.error(result.data.retMsg)
      }
    } catch (error) {
      console.log('__企业入驻暂存', error)
      setDraftLoading(false)
    }
  }
  // 清空
  const resetData = () => {
    form.resetFields()
    updateCommissionBasisData(draft => {
      draft.areaNo = ''
      draft.cityId = ''
      draft.countyId = ''
      draft.taxEnterpriseCode = null
      draft.planType = 3
      draft.serviceValue = null
      draft.sendConfirmFlag = 1
      draft.directFlag = 1
    })
    setFileUrl([]) 
    setLicenseFileUrl('')
    setPreviewData({
      previewVisible: false,
      previewImage: null,
      fileList: '',
      previewTitle: ''
    })
    setPreviewData1({
      fileList: '',
    })
    setAdministrator({
      manageName: '',
      managePhone: ''
    })
  }
  return (
    <Spin spinning={loading}>
    <PageHeader 
      title="修改"
      onBack={() => props.history.goBack()}
    />
    <div className="company-enter">
      <div className="title">基本信息</div>
      <Form form={form} initialValues={initialFormValues} {...formItemLayout}>
        <Form.Item name="companyName" rules={[{ required: true }]} label="企业名称">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="projectCode" rules={[{ required: true }]} label="项目名称">
          <Select placeholder="请选择">
            <Option value='ELE'>饿了么</Option>
            <Option value='CN'>菜鸟</Option>
            <Option value='HM'>盒马</Option>
            <Option value='WL'>物流</Option>
            <Option value='DD'>达达</Option>
          </Select>
        </Form.Item>
        <Form.Item name="industryType" rules={[{ required: true }]} label="行业分类">
          <Select placeholder="请选择">
            <Option value={1}>交通运输服务业</Option>
            <Option value={2}>信息技术服务业</Option>
            <Option value={3}>物流辅助服务业</Option>
            <Option value={4}>广播影视服务业</Option>
            <Option value={5}>供应链服务业</Option>
            <Option value={6}>居民生活日常服务业</Option>
          </Select>
        </Form.Item>
        <Form.Item name="taxpayerType" rules={[{ required: true }]} hasFeedback label="纳税人类型">
          <Select placeholder="请选择">
            <Option value={1}>一般纳税人</Option>
            <Option value={2}>小规模纳税人</Option>
          </Select>
        </Form.Item>
        <Form.Item name="taxpayerNumber" rules={[{ required: true }]} label="统一社会信用代码">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="address" rules={[{ required: true }]} label="单位地址">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="registeredPhone" rules={[{ required: true }]} label="注册电话">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="bankName" label="开户行名称">
          <Input placeholder="请输入" />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} name="bankNumber" label="银行账号">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="bankAccountName" label="账号名称">
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Item name="legalPersonName" rules={[{ required: true }]} label="法人姓名">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="contactEmail" rules={[
          {
            type: 'email',
            message: '邮箱格式不正确!',
          }, {
            required: true
          }]} label="联系邮箱">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="营业执照">
          <Upload
            action="/hire/common/uploadFile"
            listType="picture-card"
            data={uploadObj}
            name="files"
            fileList={previewData.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {previewData.fileList.length > 0 ? null : uploadButton}
          </Upload>
          <Modal maskClosable={false}
            visible={previewData.previewVisible}
            title={previewData.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
          </Modal>
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="附件">
          <Upload
            action="/hire/common/uploadFile"
            data={uploadObj}
            name="files"
            fileList={previewData1.fileList}
            onChange={handleChange1}
          >
            <Button icon={<UploadOutlined />}>上传</Button>
          </Upload>
        </Form.Item>
        <div className="title" style={{ marginLeft: '-10px' }}>佣金审核</div>
        <Form.Item name="auditMode"  rules={[{ required: true }]} label="人工审核">
          <Radio.Group>
            <Radio value={0}>人工审核判定通过或拒绝</Radio>
            <Radio value={1}>自动审核通过</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>

      <div className="title">落地配置</div>
      <Checkbox className="checkbox" checked={commissionBasis} onChange={commissionBasisChange}>委托代征</Checkbox>
      {commissionBasis &&
        <>
          <div className="row">
            <span className="row-tit">结算通道：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 3, 'taxEnterpriseCode')} value={commissionBasisData.taxEnterpriseCode} style={{ width: '400px' }}>
              {commissionBasisCityCounty.map(item => (
                <Option value={item.code} key={item.code}>{item.enterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <div>
              每笔<InputNumber value={commissionBasisData.serviceValue} min={0} onChange={(value) => updateData(value, 3, 'serviceValue')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">短信：</span>
            <div className='checkedmy' >
              是否发送充值确认结果短信
              <Switch checked={commissionBasisData.sendConfirmFlag} checkedChildren="是" onChange={(value) => updateData(value, 3, 'sendConfirmFlag')} unCheckedChildren="否" defaultChecked />
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否直发：</span>
            <div className='checkedmy' >
              <Switch checked={commissionBasisData.directFlag} checkedChildren="是" onChange={(value) => updateData(value, 3, 'directFlag')} unCheckedChildren="否" defaultChecked />
            </div>
          </div>
        </>
      }
      <div className="title">企业财务管理员</div>
      <div className="row">
        <span className="row-tit">姓名：</span>
        <Input value={administrator.manageName} onChange={(e) => updateManageName(e.target.value)} placeholder="请输入" style={{ width: '350px' }}></Input>
      </div>
      <div className="row">
        <span className="row-tit">手机号：</span>
        <Input value={administrator.managePhone} onChange={(e) => updateManagePhone(e.target.value)} placeholder="请输入" style={{ width: '350px' }}></Input>
      </div>
      <div className="btns">
        <Space>
          {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button type="primary" onClick={() => submit()} loading={submitLoading}>提交</Button>}
          {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button loading={draftLoading} onClick={() => draft()}>存为草稿</Button>}
          <Button onClick={resetData}>清空</Button>
        </Space>
      </div>
    </div>
  </Spin>
  )
}

export default CompanyUpdate
