import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  message,
} from 'antd'
import { staffKeepBatch } from '../../api'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const ConfirmAudit = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { visible, close, modalData, refresh } = props
  const initialFormValues = {
    name: '',
    idNumber: '',
    cityName: '',
    countyName: '',
    planTypeName: '',
  }
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: modalData.name,
        idNumber: modalData.idNumber,
        cityName: modalData.cityName,
        countyName: modalData.countyName,
        planTypeName: modalData.planTypeName,
      })
    }
  }, [visible, modalData])
  const onCancel = () => {
    close()
  }
  const handleOk = async() => {
    try {
      setConfirmLoading(true)
      const res = await staffKeepBatch({
        keepNoList: modalData.keepNo
      })
      if (res.data.success) {
        message.success('提交成功')
        close()
        refresh()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
    }
  }
  return ( 
    <>
      <Modal maskClosable={false} title="确认办证" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={handleOk} width={740}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
          <Form.Item label="姓名" name="name">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="身份证号" name="idNumber">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地城市" name="cityName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地区县" name="countyName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地方案" name="planTypeName">
            <Input disabled></Input>
          </Form.Item>
        </Form>
        <div style={{fontSize: '16px', marginTop: '14px'}}>确定该用户线下已完成办理么？</div>
      </Modal>
    </>
  )
}

export default ConfirmAudit