import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Select, Button, DatePicker, Row, Col, Space, Radio, Input } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum } = props
    let path = 'platform:payment'
    const [radio, setRadio] = useState(10)
    const [companyList, setCompanyList] = useState([])
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }
    const itemStyle = { width: '390px', marginRight: '15px' }
    const initialFormValues = { timeType: 1, }
    // 查询
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    // 查询
    const handelQuery = () => {
        let data = form.getFieldsValue()
        let params ={
            companyCodeList: data.companyCodeList,
            keyword: data.keyword,
            serviceBackStatus: data.serviceBackStatus,
            taxBackStatus: data.taxBackStatus,
            beginTime: data.date ? moment(data.date[0]).format('YYYY-MM-DD') : '',
            endTime: data.date ? moment(data.date[1]).format('YYYY-MM-DD') : '',
        }
        refresh('query', Object.assign({},params))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio(10)
    }
    // 导出
    const exportData = () => {
        let data = form.getFieldsValue()
        let params ={
            companyCodeList: data.companyCodeList,
            keyword: data.keyword,
            serviceBackStatus: data.serviceBackStatus,
            taxBackStatus: data.taxBackStatus,
            beginTime: data.date ? moment(data.date[0]).format('YYYY-MM-DD') : '',
            endTime: data.date ? moment(data.date[1]).format('YYYY-MM-DD') : '',
        }
        request.downExport('/platform/payment/exportBack', params)
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    const getCompanyList = async()=> {
        let result = await request.get('/platform/companyBase/allList')
        setCompanyList(result)
    }
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    useEffect(() => {
        getCompanyList()
    }, [])
    useEffect(() => {
        handelQuery()
    }, [pageNum])
    return (
        <>
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
        <Row>
            <Form.Item style={itemStyle} label="时间范围" name="date" >
                <RangePicker  onChange={(dates) => rangeChange(dates)} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item style={{ width: '600px', marginLeft: '15px' }}>
                <Radio.Group onChange={radioChange} value={radio} >
                <Radio value={1}>今天</Radio>
                <Radio value={7}>近7天</Radio>
                <Radio value={30}>近30天</Radio>
                </Radio.Group>
            </Form.Item>
        </Row>
        <Row>
             
            <Form.Item label="企业名称" name="companyCodeList" style={itemStyle} {...labelStyleObj}>
                <Select placeholder="全部">
                    {
                    companyList.map(item => {
                        return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                    })
                    }
                </Select>
            </Form.Item>
            <Form.Item style={itemStyle} name='serviceBackStatus' label='服务费状态' {...labelStyleObj} >
                <Select allowClear placeholder='全部'>
                    <Option value='0'>未处理</Option>
                    <Option value='1'>已处理</Option>
                </Select>
            </Form.Item>
            <Form.Item style={itemStyle} name='taxBackStatus' label='税费状态' {...labelStyleObj} >
                <Select allowClear placeholder='全部'>
                    <Option value='0'>未处理</Option>
                    <Option value='1'>已处理</Option>
                </Select>
            </Form.Item>
            <Form.Item label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                <Input placeholder="姓名|卡号" />
            </Form.Item>
        </Row>
        <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
                <Space>
                    <Button type='primary' onClick={Query}>查询</Button>
                    <Button onClick={resetForm}>重置</Button>
                    {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 &&<Button onClick={exportData}>导出</Button>}
                </Space>
            </Col>
        </Row>
    </Form>
    </>
    )
}

export default QueryForm
