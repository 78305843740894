import React, { useState, useEffect } from 'react'
import { Modal, Form, message, Radio } from 'antd'

import { updateBase } from '../../api'

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
}

const AuditModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)

  // 回显数据
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        auditMode: modalData.auditMode
      })
    }
  }, [visible, form, modalData])

  // 表单初始数据
  const initialFormValues = {
    auditMode: '',
  }

  const handleOk = async () => {
    const data = Object.assign({}, form.getFieldsValue(), {
      licenseFileUrl: modalData.licenseFileUrl,
      companyCode: modalData.companyCode,
      companyName: modalData.companyName,
      industryType: modalData.industryType,
      taxpayerType: modalData.taxpayerType,
      taxpayerNumber: modalData.taxpayerNumber,
      address: modalData.address,
      registeredPhone: modalData.registeredPhone,
      bankNo: modalData.bankNo,
      checkMode: modalData.checkMode,
      bankNumber: modalData.bankNumber,
      legalPersonName: modalData.legalPersonName,
      contactEmail: modalData.contactEmail,
      refAccountCompanyCode: modalData.refAccountCompanyCode,
      projectType: modalData.projectType,
      projectCode: modalData.projectCode
    })
    try {
      await form.validateFields()
      setConfirmLoading(true)
      const result = await updateBase(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('修改成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      setConfirmLoading(false)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false}
      title="基础信息"
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      width={740}>
      <Form form={form} initialValues={initialFormValues}>
        <Form.Item {...formItemLayout} name="auditMode" label="人工审核">
          <Radio.Group>
            <Radio value={0}>人工审核判定通过或拒绝</Radio>
            <Radio value={1}>自动审核通过</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AuditModal