import React from 'react';
import { Line } from '@ant-design/charts';
import './mychart.css'
const ChartLine = (props) => {
  let data = props.data || [];
  if(data.length>0){
    data = data.map((item) => {
      return {
        name: item.name,
        value: Number(item.value),
      };
    });
  }
  const config = {
    data,
    xField: 'name',
    yField: 'value',
    label: {},
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      customContent: (title, data) => {
        return (<div className='mycustomtooltip'>
          <div className='tooltitle' >{title}</div>
          <div>
            {data.map((item) => {
              return (
                <span>任务总金额: <span style={{marginLeft: '10px'}} >{item.value}</span>元</span>
              )
            })
          }
          </div>
        </div>)
      }
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line style={{height: '350px'}} {...config} />;
};

export default ChartLine;