import React,{useState,useEffect} from 'react'
import {
  Table,
  Input,
  Select,
  Form,
  message,
  Modal,
  Row,
  Col,
  Popconfirm,
  Space,
  Button,
  Upload
 } from 'antd';
 import axios from 'axios'
 import { UploadOutlined } from '@ant-design/icons';
 import {request} from '../../../../utils/fetch'
 import store from '../../../../store/index'
 import { getTaxEnterpriseList } from '../../../../api/api'
 const FormItem = Form.Item;
 const { Option } = Select;
 const topCol = {
    span:10,
  }
const endCol = {
    span:10,
    offset:2
}
const formItemLayout = {
labelCol: {
    span: 6,
},
wrapperCol: {
    span: 14,
},
};
function ContractTemp(props) {
    let path = 'platform:ContractTemplate'
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState();
    const [fileUrl, setFileUrl] = useState('');
    const [templateNo, setTemplateNo] = useState('');
    const [tableData,setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const [titleModel,setTitleModel] = useState('新增')
    const [loading, setLoading] = React.useState(false);
    const [detail,setDetail] = useState({})
    const [visible2, setVisible2] = React.useState(false);
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const fileProps = {
        name: 'files',
        action:"/hire/common/uploadFile",
        fileList: fileList,
        multiple: false,
        data:{
            token:localStorage.getItem('token'),
            mobile:localStorage.getItem('mobile'),
        },
        accept:'.pdf, .PDF',
        onChange({file,fileList}) {
            if(file.status == 'done'){
                setFileUrl(file.response.data[0].url)
                console.log(file.response.data[0].url)
            }
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            setFileList(fileList.slice(-1))
  
        },
      };
    
    const columns = [
        {
            title: '维护时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align:'center',
        }, {
            title: '模板编码',
            dataIndex: 'templateNo',
            key: 'templateNo',
            width: 150,
            align:'center',
        },{
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 150,
            align:'center',
        },  {
            title: '落地方案',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            align:'center',
            render: (text, record) => record.type == 1?'临时税务登记':record.type == 2?'个体工商户': '委托代征'
        }, {
            title: '维护人',
            dataIndex: 'createUser',
            key: 'createUser',
            width: 150,
            align:'center',
        },  
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            align:'center',
            render: (text, record) => record.status == 1?'启用':'停用'
        }, 
        {
        title: '操作',
        key: 'action',
        width: 220,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {
                    setDetail(record)
                    setVisible(true)
                }}>查看</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => {
                    setTitleModel('修改')
                    setVisible2(true)
                    setFileList([])
                    setTemplateNo(record.templateNo)
                    form.setFieldsValue(record)
                }}>修改</a>}
                  {store.getState().userReducer.permissions.indexOf(`${path}:status`)>-1&&<Popconfirm
                        title={`确定${record.status?'停用':'启用'}吗？`}
                        onConfirm={() => {
                            handleStatus(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a onClick={() => {}}>{record.status?'停用':'启用'}</a>
                    </Popconfirm>}
            </Space>
        ),
        },
    ];
    const getTableList =  async (searchObj) => {
        let result = await request.get('/platform/contractTemplate/list')
           setTableData(result.list)
    }
     // 获取所有结算通道
    const getTaxEnterpriseListall =  async () => {
        const res = await getTaxEnterpriseList()
        if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
        }
    }
    const handleStatus = (obj) => {
        let status;
        if(obj.status){
            status = 0
        }else{
            status = 1
        }
        request.post('/platform/contractTemplate/updateStatus',{
            templateNo:obj.templateNo,
            status:status,
        }).then(res => {
            getTableList(form.getFieldsValue())
            message.success('操作成功')
        }).catch(err => {
            message.error(err.msg)
        })
    }
    const handleOk = async() => {
        try {
          const values = await form.validateFields();
          setLoading(true);
          if(titleModel == '新增'){
            handleFile('/platform/contractTemplate/insert',{type:values.type,taxEnterpriseCode:values.taxEnterpriseCode})
            return
          }
          handleFile('/platform/contractTemplate/update',{templateNo:templateNo,taxEnterpriseCode:values.taxEnterpriseCode})
          
        } catch (errorInfo) {
          console.log('Failed:', errorInfo);
        }
      }
    const handleFile = async (url,obj) =>{
        setLoading(true)
        let values = await form.validateFields()
        request.post(url,{
            ...obj,
            pdfUrl:fileUrl,
        }).then(res => {
            setVisible2(false)
            setLoading(false);
            getTableList(form.getFieldsValue())
            message.success(res.msg)
        }).catch(err => {
            setLoading(false);
        })
    }
   
    useEffect(() => {
        getTableList()
        getTaxEnterpriseListall()
    },[])

    return (
        <div>
            <div style={{ textAlign: 'right',paddingRight:'20px' }}>
                {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button type="primary" onClick={() => {
                    form.resetFields()
                    setFileList()
                    setVisible2(true)
                    setTitleModel('新增')
                }}>新增</Button>}
            </div>
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.id}
            dataSource={tableData} 
            pagination={false}
           />
   <Modal maskClosable={false}
        title='查看'
        width='1000px'
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}>
        <Row className="margin-15">
            <Col {...topCol}>
            <div className="flex">
                <span className="left-span">维护时间:</span>
                <Input placeholder={detail.updateTime} disabled={true} />
            </div>
            </Col>
            <Col {...endCol}>
            <div className="flex">
                <span className="left-span">落地方案:</span>
                <Input placeholder={detail.type == 1?'临时税务登记':detail.type == 2?'个体工商户':'委托代征'} disabled={true} />
            </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
            <div className="flex">
                <span className="left-span">状态:</span>
                <Input placeholder={detail.status == 1?'启用':'停用'} disabled={true} />
            </div>
            </Col>
            <Col {...endCol}>
            <div className="flex">
                <span className="left-span">维护人:</span>
                <Input placeholder={detail.updateUser} disabled={true} />
            </div>
            </Col>
        </Row>
        <h2>PDF签署文件</h2>
        <iframe src={detail.pdfUrl} width="100%" height="500px">
            你的浏览器暂不支持预览，请下载查看！
        </iframe>
    </Modal>
    
    <Modal maskClosable={false}
        title={titleModel}
        width='600px'
        visible={visible2}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={() => setVisible2(false)}>
          <Form
                    form={form}
                    {...formItemLayout}
                    initialValues={{
                        type:''
                    }}
                    >
                <Form.Item name='taxEnterpriseCode' rules={[{ required: true, message: '请选择' }]} label="结算通道">
                    <Select disabled={titleModel=='修改'?true:false} allowClear>
                    {TaxEnterpriseLis.map((e)=><Select.Option key={e.code} value={e.code}>{e.enterpriseName}</Select.Option>)}
                </Select>
                </Form.Item>
                    <Form.Item
                      rules={[{ required: true, message: '请选择' }]}
                      label="类型"
                      name="type">
                        <Select placeholder="" disabled={titleModel=='修改'?true:false}>
                            <Option value={1}>临时税务登记</Option>
                            <Option value={2}>个体工商户</Option>
                            <Option value={3}>委托代征</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="PDF签署文件"
                        name="ffile"
                        rules={[{ required: true, message: '上传文件' }]}
                    >
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>
            </Form>
    </Modal>
    </div>
    )
}

export default ContractTemp
