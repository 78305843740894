import React, { useState, useEffect } from 'react'
import {
    PageHeader,
    Input,
    Button,
    Row,
    Col,
    Space,
    Form,
    Table,
    Spin,
    Radio,
    Select,
    DatePicker
} from 'antd'
import moment from 'moment'
import qs from 'qs'
import {getRecord} from '../api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'

const { RangePicker } = DatePicker
const { Option } = Select

const WithdrawRecord = (props) => {
  let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
  console.log(searchParam)
  let path = 'platform:Financialdeal'
  const [form] = Form.useForm()
  const [radio, setRadio] = useState(10)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  // form 表单域默认值
  const initialFormValues = {
    accountType: '',  // 关键字
    date: null
  }
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 列表字段
  const tableColumns = [
    {
      title: '操作时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },{
      title: '操作人',
      dataIndex: 'createUser',
      key: 'createUser',
      align: 'center'
    },{
      title: '类型',
      dataIndex: 'withdrawalTypeName',
      key: 'withdrawalTypeName',
      align: 'center'
    },{
      title: '提现金额',
      dataIndex: 'withdrawalAmount',
      key: 'withdrawalAmount',
      align: 'center'
    },{
      title: '状态',
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'center'
    },{
      title: '收款账号',
      dataIndex: 'acctNo',
      key: 'acctNo',
      align: 'center'
    },{
      title: '收款户名',
      dataIndex: 'acctName',
      key: 'acctName',
      align: 'center'
    }
  ]
  // 获取详情
  const getDetail = async(page) => {
    const date = form.getFieldsValue().date
    let params = {
      beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
      endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
      accountType: form.getFieldsValue().accountType,
      taxEnterpriseCode: searchParam.taxEnterpriseCode,
      pageSize: page.pageSize,
      pageNum: page.pageNum
    }  
    try {
      setLoading(true)
      const res = await getRecord(params)
      if (res.data.success) {
        setPagination(prev => {
          return {
            ...prev,
            total: res.data.data.totalCount
          }
        })
        setTableData(res.data.data.list)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
      console.log('___平台入账详情', err)
    }
  }
  useEffect(() => {
    getDetail(pagination)
  }, [])

  // 时间radio选择
  const radioChange = e => {
    let startDate, endDate
    setRadio(e.target.value)
    startDate = moment().subtract(e.target.value - 1, 'days')
    endDate = moment()
    form.setFieldsValue({date: [startDate, endDate]})
  }
  // 
  const rangeChange = (dates) => {
    if (dates === null) {
      setRadio(10)
    }
  }
  const submit = () => {
    getDetail({pageNum: 1, pageSize: 20})
  }
  const resetForm = () => {
    form.resetFields()
    setRadio()
  }
  const tablePageChange = (pageNum, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: pageNum
      }
    })
    getDetail({pageNum: pageNum, pageSize: pageSize})
  }
  // 导出
  const exportData = () => {
    const date = form.getFieldsValue().date
    const data = {
      beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
      endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
      accountType: form.getFieldsValue().accountType,
      taxEnterpriseCode: searchParam.taxEnterpriseCode
    }
    request.downExport('/platform/financialdeal/exportRecord', data)
  }
  return (
    <Spin spinning={loading}>
      <PageHeader
        title="提现记录"
        onBack={() => props.history.goBack()}
      />
      <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
        <Row gutter={4}>
            <Col span={6}>
                <Form.Item name="date" label="时间">
                  <RangePicker onChange={(dates) => rangeChange(dates)} allowClear={false} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item>
                <Radio.Group onChange={radioChange} value={radio}>
                  <Radio value={1}>今天</Radio>
                  <Radio value={7}>近7天</Radio>
                  <Radio value={30}>近30天</Radio>
                </Radio.Group>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="类型" name="accountType">
                    <Select>
                      <Option value="1">服务费</Option>
                      <Option value="2">税费</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        
        <Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              {store.getState().userReducer.permissions.indexOf(`${path}:getRecord:export`)>-1&&<Button onClick={exportData}>导出</Button>}
            </Space>
          </Col>                              
        </Row>
      </Form>
      <Table
        columns={tableColumns} 
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
        rowKey={(record, index) => `rowkey${record.status}${index}`}
        pagination={{
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          showTotal:(total) =>  `共 ${total} 条数据`,
          onChange: (pageNum, pageSize)　=> tablePageChange(pageNum, pageSize)
        }}>

      </Table>
    </Spin>
  )
}

export default WithdrawRecord