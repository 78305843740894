import React, { useState, useEffect, useRef } from 'react'
import {
  Spin,
  Form,
  Button,
  Row,
  Col,
  Select,
  Space,
  Table,
  Popconfirm,
  message
} from 'antd'
import store from '../../../../store/index'
import {request} from '../../../../utils/fetch'
import { contractTempGetList, updateStatus } from '../api'
import AddMoel from './components/AddMoel'
import ViewMoel from './components/ViewMoel'
const { Option } = Select
const labelStyleObj = {
    wrapperCol: { span: 18 }
}
function PotocolTemplate(props) {
    const [loading] = useState(false)
    const [companyList,setCompanyList] = useState([])
    const [tableData,setTableData] = useState([])
    const AddMoelRef = useRef();
    const ViewMoelRef = useRef();
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0
    })
    const [form] = Form.useForm()
    // form 表单域默认值
    const initialFormValues = {
        companyCodeList: null,
        status: '1',  // 状态
    }
    // 列表字段
    const tableColumns = [
        { title: '维护时间', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 200 },
        { title: '模板编码', dataIndex: 'templateNo', key: 'templateNo', align: 'center', width: 200 },
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 200 },
        { title: '适用城市', dataIndex: 'cityNames', key: 'cityNames', align: 'center', width: 200 },
        { title: '维护人', dataIndex: 'updateUser', key: 'updateUser', align: 'center', width: 120 },
        { title: '状态', dataIndex: 'status', key: 'status', align: 'center', width: 100,
            render: (status) => {
               return status==1?'启用':'停用'
            } 
        },
        { title: '操作', key: 'control', fixed: 'right', width: 210, align: 'center',
            render: (record) => (
                <Space>
                    {<a key="1" onClick={() => handelView(record)}>查看</a>}
                    {record.status == 1 && <a key="1" onClick={() => handelEdit(record)}>修改</a>}
                    {record.status == 1 && <Popconfirm
                        title='是否确定要停用?'
                        onConfirm={()=>{handelStatus(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a href="#">停用</a>
                    </Popconfirm>}
                </Space>
            )
        }
    ]

    const handelStatus = (record)=>{
        updateStatus({templateNo: record.templateNo, status: 0}).then(res=>{
            if(res.data.success){
                message.success(res.data.retMsg)
                refresh()
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    const handelView = (record)=>{
        ViewMoelRef.current.showModal(record)
    }
    const handelEdit = (record)=>{
        AddMoelRef.current.editModal(record)
    }
    const getCompanyList = async ()=>{
        let result = await request.get('/platform/companyBase/allList')
        setCompanyList(result)
    }
     //分页改变
    const tablePageChange = (page, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: page
            }
        })
    }
    const refresh = ()=>{
        if(pagination.pageNum==1){
            getList()
        }else{
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
        }
    }
    const getList = ()=>{
        contractTempGetList(Object.assign({}, form.getFieldsValue(), {pageNum: pagination.pageNum, pageSize: pagination.pageSize})).then(res=>{
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: res.data.data.totalCount
                    }
                })
            }
        })
    }
    const submit = ()=>{
        refresh()
    }
    const resetForm = ()=>{
        form.resetFields()
    }
    const onAdd = ()=>{
        AddMoelRef.current.showModal()
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    useEffect(() => {
        getList()
    }, [pagination.pageNum])

    return (
    <Spin spinning={loading}>
        <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
            <Row>
                <Col span={6}>
                    <Form.Item label="企业名称" name="companyCodeList" {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {companyList.map(item => {
                                return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                            })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item {...labelStyleObj} name="status" label="状态">
                        <Select allowClear placeholder="全部">
                            <Option value="1">启用</Option>
                            <Option value="0">停用</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
                <Space>
                <Button type="primary" onClick={submit}>查询</Button>
                <Button onClick={resetForm}>重置</Button>
                {<Button onClick={onAdd}>新增</Button>}
                </Space>
            </Col>
            </Row>
        </Form>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          rowKey={record => record.templateNo}
          scroll={{ x: 'max-content' }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 条数据`,
            onChange: (page, pageSize) => tablePageChange(page, pageSize)
          }}
        />
        <AddMoel refresh={refresh} companyList={companyList} ref={AddMoelRef} ></AddMoel>
        <ViewMoel ref={ViewMoelRef} ></ViewMoel>
    </Spin>
    )
}

export default PotocolTemplate