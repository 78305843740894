import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Input,
  Form,
  Row,
  message,
  Col,
} from 'antd';
import {request} from '../../../../utils/fetch'

const { TextArea } = Input;
const FormItem = Form.Item;

const MyModel1 = (props,ref) => {
  const mobile = localStorage.getItem('mobile')
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [detailObj, setDetailObj] = React.useState({});
  const topCol = {
    span:8,
  }
  const endCol = {
    span:8,
    offset:6
  }


   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (obj) => {
        form.resetFields()
        setVisible(true)
        setDetailObj(obj)
      }
  }));

  const handleOk = async() => {
    try {
      const values = await form.validateFields();
      setLoading(true)
      let obj = form.getFieldsValue()
      request.post('/platform/billOffline/confirm',{
        sureRemark:obj.sureRemark,
        settlementNo:detailObj.settlementNo,
      }).then(res => {
        setVisible(false);
        setLoading(false);
        message.success(res.msg)
        props.refresh()
      }).catch(err =>  setLoading(false))
      
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }


   
  }


 
  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='确认放款'
        width='1000px'
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form form={form} initialValues={{
          totalCommissionAmount:0

        }}>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">企业名称:</span>
                <Input placeholder={detailObj.companyCodeName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">提交时间:</span>
                <Input placeholder={detailObj.createTime} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">来源:</span>
                <Input placeholder={detailObj.sourceName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">账单月份:</span>
                <Input placeholder={detailObj.month} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">佣金账单ID:</span>
                <Input placeholder={detailObj.billNo} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">申请放款合计:</span>
                <Input placeholder={detailObj.totalAmount} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">绑定手机号:</span>
                <Input placeholder={mobile} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">实际放款佣金:</span>
                <Input placeholder={detailObj.sureCommissionAmount} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">实际放款服务费:</span>
                <Input placeholder={detailObj.sureServiceAmount} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">实际放款税费:</span>
                <Input placeholder={detailObj.sureTaxAmount} disabled={true} />
              </div>
            </Col>
        </Row>

        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">实际放款合计:</span>
                <Input placeholder={detailObj.sureTotalAmount} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
            <FormItem  name="sureRemark" label="备注" style={{'marginBottom':'0'}}>
              <TextArea cols="100" showCount allowClear maxLength={100} />
              </FormItem>
            </Col>
        </Row>

    
      
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



