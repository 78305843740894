// 线上支付
import React, { useState, useRef } from 'react'
import { Spin, Table, Space, message } from 'antd'
import QueryForm from './components/QueryForm'
import ConfirmModel from './components/ConfirmModel'
import store from '../../../../store/index'
import { getBackList } from './api'
const CnlinePayment = (props)=> {
    let path = 'platform:payment'
    const [tableData, setTableData] = useState([])
    const [query, setQuery] = useState([])
    const ConfirmModelRef = useRef();
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                setQuery(params)
                getTableLists(params)
                break;
            case 'refresh':
                if(pageNum==1){
                    getTableLists(query)
                }else{
                    setPageNum(1)
                }
                break;
            default:
        }
    }
    
    const getTableLists = (params) => {
        let data ={
            ...params,
            pageSize: pageSize,
            pageNum: pageNum
        }
        getBackList(data).then(res=>{
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    const handelPay = (record)=>{
        ConfirmModelRef.current.showModal(record);
    }
    // 列表字段
    const tableColumns = [
        { title: '农行退回时间', dataIndex: 'transactionTime', key: 'transactionTime', align: 'center', width: 170 },
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 220 },
        { title: '姓名', dataIndex: 'accountName', key: 'accountName', align: 'center', width: 100 },
        { title: '银行名称', dataIndex: 'bankName', key: 'bankName', align: 'center', width: 140 },
        { title: '银行卡号', dataIndex: 'accountNumber', key: 'accountNumber', align: 'center', width: 160 },
        { title: '佣金(元)', dataIndex: 'amount', key: 'amount', align: 'center', width: 100 },
        { title: '佣金退回描述', dataIndex: 'reason', key: 'reason', align: 'center', width: 220 },
        { title: '服务费状态', dataIndex: 'serviceStatus', key: 'serviceStatus', align: 'center', width: 110, render: (text) => { 
            return text==1?'已处理':'未处理'
        } },
        { title: '税费状态', dataIndex: 'taxStatus', key: 'taxStatus', align: 'center', width: 100, render: (text) => { 
            return text==1?'已处理':'未处理'
        } },
        { title: '结算单', dataIndex: 'settlementNo', key: 'settlementNo', align: 'center', width: 120 },
        { title: '服务费税费退回(元)', dataIndex: 'serviceAmount', key: 'serviceAmount', align: 'center', width: 160,
          render: (text, record) => { 
            return text + record.taxAmount
          }
        },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 160,
            align: 'center',
            render: (record) => (
                <Space>
                    {store.getState().userReducer.permissions.indexOf(`${path}:payment`) > -1&&(record.serviceStatus!=1||record.taxStatus!=1)&&<a key="2" onClick={() => handelPay(record)}>退回</a>}
            </Space>
            )
        }
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content' }}
            rowKey={(record) => record.backNo}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
            <ConfirmModel refresh={refresh} ref={ConfirmModelRef} />
        </Spin>
    )
}
export default CnlinePayment
