import React from 'react';
import { Column } from '@ant-design/charts';

const ChartPlots = (props) => {
  let data = props.data || []
  let vlabel = props.vlabel
  let color = props.color
  if(data.length>0){
    data = data.map((item) => {
      return {
        name: item.name,
        value: Number(item.value),
      };
    });
  }
  const config = {
    data: data,
    xField: 'name',
    yField: 'value',
    color: color,
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    minColumnWidth: 30,
    maxColumnWidth: 30,
    meta: {
      name: {
        alias: '类别',
      },
      value: {
        alias: vlabel,
      },
    },
  };
  return <Column style={{height: '350px'}} {...config} />;
};

export default ChartPlots;