import React, { useState, useEffect, useRef } from 'react'
import Search from './components/search'
import BaseTable from './components/baseTable'
import EditReport from './components/editReport'
function BankCardPush(props){
    const BaseTableRef = useRef();
    const EditReportRef = useRef();
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "search":
                BaseTableRef.current.getlist(data);
                break;
            case "cardPushDetail":
                props.history.push(data.url,data.params);
                break;
            case "edit":
                EditReportRef.current.showModal("edit",data);
                break;
            default: 
            break;
          }
    }
    return (
        <div>
            <Search refresh={refresh} type={1}></Search>
            <BaseTable ref={BaseTableRef} refresh={refresh}></BaseTable>
            <EditReport ref={EditReportRef} refresh={refresh}></EditReport>
        </div>
    )
}
export default BankCardPush;