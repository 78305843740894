// 结算记录
import React, { useState} from 'react'
import { Spin, Table } from 'antd'
import QueryForm from './components/QueryForm'
import { ddcSettlementGetList } from '../api'
import moment from 'moment'
function SettlementRecord() {
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                getTableLists(params)
                break;
            default:
        }
    }
    
    const getTableLists = async (record) => {
        let data ={
            status: record.status, 
            beginCollectDate: record.dsrq ? moment(record.dsrq[0]).format('YYYY-MM-DD') : '',
            endCollectDate: record.dsrq ? moment(record.dsrq[1]).format('YYYY-MM-DD') : '',
            beginPreSettlementDate: record.yjrq ? moment(record.yjrq[0]).format('YYYY-MM-DD') : '',
            endPreSettlementDate: record.yjrq ? moment(record.yjrq[1]).format('YYYY-MM-DD') : '',
            beginSettlementTime: record.sjjssj ? moment(record.sjjssj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endSettlementTime: record.sjjssj ? moment(record.sjjssj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            pageSize: pageSize,
            pageNum: pageNum
        }
        ddcSettlementGetList(data).then((res)=>{
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    // 列表字段
    const tableColumns = [
        { title: '序号', align: 'center', width: 60,render:(text, record, index)=>{
            return pageSize*(pageNum-1)+index+1
        }},
        { title: '代收日期', dataIndex: 'collectDate', key: 'collectDate', align: 'center', width: 140 },
        { title: '代收金额', dataIndex: 'collectAmount', key: 'collectAmount', align: 'center', width: 120 },
        { title: '服务费', dataIndex: 'serviceAmount', key: 'serviceAmount', align: 'center', width: 110 },
        { title: '结算总金额', dataIndex: 'settlementAmount', key: 'settlementAmount', align: 'center', width: 110 },
        { title: '门店结算金额', dataIndex: 'storesSettlementAmount', key: 'storesSettlementAmount', align: 'center', width: 110 },
        { title: '商户结算金额', dataIndex: 'businessSettlementAmount', key: 'businessSettlementAmount', align: 'center', width: 110 },
        { title: '应结算日期', dataIndex: 'preSettlementDate', key: 'preSettlementDate', align: 'center', width: 140 },
        { title: '结算账号', dataIndex: 'settlementAccount', key: 'settlementAccount', align: 'center', width: 110 },
        { title: '实际结算时间', dataIndex: 'settlementTime', key: 'settlementTime', align: 'center', width: 120 },
        { title: '结算状态', dataIndex: 'status', key: 'status', align: 'center', width: 120 },
        // { title: '备注', dataIndex: 'bz', key: 'bz', align: 'center', width: 200 },
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content' }}
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
        </Spin>
    )
}
export default SettlementRecord
