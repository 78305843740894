import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Image,
  message,
} from 'antd'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const ConfirmAudit = (props) => {
  const [form] = Form.useForm()
  const { visible, close, modalData } = props
  const initialFormValues = {
    name: '',
    idNumber: '',
    cityName: '',
    countyName: '',
    planTypeName: '',
    transactStatusName: '',
    keepStatusName: '',
    establishDate: ''
  }
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: modalData.name,
        idNumber: modalData.idNumber,
        cityName: modalData.cityName,
        countyName: modalData.countyName,
        planTypeName: modalData.planTypeName,
        transactStatusName: modalData.transactStatusName,
        keepStatusName: modalData.keepStatusName,
        establishDate: modalData.establishDate,
        socialCreditCode: modalData.socialCreditCode,
        smsStatusName: modalData.smsStatusName,
        smsStatusDate: modalData.smsStatusDate,
        smsTime: modalData.smsTime,
      })
    }
  }, [visible, modalData])
  const onCancel = () => {
    close()
  }
  return ( 
    <>
      <Modal maskClosable={false} title="查看" visible={visible} onCancel={onCancel} footer={null} width={740}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
          <Form.Item label="姓名" name="name">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="身份证号" name="idNumber">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地城市" name="cityName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地区县" name="countyName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地方案" name="planTypeName">
            <Input disabled></Input>
          </Form.Item>
          {
            !!modalData.transactVideoUrl && 
            <Form.Item label="申请办理视频">
              <video id="video1" key={modalData.transactVideoUrl} width="420" height="240" controls>
                <source src={modalData.transactVideoUrl} type="video/mp4" />
                您的浏览器不支持 HTML5 video 标签。
              </video>
            </Form.Item>
          }
          
          <Form.Item label="办证状态" name="transactStatusName">
            <Input disabled></Input>
          </Form.Item>
          {modalData.planType!=1&&<Form.Item label="存档状态" name="keepStatusName">
            <Input disabled></Input>
          </Form.Item>}
          <Form.Item label="成立日期" name="establishDate">
            <Input disabled></Input>
          </Form.Item>
          {modalData.planType!=1&&<Form.Item label="社会信用统一代码" name="socialCreditCode">
            <Input disabled></Input>
          </Form.Item>}
          {modalData.planType!=1&&<Form.Item label="短信状态" name="smsStatusName">
            <Input disabled></Input>
          </Form.Item>}
          {modalData.planType!=1&&<Form.Item label="短信发送时间" name="smsTime">
            <Input disabled></Input>
          </Form.Item>}
          <Form.Item label="身份证照">
            <div style={{display: 'flex'}}>
            {
              !!modalData.positivePictureUrl && <Image src={modalData.positivePictureUrl} width={240} />
            }
            {
              !!modalData.reversePictureUrl && <Image src={modalData.reversePictureUrl} width={240} />
            }
            </div>
          </Form.Item>
          {modalData.planType!=1&&<Form.Item label="营业执照">
            <div style={{display: 'flex'}}>
            {
              !!modalData.businessLicenseUrl && <Image src={modalData.businessLicenseUrl} width={340} />
            }
            </div>
          </Form.Item>}
          {
            !!modalData.cancelVideoUrl && 
            <Form.Item label="申请注销视频">
              <video id="video1" width="420" height="240" controls>
                <source src={modalData.cancelVideoUrl} type="video/mp4" />
                您的浏览器不支持 HTML5 video 标签。
              </video>
            </Form.Item>
          }
        </Form>
      </Modal>
    </>
  )
}

export default ConfirmAudit