import React, { useState, useEffect } from 'react'
import {
    Row,
    Table,
    Select,
    Form,
    Input,
    Button,
    Modal,
    Tooltip,
    message,
    Space,
    PageHeader,
    Popconfirm,
    Typography
} from 'antd';
import qs from 'qs'
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { request } from '../../../../../utils/fetch'
import store from '../../../../../store/index'
const { Link, Title, Paragraph, Text } = Typography;
const FormItem = Form.Item;
const { Option } = Select;
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}

function Detail(props) {
    let path = 'platform:BillUpload'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    let type = searchParam.lineType
    const [form] = Form.useForm();
    const initSearch = {
        initSearch: '',
        lineType: ''
    }
    const [tableData, setTableData] = useState([])
    const [visible1, setVisible1] = useState(false);
    const [detail, setDetail] = useState({}) //不能直接获取record的先暂存在这里
    const [loading, setLoading] = useState(false);
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    //表格下面 总计 的数据
    const [countData, setCountData] = useState({
    })

    const columns = [
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            align: 'center',
        },
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 200,
            align: 'center',
        }, 
        {
            title: '上传佣金（元）',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: 150,
            align: 'center',
        },
        // {
        //     title: '落地方案',
        //     dataIndex: 'planTypeName',
        //     key: 'planTypeName',
        //     width: 150,
        //     align:'center',
            
        // }, 
        {
            title: '发放人数',
            dataIndex: 'staffCount',
            key: 'staffCount',
            width: 150,
            align: 'center',
        }, {
            title: '检查通过数',
            dataIndex: 'tgStaffCount',
            key: 'tgStaffCount',
            width: 150,
            align: 'center',
        }, {
            title: '进入人工审核数',
            dataIndex: 'auditStaffCount',
            key: 'auditStaffCount',
            width: 150,
            align: 'center',
        }, {
            title: <>
                <span>结算单状态 </span>
                <Tooltip title='有人工审核中的数据则不支持触发生成结算单'>
                    <QuestionCircleTwoTone />
                </Tooltip>
            </>,
            dataIndex: 'createSettlementStatusName',
            key: 'createSettlementStatusName',
            width: 150,
            align: 'center',
        }, {
            title: '已生成的结算单',
            dataIndex: 'taskStatusName',
            key: 'taskStatusName',
            width: 150,
            align: 'center',
            children: [
                {
                    title: '合计申请放款（元）',
                    dataIndex: 'settlementTotalAmount',
                    key: 'settlementTotalAmount',
                    width: 160,
                    align: 'center',
                }, {
                    title: '佣金（元）',
                    dataIndex: 'settlementCommissionAmount',
                    key: 'settlementCommissionAmount',
                    width: 150,
                    align: 'center',
                }, {
                    title: '服务费（元）',
                    dataIndex: 'settlementServiceAmount',
                    key: 'settlementServiceAmount',
                    width: 150,
                    align: 'center',
                }, {
                    title: '税费（元）',
                    dataIndex: 'settlementTaxAmount',
                    key: 'settlementTaxAmount',
                    width: 150,
                    align: 'center',
                }
            ]
        },
        {
            title: '操作',
            key: 'action',
            width: 300,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.createSettlementStatus == 0 && store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <Link onClick={() => { methodsFn.goPreview(record) }}>预览</Link>
                    }
                    {
                        record.auditStaffCount != 0 && record.createSettlementStatus != 2 &&store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <Link onClick={() => methodsFn.goCheck(record)}>审核查询</Link>
                    }
                    {
                        record.createSettlementStatus == 0 && store.getState().userReducer.permissions.indexOf(`${path}:createSettlement`) > -1 &&
                        <Link onClick={() => { setDetail(record); setVisible1(true) }}>生成结算单</Link>
                    }
                    {
                        record.createSettlementStatus == 0 && store.getState().userReducer.permissions.indexOf(`${path}:removeSettlement`) > -1 &&
                        <Popconfirm
                            title="确定放弃生成结算单么？"
                            onConfirm={() => {
                                methodsFn.goCancel(record)
                            }}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Link>放弃生成</Link>
                        </Popconfirm>
                    }
                    {
                        record.createSettlementStatus == 1 &&store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 &&
                        <Link onClick={() => methodsFn.goStatement(record)}>账单明细</Link>
                    }


                </Space>
            ),
        },
    ];

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <PageHeader
                        onBack={() => props.history.goBack()}
                        title="明细"
                    />
                    <Row>
                        <FormItem label="结算单状态" name="createSettlementStatus" style={itemStyle} >
                            <Select>
                                <Option value='0'>未生成</Option>
                                <Option value='1'>已生成</Option>
                                <Option value='2'>放弃生成</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword" style={itemStyle} >
                            <Input placeholder="企业名称" />
                        </FormItem>
                    </Row>
                    <Row justify='end' >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                    </Row>
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if(pageNum==1){
                methodsFn.getTableList()
            }
            methodsFn.getCountData()
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList() {
            let searchObj = form.getFieldsValue()
            let result = await request.get('/platform/billUpload/getDivideList', {
                pageSize: pageSize,
                pageNum: pageNum,
                importCode: searchParam.importCode,
                taxEnterpriseCode: searchParam.taxEnterpriseCode,
                settlementStatusList: searchObj.createSettlementStatus,
                keyword: searchObj.keyword
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },

        goCreate() {
            setLoading(true)
            request.post('/platform/billUpload/createSettlement', {
                divideCode: detail.divideCode,
            }).then(res => {
                console.log(res);
                message.success(res.msg)
                methodsFn.handleSearch()
                setLoading(false)
                setVisible1(false)
            }).catch(err => {
                setLoading(false)
                setVisible1(false)
                console.log(err);
            })
        },
        goCancel(record) {
            request.post('/platform/billUpload/removeSettlement', {
                divideCode: record.divideCode,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()
            }).catch(err => { console.log(err); })
        },
        getCountData() {
            let formData = form.getFieldsValue()
            request.get('/platform/billUpload/getDivideStat', {
                importCode: searchParam.importCode,
                taxEnterpriseCode: searchParam.taxEnterpriseCode,
                keyword: formData.keyword
            }).then(res => {
                setCountData(res)
            }).catch(err => { console.log(err); })
        },
        goPreview(record) {
            props.history.push(`/admin/commissionUpload/detail/preview?divideCode=${record.divideCode}&lineType=${record.lineType}`)
        },
        goStatement(record) {
            props.history.push(`/admin/commissionUpload/detail/statement?divideCode=${record.divideCode}&lineType=${record.lineType}`)
        },
        goCheck(record) {
            props.history.push(`/admin/commissionUpload/detail/check?divideCode=${record.divideCode}&lineType=${record.lineType}&companyName=${record.companyName}`)
        },
    }

    useEffect(() => {
        methodsFn.getTableList()
    }, [pageNum])

    useEffect(() => {
        methodsFn.getCountData()
    }, [])

    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table
                bordered
                scroll={{ x: 'max-content' }}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />

            <Paragraph style={{ paddingLeft: '30px' }}>
                <Title level={5}>{type == '1' ? '线上发放' : '线下发放'}  合计</Title>
                <Space style={{ width: '100%' }}>
                    <Text strong>表格拆分：</Text>
                    <Text>总发放{countData.totalStaffCount}人</Text>
                    <Text>总佣金{countData.totalCommissionAmount}元</Text>
                </Space>

                <Space>
                    <Text strong>已生成的结算单：</Text>
                    <Text>总发放人数{countData.settlementStaffCount}人</Text>
                    <Text>合计申请放款{ (countData.settlementCommissionAmount + countData.settlementServiceAmount + countData.settlementTaxAmount).toFixed(2)}元
                    </Text>
                    <Text>（佣金{countData.settlementCommissionAmount}元</Text>
                    <Text>服务费{countData.settlementServiceAmount}元</Text>
                    <Text>税费{countData.settlementTaxAmount}元）</Text>
                </Space>
            </Paragraph>
            <Modal maskClosable={false}
                confirmLoading={loading}
                forceRender
                title='确认框'
                visible={visible1}
                onOk={methodsFn.goCreate}
                onCancel={() => { setVisible1(false) }} >
                <p>确定生成结算单数据？</p>
                <p>检查通过及人工审核通过的数据生成结算单</p>
                <p>线上发放则是在生成结算单后，去企业端“结算支付”中进行支付；线下发放则是在生成结算单后由平台确认发放完成，无需在线支付。</p>
            </Modal>

        </div >
    )
}

export default Detail
