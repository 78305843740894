import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  message,
  Button,
  Spin
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PasscodeInput from '../../../../../components/PasscodeInput/index'
import { sendCode, payment, getPhone, getCount } from '../api'
const FormItem = Form.Item;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);
const ConfirmModel = (props, ref) => {
  let second = 60
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [spinLoading, setSpinLoading] = React.useState(false);
  const [btnTxt, setBtnTxt] = React.useState('获取验证码');
  const [disabled, setDisabled] = React.useState(false);
  const [phoneBefore, setPhoneBefore] = React.useState();
  const [phoneAfter, setPhoneAfter] = React.useState();
  const [row, setRow] = React.useState();
  const [amt, setAmt] = React.useState();
  const initialValues = {
    age:''
  }

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      setAmt('')
      getCongfigPhone()
      setVisible(true);
      setRow({...record})
      form.resetFields()
      form.setFieldsValue(record)
      getYuE(record)
    }
  }));
  useEffect(() => {
  }, [])
  const getYuE = (record)=>{
    setSpinLoading(true)
    getCount({companyCode: record.companyCode, taxEnterpriseCode: record.taxEnterpriseCode}).then(res=>{
      if(res.data.success){
        setAmt(res.data.data)
      }else{
        message.error(res.data.retMsg)
      }
      setSpinLoading(false)
    }).catch(()=>{
      setSpinLoading(false)
    })
  }
  const onGetCode = ()=>{
    form.validateFields(['phone']).then((values)=>{
      console.log(values)
      let phone = phoneBefore + values.phone + phoneAfter
      let data = {
        phone: phone,
      }
      setBtnTxt('发送中')
      setDisabled(true)
      sendCode(data).then(res=>{
        console.log(res)
        if(res.data.success){
          timerFn()
        }else{
          message.error(res.data.retMsg)
          setDisabled(false)
          setBtnTxt('获取验证码')
        }
      }).catch(()=>{
        setBtnTxt('获取验证码')
        setDisabled(false)
      })
    })
  }
  const timerFn = () => {
    setTimeout(() => {
        if(second > 0){
              setDisabled(true)
              setBtnTxt(`${second}秒后重试`)
            second --
            timerFn()
        }else{
          second = 60
          setDisabled(false)
          setBtnTxt('获取验证码')
        }
    }, 1000);

  }
  const getCongfigPhone = () => {
    getPhone().then(res=>{
      console.log(res)
      if(res.data.success){
        setPhoneBefore(res.data.data.slice(0,3))
        setPhoneAfter(res.data.data.slice(7))
      }
    })
  }
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        setLoading(true);
        console.log(values)
        methodsFn.handleAdd(values)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      let data = {
        settlementNo: row.settlementNo,
        code: obj.code
      }
      payment(data).then(res=>{
        if(res.data.success){
          setVisible(false);
          setLoading(false);
          message.success(res.data.retMsg)
          props.refresh('refresh')
        }else{
          message.error(res.data.retMsg)
          setLoading(false);
        }
      }).catch(()=>{
        setLoading(false);
      })
    },
  }

  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='确认支付'
        width='800px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
          initialValues={initialValues}
          form={form}
        >
          <FormItem name="companyCodeName" label="企业名称" >
              <Input disabled />
          </FormItem>
          <FormItem name="totalAmount" label="放款金额" >
              <Input disabled />
          </FormItem>
          <FormItem name="taxEnterpriseName" label="结算通道" >
              <Input disabled />
          </FormItem>
          <FormItem label="账户余额" >
            <Spin spinning={spinLoading} indicator={antIcon} >
              <Input disabled value={amt} />
            </Spin>
          </FormItem>
          <FormItem name="phone" label="授权手机号" rules={[{ required: true, min: 4, message: '请补全号码' }]} >
            <div style={{display: 'flex',alignItems: 'center'}}>
              <div style={{fontSize: '20px'}} >{phoneBefore}</div>
              <PasscodeInput />
              <div style={{fontSize: '20px'}} >{phoneAfter}</div>
            </div>
          </FormItem>
          <FormItem name="code" label="短信验证码" rules={[{ required: true, message: '请输入短信验证码' }]} >
            <div style={{display: 'flex'}} >
              <Input placeholder="请输入"/>
              <Button style={{marginLeft: '10px'}} onClick={onGetCode} disabled={disabled} type="primary">{btnTxt}</Button>
            </div>
          </FormItem>
          </Form>
      </Modal>
    </>
  );
};
export default forwardRef(ConfirmModel)



