import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  DatePicker,
  Space,
  Typography 
 } from 'antd';
import {request} from '../../../../utils/fetch'
import { LoadingOutlined } from '@ant-design/icons';
import store from '../../../../store/index'
import moment from 'moment';
import AuditModal from './components/AuditModal'

import './index.css'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Paragraph } = Typography;
const itemStyle = {
     width:'330px',
}
 const labelStyleObj = {
    labelCol:{ flex: '100px'},
    wrapperCol:{ spflexan: 1}
 }
 const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );
function RechargeAudit(props) {
    // let path = 'platform:Receipt'
    const [form] = Form.useForm();
    const initSearch = {
        date: null,
        companyCode: null
    }
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const AuditModalRef = useRef();
    const columns = [
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            align:'left',
            width: '260',
            render: (companyName, record) => {
                return (
                    <>
                        <div style={{marginBottom:'6px'}} >
                            {companyName}
                        </div>
                        <div>
                            <Paragraph copyable>{record.rechargeNo}</Paragraph>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '结算通道',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: '250',
            align:'left',
        }, 
        {
            title: '汇款信息',
            dataIndex: 'bankAccountName',
            key: 'bankAccountName',
            align:'left',
            width: '330',
            render: (text, record) => {
                return (
                    <>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >开户名:</div>
                            <div style={{flex: '1',textAlign: 'left'}} >{record.bankAccountName}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >账号:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.bankAccountNo}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'50px',textAlign: 'right',marginRight: '10px'}} >银行:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.bankName}</div>
                        </div>
                    </>
                )
            }
        }, 
        {
            title: '余额信息(元)',
            dataIndex: 'amount',
            key: 'amount',
            align:'left',
            width: '250',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'100px',textAlign: 'right',marginRight: '10px'}} >申请充值金额:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.amount}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'100px',textAlign: 'right',marginRight: '10px'}} >确认充值金额:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.sureAmount}</div>
                        </div>
                    </div>
                )
            }
        },
        {
            title: '时间信息',
            dataIndex: 'createTime',
            key: 'createTime',
            align:'left',
            width: 300,
            render: (text, record) => {
                return (
                    <div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'70px',textAlign: 'right',marginRight: '10px'}} >申请时间:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.createTime}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center'}} >
                            <div style={{width:'70px',textAlign: 'right',marginRight: '10px'}} >确认时间:</div>
                            <div style={{flex: '1',textAlign: 'left'}}>{record.sureTime}</div>
                        </div>
                    </div>
                )
            }
        }, 
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            align:'left',
        }, 
        {
            title: '操作',
            key: 'action',
            width: 120,
            align:'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle" align="center" >
                    {record.status == 0&&<Button type="link" size='small' onClick={ () => onUpData(record) } >审核</Button>}
                </Space>
            )
        },
    ];
    // 详情
    const onUpData = (record) => {
        AuditModalRef.current.showModal(record)
    }
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="汇款识别码" name="keyword" style={itemStyle} {...labelStyleObj}>
                       <Input allowClear placeholder="请输入" />
                    </FormItem>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <Form.Item labelCol={{ flex: '100px' }} name="date" style={{width: '330px'}} label="申请时间">
                        <RangePicker />
                    </Form.Item>
                    <FormItem  label="状态" name="statusOrTypeList" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部" allowClear >
                            <Option value={'0'}>待确认</Option>
                            <Option value={'1'}>确认成功</Option>
                            <Option value={'2'}>确认失败</Option>
                        </Select>
                    </FormItem>
                    <Col span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap',marginBottom: '0px' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            <Button style={{ marginLeft: 10 }} onClick={exportData}>导出</Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            );
        },
    }
    //导出
    const exportData = () => {
        let formData = methodsFn.getsearchObj()
        const params = {
            companyCode: formData.companyCode,
            keyword: formData.keyword,
            statusOrTypeList: formData.statusOrTypeList,
            beginTime: formData.date? moment(formData.date[0]).format('YYYY-MM-DD') : '',
            endTime: formData.date? moment(formData.date[1]).format('YYYY-MM-DD') : '',
        }
        let url = '/platform/companyAccount/exportRechargeList'
        request.downExport(url, params)
    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                companyCode: formObj.companyCode,
                keyword: formObj.keyword,
                statusOrTypeList: formObj.statusOrTypeList,
                beginTime: formObj.date? moment(formObj.date[0]).format('YYYY-MM-DD') : '',
                endTime: formObj.date? moment(formObj.date[1]).format('YYYY-MM-DD') : '',
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/companyAccount/getRechargeList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
            setCompanyList(result)
        },
    }
    useEffect(() => {
        methodsFn.getCompanyList()
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])
    return (
        <div>
        {myComponent.renderSearchForm()}
        <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.id}
            pagination={{
            position: ['bottomCenter'],
            total: total,
            current: pageNum,
            pageSize: pageSize,
            showSizeChanger:false,
            showTotal:(total) =>  `共 ${total} 条数据` ,
            onChange: (pageNum, pageSize) => setPageNum(pageNum)
        }}
        />
        <AuditModal ref={AuditModalRef} refresh={methodsFn.handleSearch} />
        </div>
    )
}
export default RechargeAudit